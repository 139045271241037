import { Component, Input } from '@angular/core';

// enums
import {
    getUserAlertTaskStatusTitle,
    UserAlertTaskStatus,
} from 'app/connect/enums/user-alert-task-status.enum';

@Component({
    selector: 'app-user-alert-task-status',
    templateUrl: 'user-alert-task-status.component.html',
    styleUrls: ['user-alert-task-status.component.scss'],
})
export class UserAlertTaskStatusComponent {
    @Input()
    status: UserAlertTaskStatus;

    getUserAlertTaskStatusTitle = getUserAlertTaskStatusTitle;

    get statusClassName() {
        switch (this.status) {
            case UserAlertTaskStatus.Complete:
                return 'complete';
            case UserAlertTaskStatus.InProgress:
                return 'in-progress';
            case UserAlertTaskStatus.NotStarted:
                return 'not-started';
        }
    }
}
