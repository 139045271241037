import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'mobileNumber' })
export class MobileNumberPipe implements PipeTransform {
    transform(mobileNumber: string, diallingCode: string): string {
        if (!mobileNumber) {
            return '-';
        }

        if (!diallingCode && mobileNumber) {
            return mobileNumber;
        }

        return `${diallingCode}${mobileNumber}`;
    }
}
