import { LozengeVariant } from './lozenge-variant.enum';
import { EnumValueTitle } from 'app/shared/models/enum-value-title.model';

export enum UserAlertTaskStatus {
    NotStarted = 1,
    InProgress = 2,
    Complete = 3,
    Fail = 4,
}

export const UserAlertTaskStatusList: EnumValueTitle<UserAlertTaskStatus>[] = [
    { title: 'Not Started', value: UserAlertTaskStatus.NotStarted },
    { title: 'In Progress', value: UserAlertTaskStatus.InProgress },
    { title: 'Complete', value: UserAlertTaskStatus.Complete },
];

export function getUserAlertTaskStatusTitle(status: UserAlertTaskStatus) {
    return UserAlertTaskStatusList.find((x) => x.value === status)?.title;
}

export function getUserAlertTaskStatusLozengeVariant(
    status: UserAlertTaskStatus
): LozengeVariant {
    switch (status) {
        case UserAlertTaskStatus.Complete:
            return LozengeVariant.Green;
        case UserAlertTaskStatus.InProgress:
            return LozengeVariant.Amber;
        case UserAlertTaskStatus.NotStarted:
            return LozengeVariant.LightBlue;
        case UserAlertTaskStatus.Fail:
            return LozengeVariant.Red;
    }
}
