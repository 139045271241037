import { EnumValueTitle } from 'app/shared/models/enum-value-title.model';

export enum BritishTerritory {
    England = 1,
    Scotland = 2,
    Wales = 3,
    NorthernIreland = 4,
    Guernsey = 5,
    Jersey = 6,
    IsleOfMan = 7,
}

export const BritishTerritories: EnumValueTitle<BritishTerritory>[] = [
    { value: BritishTerritory.England, title: 'England' },
    { value: BritishTerritory.Scotland, title: 'Scotland' },
    { value: BritishTerritory.Wales, title: 'Wales' },
    { value: BritishTerritory.NorthernIreland, title: 'Northern Ireland' },
    { value: BritishTerritory.Guernsey, title: 'Guernsey' },
    { value: BritishTerritory.Jersey, title: 'Jersey' },
    { value: BritishTerritory.IsleOfMan, title: 'The Isle Of Man' },
];

export const SupportedLandRegistryNames: EnumValueTitle<BritishTerritory>[] = [
    { value: BritishTerritory.England, title: 'HM Land Registry' },
    { value: BritishTerritory.Wales, title: 'HM Land Registry' },
    { value: BritishTerritory.Scotland, title: 'Registers of Scotland' },
    { value: BritishTerritory.NorthernIreland, title: 'Land & Property Services' },
];

export function getBritishTerritoryName(value: BritishTerritory): string | null {
    return BritishTerritories.find((x) => x.value === value)?.title;
}

export function getSupportedLandRegistryName(value: BritishTerritory): string | null {
    return SupportedLandRegistryNames.find((x) => x.value === value)?.title;
}