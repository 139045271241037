import { Injectable } from '@angular/core';

// ngrx || rxjs
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

// store
import * as fromStore from 'app/connect/store';

// services
import { NotificationService } from 'app/connect/services/notification.service';

@Injectable()
export class NotificationEffects {
    getNotifications$ = createEffect(() =>
        this.actions.pipe(
            ofType(fromStore.GetNotifications),
            switchMap(() =>
                this.notificationService.getNotifications$().pipe(
                    map((notifications) =>
                        fromStore.GetNotificationsSuccess({ notifications })
                    ),
                    catchError(() => of(fromStore.GetNotificationsFail()))
                )
            )
        )
    );

    readNotifications$ = createEffect(() =>
        this.actions.pipe(
            ofType(fromStore.ReadNotification),
            switchMap((action) =>
                this.notificationService.readNotifications$(action.id).pipe(
                    map((notification) =>
                        fromStore.ReadNotificationSuccess({
                            notification,
                        })
                    ),
                    catchError(() => of(fromStore.ReadNotificationFail()))
                )
            )
        )
    );

    dismissNotifications$ = createEffect(() =>
        this.actions.pipe(
            ofType(fromStore.DismissNotification),
            switchMap((action) =>
                this.notificationService.dismissNotifications$(action.id).pipe(
                    map((notification) =>
                        fromStore.DismissNotificationSuccess({
                            notification,
                        })
                    ),
                    catchError(() => of(fromStore.DismissNotificationFail()))
                )
            )
        )
    );

    constructor(
        private actions: Actions,
        private notificationService: NotificationService
    ) {}
}
