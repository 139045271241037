<app-dialog id="otp-check-dialog" [dialogTitle]="pageTitle" defaultWidth="440px" defaultHeight="380px" data-auto="otp-check-dialog" [showClose]="true">
    <app-loading-card *ngIf="working"></app-loading-card>
    <div body fxLayout="column">
        <div [formGroup]="form" fxLayout="column" (keydown.enter)="onEnterPressed()">
            <p class="font-size-s">
                We've just sent {{ source }} with an access code to {{ device }}.<br />
                <br />
                Please enter it below.
            </p>
            <div fxLayout="column">
                <div class="margin-bottom-10">
                    <app-authentication-code-entry #codeEntry length="6"
                                                   spacerInterval="3"
                                                   (valueChanged)="onValueChanged($event)"
                                                   [numericOnly]="true"></app-authentication-code-entry>
                </div>

                <button class="btn large primary dark"
                        [disabled]="working || form.invalid"
                        (click)="onEnterPressed()" data-auto="confirm-code-button">
                    Confirm code
                </button>
            </div>
        </div>
    </div>
</app-dialog>