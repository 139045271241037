import { Component, Input } from '@angular/core';
import { findCountryByIso2 } from 'app/shared/utilities/country.utilities';

@Component({
    templateUrl: 'country-flag.component.html',
    selector: 'app-country-flag'
})
export class CountryFlagComponent {
    @Input()
    iso2Code: string;

    get countryName(): string {
        const country = findCountryByIso2(this.iso2Code);

        return country?.country ?? this.iso2Code;
    }
}