import { createSelector, createFeatureSelector } from '@ngrx/store';

import * as fromFeature from 'app/portal/modules/process-list/store/reducers';
import * as fromProcessList from 'app/portal/modules/process-list/store/reducers/process-list.reducers';

// models
import { StoreNames } from 'app/connect/models/store-names.model';

const getProcessesState = createFeatureSelector<fromFeature.ProcessListState>(StoreNames.processList);
const getProcessesListState = createSelector(getProcessesState, (state: fromFeature.ProcessListState) => state.ProcessListState);

export const getLoadingProcesses = createSelector(getProcessesListState, (state: fromProcessList.ProcessListState) => state.loadingProcesses);
export const getProcesses = createSelector(getProcessesListState, (state: fromProcessList.ProcessListState) => state.processes);

export const getCreatingProcess = createSelector(getProcessesListState, (state: fromProcessList.ProcessListState) => state.creatingProcess);

export const getLoadingJourneys = createSelector(getProcessesListState, (state: fromProcessList.ProcessListState) => state.loadingJourneys);
export const getJourneys = createSelector(getProcessesListState, (state: fromProcessList.ProcessListState) => state.journeys);
export const getJourneysLoaded = createSelector(getProcessesListState, (state: fromProcessList.ProcessListState) => state.journeysLoaded);

export const getLoadingActiveJourneys = createSelector(getProcessesListState, (state: fromProcessList.ProcessListState) => state.loadingActiveJourneys);
export const getActiveJourneys = createSelector(getProcessesListState, (state: fromProcessList.ProcessListState) => state.activeJourneys);
export const getActiveJourneysLoaded = createSelector(getProcessesListState, (state: fromProcessList.ProcessListState) => state.activeJourneysLoaded);

export const getLoadingEntities = createSelector(getProcessesListState, (state: fromProcessList.ProcessListState) => state.loadingEntities);
export const getEntities = createSelector(getProcessesListState, (state: fromProcessList.ProcessListState) => state.entities);
export const getEntitiesLoaded = createSelector(getProcessesListState, (state: fromProcessList.ProcessListState) => state.entitiesLoaded);

export const getLoadingFolders = createSelector(getProcessesListState, (state: fromProcessList.ProcessListState) => state.loadingFolders);
export const getFolders = createSelector(getProcessesListState, (state: fromProcessList.ProcessListState) => state.folders);
export const getFoldersLoaded = createSelector(getProcessesListState, (state: fromProcessList.ProcessListState) => state.foldersLoaded);

export const getSavingFolders = createSelector(getProcessesListState, (state: fromProcessList.ProcessListState) => state.savingFolders);

export const getSmsTemplateAvailability = createSelector(getProcessesListState, (state: fromProcessList.ProcessListState) => state.smsTemplateAvailability);

export const getInvitedByUsers = createSelector(getProcessesListState, (state: fromProcessList.ProcessListState) => state.invitedByUsers);
export const getExporting = createSelector(getProcessesListState, (state: fromProcessList.ProcessListState) => state.exporting);