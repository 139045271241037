import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

const GOOGLE_TAG_MANAGER_SCRIPT_ELEMENT_ID = 'gtm_script_connect';
const VALID_GTM_ID_PATTERN = /^[a-zA-Z0-9]/;

@Injectable({ providedIn: 'root' })
export class GoogleTagManagerService {
    private googleTagManagerId = environment.googleTagManagerId;

    addGoogleTagManager(): void {
        if (!this.googleTagManagerId || !VALID_GTM_ID_PATTERN.test(this.googleTagManagerId)) {
            return;
        }

        const gtmScript = document.getElementById(GOOGLE_TAG_MANAGER_SCRIPT_ELEMENT_ID);
        if (gtmScript) {
            return;
        }

        this.injectGTM();
    }

    private injectGTM(): void {
        const gtmScript = document.createElement('script');

        const gtmUrl = `https://www.googletagmanager.com/gtag/js?id=${this.googleTagManagerId}`;

        gtmScript.id = GOOGLE_TAG_MANAGER_SCRIPT_ELEMENT_ID;
        gtmScript.setAttribute('src', gtmUrl);
        gtmScript.setAttribute('async', '');

        const w = window as any;
        w.dataLayer = w.dataLayer || [];
        w.gtag = function() {
            /* eslint-disable prefer-rest-params */
            w.dataLayer.push(arguments);
            /* eslint-enable prefer-rest-params */
        };

        gtmScript.addEventListener('load', () => {
            try {
                w.gtag('js', new Date());
                w.gtag('config', this.googleTagManagerId);
            } catch (ex) { 
                // ignore
            }
        });

        const body = document.getElementsByTagName('body')[0];

        body.appendChild(gtmScript);
    }
}