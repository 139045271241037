import { createSelector, createFeatureSelector } from '@ngrx/store';

import * as fromFeature from 'app/portal/modules/process-list/store/reducers';
import * as fromProcess from 'app/portal/modules/process-list/store/reducers/process.reducers';

// models
import { StoreNames } from 'app/connect/models/store-names.model';

const getProcessesState = createFeatureSelector<fromFeature.ProcessListState>(StoreNames.processList);
const getProcessState = createSelector(getProcessesState, (state: fromFeature.ProcessListState) => state.ProcessState);

export const getLoadingProcess = createSelector(getProcessState, (state: fromProcess.ProcessState) => state.loadingProcess);
export const getProcess = createSelector(getProcessState, (state: fromProcess.ProcessState) => state.process);
export const getUpdatingProcess = createSelector(getProcessState, (state: fromProcess.ProcessState) => state.updatingProcess);
export const getValidatingProcess = createSelector(getProcessState, (state: fromProcess.ProcessState) => state.validating);
export const getMagicLinkClientUser = createSelector(getProcessState, (state: fromProcess.ProcessState) => state.magicLinkClientUser);
export const getMagicLinkClientUserLoading = createSelector(getProcessState, (state: fromProcess.ProcessState) => state.magicLinkClientUserLoading);
export const getMagicLinkClientUserFailed= createSelector(getProcessState, (state: fromProcess.ProcessState) => state.magicLinkClientUserFailed);