import {
    Directive,
    Output,
    EventEmitter,
    ElementRef,
    HostListener,
    Input,
} from '@angular/core';

@Directive({
    selector: '[appClickOutside]',
})
export class ClickOutsideDirective {
    @Input()
    allowedElements: HTMLElement[];

    @Output()
    clickOutside = new EventEmitter();

    constructor(private elementRef: ElementRef) {}

    @HostListener('document:click', ['$event.target'])
    onClick(target: HTMLElement) {
        const inside =
            this.elementRef.nativeElement.contains(target) ||
            this.allowedElements?.some((x) => x.contains(target));

        if (!inside) {
            this.clickOutside.emit();
        }
    }
}
