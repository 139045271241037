import { Action, createReducer, on } from '@ngrx/store';

// store
import * as actions from 'app/connect/store/actions/postcode-lookup.actions';

// models
import { Address } from 'app/portal/modules/inputs/models/address.model';

export interface PostcodeLookupState {
    searching: boolean;
    addresses: Address[];
}

const initialState: PostcodeLookupState = {
    searching: false,
    addresses: null,
};

const postcodeLookupReducer = createReducer(
    { ...initialState },
    on(actions.LookupPostcode, (state) => ({
        ...state,
        addresses: null,
        searching: true,
    })),
    on(actions.LookupPostcodeSuccess, (state, action) => ({
        ...state,
        searching: false,
        addresses: action.addresses,
    })),
    on(actions.LookupPostcodeFail, (state) => ({
        ...state,
        searching: false,
    })),
    on(actions.ClearPostcodeLookup, (state) => ({
        ...state,
        searching: false,
        addresses: null,
    })),
);

export function reducer(state: PostcodeLookupState, action: Action) {
    return postcodeLookupReducer(state, action);
}
