import { Component, Input, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

// ngrx | rxjs
import { select, Store} from '@ngrx/store';
import { map, Observable } from 'rxjs';

// store
import * as fromConnect from 'app/connect/store';

// components
import { TermsDialogComponent } from 'app/authentication-v2/components/terms-dialog/terms-dialog.component';

// enum
import { ClientUserRequiredAction } from 'app/shared/enums/client-user-required-action.enum';

@Component({
    selector: 'app-authentication-layout',
    templateUrl: './authentication-layout.component.html',
    styleUrls: ['./authentication-layout.component.scss']
})
export class AuthenticationLayoutComponent implements OnInit {

    backgroundUrl$: Observable<string>;
    title$: Observable<string>;
    tagLine$: Observable<string>;
    authenticationLogoUrl$: Observable<string>;
    fullyLoggedIn$: Observable<boolean>;

    @Input()
    pageTitle: string;

    year: number;

    constructor(
        private connectStore: Store<fromConnect.ConnectStoreState>,
        private dialog: MatDialog
    ) { }

    ngOnInit(): void {
        this.year = new Date().getFullYear();
        this.backgroundUrl$ = this.connectStore.select(fromConnect.getBackgroundUrl);
        this.title$ = this.connectStore.select(fromConnect.getTitle);
        this.tagLine$ = this.connectStore.select(fromConnect.getTagLine);
        this.authenticationLogoUrl$ = this.connectStore.select(fromConnect.getAuthenticationLogoUrl);

        this.fullyLoggedIn$ = this.connectStore.pipe(
            select(fromConnect.getUser),
            map((user) => user !== null && !user.isRestrictedAccess && user.requiredAction === ClientUserRequiredAction.None));
    }

    onViewTermsClicked() {
        this.dialog.open(TermsDialogComponent, { disableClose: true });
    }
}