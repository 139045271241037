import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';

// rxjs
import { filter, takeUntil } from 'rxjs';

// components
import { BaseComponent } from 'app/shared/base/base-component';

// models
import { EntityDetails } from 'app/portal/modules/entity-list/models/entity-details.model';
import { FolderEntity } from 'app/portal/modules/folder-list/models/folder-entity.model';

@Component({
    selector: 'app-entity-contact-card',
    templateUrl: 'entity-contact-card.component.html',
    styleUrls: ['entity-contact-card.component.scss'],
})
export class EntityContactCardComponent extends BaseComponent {
    @Input()
    entity: EntityDetails | FolderEntity;

    @Output()
    navigate = new EventEmitter();

    constructor(router: Router) {
        super();

        router.events
            .pipe(
                takeUntil(this.ngUnsubscribe),
                filter((event) => event instanceof NavigationStart)
            )
            .subscribe(() => this.navigate.emit());
    }
}
