<div id="negative-positive-input">
    <label class="radio-button negative" [for]="'negative-' + id">
        <input type="radio"
               [id]="'negative-' + id"
               [formControl]="formControl"
               [name]="'radio-group-' + id"
               [value]="false">
        <span>
            {{ negativeLabel }}
            <i class="fa fa-light fa-times"></i>
        </span>
    </label>
    <label class="radio-button positive" [for]="'positive-' + id">
        <input type="radio"
               [id]="'positive-' + id"
               [formControl]="formControl"
               [name]="'radio-group-' + id"
               [value]="true">
        <span>
            {{ positiveLabel }}
            <i class="fa fa-light fa-check"></i>
        </span>
    </label>
</div>