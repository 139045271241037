<app-authentication-layout id="set-authentication-method" fxFlex pageTitle="Choose Authentication Method">
    <app-loading-card *ngIf="(working$ | async)"></app-loading-card>
    <div>
        <p>Please select your preferred method for two-factor authentication.</p>
        <a class="btn large primary dark margin-top-24 full-width" (click)="onAuthenticatorApp()">
            Use an Authenticator App
        </a>
        <button class="btn large secondary dark margin-top-24 full-width" (click)="onPin()">
            Use Password and Pin
        </button>
    </div>
</app-authentication-layout>