import { ActionReducerMap, } from '@ngrx/store';

import * as fromAuthentication from './auth.reducers';
import * as fromTerms from './terms.reducers';

export interface AuthenticationState {
  authenticationState: fromAuthentication.AuthenticationState;
  termsState: fromTerms.TermsState;
}

export const reducers: ActionReducerMap<AuthenticationState> = {
  authenticationState: fromAuthentication.reducer,
  termsState: fromTerms.reducer
};

