import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-maximizer',
    templateUrl: './maximizer.component.html'
})
export class MaximizerComponent {

    maximizedClass = 'go-full-screen';

    @Input()
    target: string;

    maximized = false;

    onMaximizeToggled(): void {
        const element = document.getElementById(this.target);
        if (element) {
            this.maximized = !this.maximized;
            if (this.maximized) {
                element.classList.add(this.maximizedClass);
            } else {
                element.classList.remove(this.maximizedClass);
            }
        }
    }
}