<app-authentication-layout id="forgot-login-details" fxFlex pageTitle="What can't you remember?" data-auto="forgot-login-details">
    <p class="font-size-s font-weight-bold">
        No need to panic, we can help you reset your login detail in just a few minutes.
    </p>
    <p class="font-size-s">
        Note: If you've forgotten your PIN number or your password, you'll need access to the mobile device you used when registering for your account.
    </p>

    <div class="margin-bottom-20">
        <a class="btn large primary dark full-width"
                aria-label="My PIN number and my password"
                data-auto="forgotten-pin-and-password-button"
                (click)="navigation.gotoResetSecurity()">
            My PIN number and my password
        </a>
    </div>

    <div class="margin-bottom-20">
        <a class="btn large primary full-width background-connect-accent white-text"
                aria-label="My PIN number or password"
                data-auto="forgotten-pin-or-password-button"
                (click)="navigation.gotoResetSecurity()">
            My PIN number or password
        </a>
    </div>

    <div class="margin-bottom-20">
        <a class="btn large full-width secondary"
                aria-label="I can't remember my email address"
                (click)="navigation.gotoContactAdmin()"
                data-auto="forgotten-email-button">
            I can't remember my email address
        </a>
    </div>

    <div class="margin-bottom-20" fxLayout="row" fxLayoutAlign="center center">
        <button class="btn text-link" (click)="navigation.gotoLogin()">Back to login</button>
    </div>

    <div *ngIf="freshworksLoaded" class="margin-top-24 font-size-xs">
        Need help? 
        <button (click)="openFreshworks()" 
                class="text-link font-size-inherit">
            Click here
        </button>
    </div>
</app-authentication-layout>