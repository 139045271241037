// ngrx
import { Action, createReducer, on } from '@ngrx/store';

// store
import * as actions from 'app/authentication-v2/store/actions/terms.actions';

// models
import { TermsDetails } from 'app/authentication-v2/models/terms-details.model';

export interface TermsState {
    loading: boolean;
    terms: TermsDetails;
}

const initialState: TermsState = {
    loading: false,
    terms: null
};

const termsReducer = createReducer(
    initialState,
    on(actions.GetCurrentTerms, (state) => ({
        ...state,
        loading: true,
        terms: null
    })),
    on(actions.GetCurrentTermsSuccess, (state, action) => ({
        ...state,
        loading: false,
        terms: action.terms
    })),
    on(actions.GetCurrentTermsFail, (state) => ({
        ...state,
        loading: false
    }))
);

export function reducer(state: TermsState, action: Action) {
    return termsReducer(state, action);
}
