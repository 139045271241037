import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

// ngrx/rxjs
import { Observable } from 'rxjs';

// services
import { AuthenticationTokenService } from 'app/shared/services/authentication-token.service';

// models
import { TokenResponse, RefreshTokenRequest } from 'app/shared/models';
import { LoginDetails, PasswordResetDetails } from 'app/authentication-v2/models';
import { PasswordReset } from 'app/authentication-v2/models/password-reset.model';
import { User } from 'app/models/user.model';
import { ClientTokenResponse } from 'app/models/client-token-response.model';
import { TokenLoginDetails } from 'app/authentication-v2/models/token-login-details.model';
import { LoginResponse } from 'app/models/login-response.model';
import { LogoutRequest } from 'app/shared/models/authentication/logout-request.model';

@Injectable()
export class AuthenticationV2Service {

    private baseEndpoint = 'api/v2/authentication';

    constructor(
        private http: HttpClient,
        private authenticationTokenService: AuthenticationTokenService) { }

    setAndPopulateClient(clientId: string): Observable<ClientTokenResponse> {
        const params = new HttpParams().append('refreshToken', encodeURIComponent(this.authenticationTokenService.getRefreshToken()));
        const url = `${this.baseEndpoint}/set-and-populate-client/${clientId}`;
        return this.http.get<ClientTokenResponse>(url, { params });
    }

    rehydrateUser(): Observable<User> {
        const params = new HttpParams().append('refreshToken', encodeURIComponent(this.authenticationTokenService.getRefreshToken()));
        const url = `${this.baseEndpoint}/rehydrate-user`;
        return this.http.get<User>(url, { params });
    }

    refreshToken(data: RefreshTokenRequest): Observable<TokenResponse> {
        const url = `${this.baseEndpoint}/refresh-token`;
        return this.http.post<TokenResponse>(url, data);
    }

    login(data: LoginDetails): Observable<LoginResponse<User>> {
        const url = `${this.baseEndpoint}/login`;
        return this.http.post<LoginResponse<User>>(url, data);
    }

    logout(data: LogoutRequest): Observable<void> {
        const url = `${this.baseEndpoint}/logout`;
        return this.http.put<void>(url, data);
    }

    sendPasswordResetEmail(email: string): Observable<boolean> {
        const params = new HttpParams().append('email', email);
        const url = `${this.baseEndpoint}/send-password-reset-email?${params.toString()}`;

        return this.http.get<boolean>(url);
    }

    resetPassword(data: PasswordResetDetails): Observable<User> {
        const url = `${this.baseEndpoint}/reset-password`;
        return this.http.put<User>(url, data);
    }

    getResetPasswordLink(email: string): Observable<PasswordReset> {
        const params = new HttpParams().append('email', email);
        const url = `${this.baseEndpoint}/reset-password-link?${params.toString()}`;

        return this.http.get<PasswordReset>(url);
    }

    isPasswordLinkValid(email: string, token: string): Observable<boolean> {
        const url = `${this.baseEndpoint}/reset-password-link/valid`;
        return this.http.post<boolean>(url, {email, token});
    }

    setTemporaryPassword(data: PasswordResetDetails): Observable<boolean> {
        const url = `${this.baseEndpoint}/set-temporary-password`;
        return this.http.post<boolean>(url, data);
    }

    tokenLogin(tokenDetails: TokenLoginDetails): Observable<User> {
        const url = `${this.baseEndpoint}/token-login`;
        return this.http.post<User>(url, { token: tokenDetails.token, userGroupId: tokenDetails.userGroupId });
    }

    setUserGroup(clientId: string, userGroupId: string): Observable<TokenResponse> {
        const params = new HttpParams().append('refreshToken', encodeURIComponent(this.authenticationTokenService.getRefreshToken()));
        const url = `${this.baseEndpoint}/client/${clientId}/user-group/${userGroupId}`;
        return this.http.get<TokenResponse>(url, { params });
    }

    clearUserGroup(): Observable<TokenResponse> {
        const params = new HttpParams().append('refreshToken', encodeURIComponent(this.authenticationTokenService.getRefreshToken()));
        const url = `${this.baseEndpoint}/clear-user-group`;
        return this.http.get<TokenResponse>(url, { params });
    }

    acceptTerms(): Observable<User> {
        const url = `api/v2/user-terms/accept-terms`;
        return this.http.put<User>(url, null);
    }

    setTemporaryPin$(userId: Guid, pin: string): Observable<void> {
        const url = `${this.baseEndpoint}/set-temporary-pin`;
        const request = {
            userId,
            pin
        };
        return this.http.put<void>(url, request);
    }

    setChargeableMode$(chargeableMode: boolean): Observable<void> {
        const url = `api/v2/super-users/chargeable-mode?chargeableMode=${chargeableMode}`;
        return this.http.put<void>(url, {});
    }
}
