<app-dialog dialogTitle="Session Expired" defaultWidth="500px" defaultHeight="300px">

    <div body>
        <div>
            <p>While you were away your session expired.</p>
            <p>Any unsaved changes were lost.</p>
        </div>
    </div>

    <div footer-actions>
        <button class="btn large primary dark" mat-dialog-close>OK</button>
    </div>

</app-dialog>