<app-authentication-layout id="set-pin" fxFlex [pageTitle]="title" data-auto="set-pin">
    <app-loading-card *ngIf="(working$ | async)"></app-loading-card>
    <div [formGroup]="form" fxLayout="column" (keydown.enter)="setPinClick()">
        <p class="font-size-s">
            We now need you to set a pin.<br />
            <br />
            Make sure it’s something you'll remember as you'll need it each time you log in.
        </p>
        <div fxLayout="column">
            <div class="margin-bottom-10">
                <app-authentication-code-entry #codeEntry length="4"
                                               spacerInterval="2"
                                               (valueChanged)="onValueChanged($event)"
                                               [numericOnly]="true"
                                               style="max-width: 250px; display: block;"></app-authentication-code-entry>
            </div>

            <button class="btn large primary dark"
                    [disabled]="(working$ | async) || form.invalid"
                    (click)="setPinClick()" data-auto="set-pin-button">
                Set PIN
            </button>
            <div *ngIf="(showLoginLink$ | async)" class="margin-top-24 font-size-xs">
                Already have an account?
                <a (click)="navigation.gotoLogin()" class="text-link font-size-inherit">
                    Click here
                </a>
            </div>
        </div>
    </div>
</app-authentication-layout> 