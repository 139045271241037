import { Component, Input } from '@angular/core';

// models
import { UserNotification } from 'app/connect/models/user-notification.model';

@Component({
    selector: 'app-notification-details',
    templateUrl: 'notification-details.component.html',
    styleUrls: ['notification-details.component.scss'],
})
export class NotificationDetailsComponent {
    @Input()
    notification: UserNotification;
}
