<app-dialog dialogTitle="Need more time?" id="session-timeout-dialog" defaultWidth="500px" defaultHeight="400px">
    <div body fxLayout="column"fxLayoutGap="32px">
        <div class="font-heading-7">Your session is about to expire, and you will be logged out shortly.</div>
        <div class="font-heading-7">Note: Any un-saved changes will not be saved.</div>
    </div>
    <div footer-actions fxLayout="row" fxLayoutAlign="space-between" >
        <button class="btn large primary dark" fxFlex="200px" (click)="onLogoutClicked()">Logout</button>
        <button class="btn large primary dark" fxFlex="200px" (click)="onStayLoggedInClicked()">Stay logged in</button>
    </div>
</app-dialog>
