import { Component, Input } from '@angular/core';

// enums
import {
    getNotificationPriorityTitle,
    NotificationPriority,
} from 'app/shared/enums/notification-priority.enum';

@Component({
    selector: 'app-notification-priority',
    templateUrl: 'notification-priority.component.html',
    styleUrls: ['notification-priority.component.scss'],
})
export class NotificationPriorityComponent {
    @Input()
    priority: NotificationPriority;

    @Input()
    size: 'small' | 'medium' | 'large' = 'medium';

    getNotificationPriorityTitle = getNotificationPriorityTitle;

    get priorityClassName() {
        switch (this.priority) {
            case NotificationPriority.High:
                return 'high';
            case NotificationPriority.Low:
                return 'low';
            case NotificationPriority.Medium:
                return 'medium';
        }
    }

    get gap() {
        switch (this.size) {
            case 'large':
                return '12px';
            case 'medium':
                return '8px';
            case 'small':
                return '4px';
        }
    }

    get fontSize() {
        switch (this.size) {
            case 'large':
                return '24px';
            case 'medium':
                return '16px';
            case 'small':
                return '12px';
        }
    }
}
