import { LozengeVariant } from './lozenge-variant.enum';

export enum UserAlertStatus {
    None = 0,
    Pass = 1,
    ActionRequired = 2,
    Fail = 3,
}

export function getUserAlertStatusLozengeVariant(
    status: UserAlertStatus
): LozengeVariant {
    switch (status) {
        case UserAlertStatus.ActionRequired:
            return LozengeVariant.Amber;
        case UserAlertStatus.Fail:
            return LozengeVariant.Red;
        case UserAlertStatus.Pass:
            return LozengeVariant.Green;
        default:
            return LozengeVariant.LightBlue;
    }
}

export function getUserAlertStatusLozengeText(status: UserAlertStatus): string {
    switch (status) {
        case UserAlertStatus.ActionRequired:
            return 'Refer';
        case UserAlertStatus.Fail:
            return 'Fail';
        case UserAlertStatus.Pass:
            return 'Pass';
    }
}
