export const environment = {
    production: '#{EnvironmentProduction}#',
    test: '#{EnvironmentTest}#',
    demo: '#{EnvironmentDemo}#',
    release: '#{EnvironmentRelease}#',
    apiBaseUrl: '#{ApiBaseUrl}#',
    baseSiteUrl: '#{GroupPlatformSettings.BaseUrl}#',
    currentVersion: '#{GroupPlatformSettings.CurrentVersion}#',
    companiesHouseUrl: '#{CompaniesHouseUrl}#',
    createSetsTimeout: '#{CreateSetsTimeout}#',
    translationPack: 'default',
    googleTagManagerId: '#{GoogleTagManagerId}#',

    accentColor: '#{ClientBranding.AccentColor}#',
    authenticationLogoUrl: '#{ClientBranding.AuthenticationLogoUrl}#',
    backgroundUrl: '#{ClientBranding.BackgroundUrl}#',
    credasLogoColor: '#{ClientBranding.CredasLogoColor}#',
    logoUrl: '#{ClientBranding.LogoUrl}#',
    primaryColor: '#{ClientBranding.PrimaryColor}#',
    headerColor: '#{ClientBranding.HeaderColor}#',
    primaryReverseColor: '#{ClientBranding.PrimaryReverseColor}#',
    secondaryColor: '#{ClientBranding.SecondaryColor}#',
    secondaryColorDark: '#{ClientBranding.SecondaryColorDark}#',
    tableAndTabColor: '#{ClientBranding.TableAndTabColor}#',
    tableAndTabTextColor: '#{ClientBranding.TableAndTabTextColor}#',
    tagLine: '#{ClientBranding.TagLine}#',
    textColor: '#{ClientBranding.TextColor}#',
    title: '#{ClientBranding.Title}#',
    favIcon: '#{ClientBranding.FavIcon}#',
    pdfHeaderColor: '#{ClientBranding.PdfHeaderColor}#',
    pdfHeaderTextColor: '#{ClientBranding.PdfHeaderTextColor}#',
    poweredByCredasDisabled: '#{ClientBranding.poweredByCredasDisabled}#',

    entityAccentColor: '#{EntityBranding.AccentColor}#',
    entityAuthenticationBackgroundUrl: '#{EntityBranding.AuthenticationBackgroundUrl}#',
    entityAuthenticationHeader: '#{EntityBranding.AuthenticationHeader}#',
    entityAuthenticationLogoUrl: '#{EntityBranding.AuthenticationLogoUrl}#',
    entityAuthenticationTagline: '#{EntityBranding.AuthenticationTagline}#',
    entityBannerButtonColor: '#{EntityBranding.BannerButtonColor}#',
    entityBannerButtonLink: '#{EntityBranding.BannerButtonLink}#',
    entityBannerButtonText: '#{EntityBranding.BannerButtonText}#',
    entityBannerButtonTextColor: '#{EntityBranding.BannerButtonTextColor}#',
    entityBannerEnabled: '#{EntityBranding.BannerEnabled}#',
    entityBannerHeader: '#{EntityBranding.BannerHeader}#',
    entityBannerHeaderColor: '#{EntityBranding.BannerHeaderColor}#',
    entityBannerImageUrl: '#{EntityBranding.BannerImageUrl}#',
    entityBannerText: '#{EntityBranding.BannerText}#',
    entityBannerTextColor: '#{EntityBranding.BannerTextColor}#',
    entityCredasLogoColor: '#{EntityBranding.CredasLogoColor}#',
    entityHelpText: '#{EntityBranding.HelpText}#',
    entityLogoUrl: '#{EntityBranding.LogoUrl}#',
    entityPrimaryButtonColor: '#{EntityBranding.PrimaryButtonColor}#',
    entityPrimaryButtonTextColor: '#{EntityBranding.PrimaryButtonTextColor}#',
    entityPrimaryColor: '#{EntityBranding.PrimaryColor}#',
    entityPrimaryTextColor: '#{EntityBranding.PrimaryTextColor}#',
    entitySecondaryButtonColor: '#{EntityBranding.SecondaryButtonColor}#',
    entitySecondaryButtonTextColor: '#{EntityBranding.SecondaryButtonTextColor}#',
    entityTableAndTabColor: '#{EntityBranding.TableAndTabColor}#',
    entityTableAndTabTextColor: '#{EntityBranding.TableAndTabTextColor}#',
    entityTextColor: '#{EntityBranding.TextColor}#',

    logRocketId: '#{LogRocketId}#',
};
