import { createAction, props } from '@ngrx/store';

// models
import { ReceiveVerifierTaskTimeoutEvent } from 'app/connect/models/receive-verifier-task-timeout-event.model';
import { UserAlert } from 'app/connect/models/user-alert.model';
import { UserNotification } from 'app/connect/models/user-notification.model';

export const SetClientGroup = createAction(
    '[Messaging Hub] Set client group',
    props<{ clientId: string }>()
);

export const SetClientGroupSuccess = createAction(
    '[Messaging Hub] Set client group success',
    props<{ clientId: string }>()
);

export const ReceiveNotification = createAction(
    '[Messaging Hub] Receive notification',
    props<{ notification: UserNotification }>()
);

export const ReceiveAlert = createAction(
    '[Messaging Hub] Receive alert',
    props<{ alert: UserAlert }>()
);

export const ReceiveVerifierTaskTimeout = createAction(
    '[Messaging Hub] Receive verifier task timeout',
    props<{ timeoutEvent: ReceiveVerifierTaskTimeoutEvent}>()
);
