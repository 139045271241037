<button id="folder"
        [class.selected]="selected"
        (click)="folderClick.emit(folder)">
    <div class="icon-outer-wrapper">
        <div class="icon-inner-wrapper">
            <i class="fa-solid fa-user"></i>
        </div>
    </div>
    <div class="content-wrapper">
        <div class="description-wrapper">
            <div class="title">{{folder.title}}</div>
            <div class="font-size-xs">{{folder.count}} members</div>
        </div>
        <button *ngIf="!selected"
                class="btn empty"
                (click)="$event.stopPropagation();"
                [matMenuTriggerFor]="folderMenu">
            <i class="fa-solid fa-ellipsis-vertical"></i>
        </button>
        <button *ngIf="selected"
                class="btn empty"
                (click)="$event.stopPropagation(); folderClose.emit(folder)">
            <i class="fa-solid fa-close"></i>
        </button>
        <mat-menu #folderMenu="matMenu">
            <button mat-menu-item (click)="folderEdit.emit(folder)">Edit folder</button>
            <button mat-menu-item (click)="folderRemove.emit(folder)">Remove folder</button>
        </mat-menu>
    </div>
</button>