import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

// ngrx | rxjs
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

// store
import * as fromStore from 'app/authentication-v2/store';

@Component({
    templateUrl: 'enter-authenticator-code.component.html',
})
export class EnterAuthenticatorCodeComponent implements OnInit {
    form: FormGroup;

    working$: Observable<boolean>;
    authenticationMessage$: Observable<string>;

    constructor(
        private formBuilder: FormBuilder,
        private store: Store<fromStore.AuthenticationState>
    ) {}

    ngOnInit() {
        this.working$ = this.store.pipe(
            select(fromStore.getAuthenticationWorking)
        );

        this.authenticationMessage$ = this.store.pipe(
            select(fromStore.getAuthenticationMessage)
        );

        this.form = this.formBuilder.group({
            code: ['', Validators.required],
        });
    }

    onConfirm() {
        if (this.form.invalid) {
            return;
        }

        this.store.dispatch(
            fromStore.ValidateAuthenticatorCode({
                code: this.form.get('code').value,
            })
        );
    }
}
