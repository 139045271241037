import { Action, createAction, props } from '@ngrx/store';

// signalr
import { HubConnection } from '@microsoft/signalr';

// models
import { SignalREvent } from 'app/connect/models/signalr-event.model';

export const StartHubConnection = createAction(
    '[SignalR] Start hub connection',
    props<{ hub: string; events: SignalREvent[]}>()
);

export const StartHubConnectionSuccess = createAction(
    '[SignalR] Start hub connection success',
    props<{ hub: string; connection: HubConnection }>()
);

export const StartHubConnectionFail = createAction(
    '[SignalR] Start hub connection fail',
    props<{ errors: any }>()
);

export const InvokeAction = createAction(
    '[SignalR] Invoke action',
    props<{
        hub: string;
        methodName: string;
        args: any[];
        successAction?: Action;
    }>()
);

export const InvokeActionSuccess = createAction(
    '[SignalR] Invoke action success',
    props<{ hub: string; methodName: string; args: any[] }>()
);

export const InvokeActionFail = createAction(
    '[SignalR] Invoke action fail',
    props<{ errors: any }>()
);