// ngrx
import { createAction, props } from '@ngrx/store';

// models
import { ProcessDetails } from 'app/portal/modules/process-details/models/process-details.model';

export const GetProcess = createAction(
    '[Process] Get Process',
    props<{processId: string}>()
);

export const GetProcessSuccess = createAction(
    '[Process] Get Process Success',
    props<{process: ProcessDetails}>()
);

export const GetProcessFail = createAction(
    '[Process] Get Process Fail'
);

export const RestartWorkflow = createAction(
    '[Process] Restart workflow',
    props<{processId: string; reinviteUser: boolean}>()
);

export const RestartWorkflowSuccess = createAction(
    '[Process] Restart workflow success',
    props<{process: ProcessDetails}>()
);

export const RestartWorkflowFail = createAction(
    '[Process] Restart workflow fail',
);

export const CloseWorkflow = createAction(
    '[Process] Close workflow',
    props<{processId: string}>()
);

export const CloseWorkflowSuccess = createAction(
    '[Process] Close workflow success',
    props<{process: ProcessDetails}>()
);

export const CloseWorkflowFail = createAction(
    '[Process] Close workflow fail',
);

export const StartProcessValidation = createAction(
    '[Process] Start validation',
);

export const StopProcessValidation = createAction(
    '[Process] Stop validation',
);

export const GetMagicLinkClientUser = createAction(
    '[Process] Get magic link client user',
    props<{ processId: string }>()
);

export const GetMagicLinkClientUserSuccess = createAction(
    '[Process] Get magic link client user success',
    props<{ magicLink: string }>()
);

export const GetMagicLinkClientUserFail = createAction(
    '[Process] Get magic link client user fail'
);
