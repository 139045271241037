import { ActionReducerMap } from '@ngrx/store';

import * as fromProcessExport from './process-export.reducers';
import * as fromProcessList from './process-list.reducers';
import * as fromProcess from './process.reducers';

export interface ProcessListState {
    ProcessExportState: fromProcessExport.ProcessExportState;
    ProcessListState: fromProcessList.ProcessListState;
    ProcessState: fromProcess.ProcessState;
}

export const reducers: ActionReducerMap<ProcessListState> = {
    ProcessExportState: fromProcessExport.reducer,
    ProcessListState: fromProcessList.reducer,
    ProcessState: fromProcess.reducer
};
