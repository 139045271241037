<div *ngIf="canZoom && imageData else imageTemplate" class="full-height">
    <pinch-zoom class="full-height">
        <img #image
             [class.hide]="!imageData"
             [src]="imageData"
             [ngClass]="className ?? null"
             [style]="!className ? 'width: 100%; height: 100%; object-fit: contain;' : null"
             [ngStyle]="style ?? null"
             (load)="onImageLoaded($event)"
             (click)="onClicked($event)" />
    </pinch-zoom>
</div>
<ng-template #imageTemplate>
    <img #image
         [class.hide]="!imageData"
         [src]="imageData"
         [ngClass]="className ?? null"
         [style]="!className ? 'width: 100%; height: 100%' : null"
         [ngStyle]="style ?? null"
         (click)="onClicked($event)" />
</ng-template>
<div *ngIf="!imageData && showMissingImageIcon" [ngClass]="noImageIconClassList">
    <i class="fa {{noImageIcon}}"
       [ngClass]="className ?? null"
       [src]="imageData"
       [style.font-size.px]="noImageIconSizeInPx"
       [style]="!className ? 'width: 100%; height: 100%' : null"
       (click)="onClicked($event)"></i>
</div>