<app-dialog dialogTitle="Chargeable Mode Warning" defaultWidth="500px" defaultHeight="350px" [showClose]="true">

    <div body>
        <div>
            <p class="align-center"><i class="fa fa-solid fa-exclamation-circle rag-red-text icon-size-l"></i></p>
            <p>You are currently in 'Chargeable mode'.</p>
            <p>Events created in this mode will be chargeable to this client.</p>
            <p>If you would like to change this, turn chargeable mode off in your Account menu.</p>
        </div>
    </div>
</app-dialog>