import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

// enums
import { ContactType } from 'app/authentication-v2/enumerations/contact-type.enum';
import { ClientUserRequiredAction } from 'app/shared/enums/client-user-required-action.enum';
import { OtpReason } from 'app/shared/enums/otp-reason.enum';

const AUTH_PREFIX = 'auth-v2';

@Injectable()
export class NavigationService {
    constructor(private router: Router) { }

    gotoLogin(): void {
        this.router.navigate([AUTH_PREFIX, 'login']);
    }

    gotoContactAdmin(): void {
        this.router.navigate([AUTH_PREFIX, 'contact-administrator']);
    }

    gotoForgotLoginDetails(): void {
        this.router.navigate([AUTH_PREFIX, 'forgot-details']);
    }

    gotoSetPin(existingUser = false, token: string = undefined, isReset = false): void {
        this.router.navigate([AUTH_PREFIX, 'set-pin'], {
            queryParams: {
                existingUser,
                token,
                reset: isReset
            }
        });
    }

    gotoEnterPin(existingUser = false): void {
        this.router.navigate([AUTH_PREFIX, 'enter-pin'], {
            queryParams: { existingUser }
        });
    }

    gotoSetAuthenticationMethod(): void {
        this.router.navigate([AUTH_PREFIX, 'set-authentication-method']);
    }

    gotoSetAuthenticatorApp(): void {
        this.router.navigate([AUTH_PREFIX, 'set-authenticator-app']);
    }

    gotoEnterAuthenticatorAppCode(): void {
        this.router.navigate([AUTH_PREFIX, 'enter-authenticator-app-code']);
    }

    gotoSetMobileNumber(existingUser = false): void {
        this.router.navigate([AUTH_PREFIX, 'mobile-number'], {
            queryParams: { existingUser }
        });
    }

    gotoOtp(confirmationId: Guid, contactType: ContactType, reason: OtpReason): void {
        this.router.navigate([AUTH_PREFIX, 'otp-check'], {
            queryParams: {
                confirmationId,
                type: contactType,
                reason
            }
        });
    }

    gotoSetPassword(passwordResetToken: string, email: string, isReset = false, showForm = false): void {
        this.router.navigate([AUTH_PREFIX, 'reset-password'], {
            queryParams: {
                token: passwordResetToken,
                email,
                reset: isReset,
                showForm
            }
        });
    }

    gotoRequiredAction(action: ClientUserRequiredAction): void {
        switch (action) {
            case ClientUserRequiredAction.SetPin:
                this.gotoSetPin(true);
                break;
            case ClientUserRequiredAction.ProvideMobileNumber:
                this.gotoSetMobileNumber(true);
                break;
            case ClientUserRequiredAction.ProvidePin:
                this.gotoEnterPin(true);
                break;
            case ClientUserRequiredAction.SetAuthenticationMethod:
                this.gotoSetAuthenticationMethod();
                break;
            case ClientUserRequiredAction.SetAuthenticatorApp:
                this.gotoSetAuthenticatorApp();
                break;
            case ClientUserRequiredAction.ProvideAuthenticatorApp:
                this.gotoEnterAuthenticatorAppCode();
                break;
        }
    }

    gotoHomepage(): void {
        this.router.navigate(['/']);
    }

    gotoResetSecurity(): void {
        this.router.navigate([AUTH_PREFIX, 'reset-security']);
    }

    gotoWhatToReset(): void {
        this.router.navigate([AUTH_PREFIX, 'what-to-reset']);
    }

    gotoVerifier(): void {
        this.router.navigate(['/', 'back-office', 'verifier']);
    }

    gotoVerifierTasks(): void {
        this.router.navigate(['/', 'back-office', 'verifier', 'tasks']);
    }

    gotoVerifierTask(taskId: string): void {
        this.router.navigate(['/', 'back-office', 'verifier', 'tasks', taskId]);
    }
}