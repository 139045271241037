import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';

// models
import { UserNotification } from 'app/connect/models/user-notification.model';

@Component({
    selector: 'app-notification-card',
    templateUrl: 'notification-card.component.html',
    styleUrls: ['notification-card.component.scss'],
})
export class NotificationCardComponent implements OnChanges {
    @Input()
    notification: UserNotification;

    @Output()
    read = new EventEmitter();

    @Output()
    dismiss = new EventEmitter();

    textContent: string;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.notification.currentValue) {
            this.setTextContent(changes.notification.currentValue);
        }
    }

    setTextContent(notification: UserNotification) {
        const element = new DOMParser().parseFromString(
            notification.description,
            'text/html'
        );

        const children = element.children;

        for (const item of children) {
            if (!item.textContent) {
                continue;
            }

            this.textContent = item.textContent;
            break;
        }
    }
}
