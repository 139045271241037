<div id="user-alert-list">
    <app-user-alert-details *ngIf="(highlightedAlert$ | async) else list"
                            [userAlert]="highlightedAlert$ | async">
    </app-user-alert-details>
    <ng-template #list>
        <div class="empty-wrapper" *ngIf="!alertGroups?.length">
            <h3>You're all caught up!</h3>
            <h4 class="font-weight-light">Great news, you don't have any new actions.</h4>
        </div>
        <div *ngFor="let group of (alertGroups | orderBy: 'title')">
            <app-user-alert-group [group]="group"
                                  (dismiss)="dismissAlert($event)"
                                  (read)="readAlert($event)"
                                  (toggle)="toggleAlertGroup($event)"></app-user-alert-group>
        </div>
    </ng-template>
</div>