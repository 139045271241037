<div id="page" fxLayout="column" [fxLayoutGap]="layoutSpacing" fxFlex="100" (scroll)="onPageScroll($event)">
    <ng-content page-banner></ng-content>
    <div fxLayout="column" class="toolbar-filter-container" [class.margin-bottom-none]="filterConfiguration">
        <app-toolbar *ngIf="!hideToolbar"
                     [showAdditionalOptions]="showAdditionalOptions"
                     [hasCustomBannerContent]="hasCustomBannerContent"
                     [backRouterLink]="backRouterLink"
                     [showMaximizer]="showMaximizer"
                     [queryParamsHandling]="queryParamsHandling"
                     [isNewButtonDisabled]="isNewButtonDisabled">
            <ng-content select="[toolbar]"></ng-content>
            <ng-content filter select="[filter]"></ng-content>
            <ng-content standalone-buttons select="[standalone-buttons]"></ng-content>
            <ng-content banner-content select="[banner-content]"></ng-content>
            <ng-content nav-content select="[nav-content]"></ng-content>
            <app-filter
                        filter
                        [hidden]="!filterConfiguration"
                        [configuration]="filterConfiguration"
                        [visibleRecordCount]="visibleRecordCount">
                <ng-content standalone-filter-buttons select="[standalone-filter-buttons]"></ng-content>
            </app-filter>
        </app-toolbar>
    </div>

    <div class="page-content inset-container {{contentClassName}}"
         fxFlex="fill"
         [ngClass]="{
             'hide-scroll': hideScroll, 
             'margin-none': noMargin,
             'padding-none': noPadding,
             'overflow-auto': overflowContent
         }" #contentContainer>
        <ng-content select="[content]"></ng-content>
    </div>
</div>