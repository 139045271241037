import { EnumValueTitle } from 'app/shared/models/enum-value-title.model';

export enum DtoItemType {
    SmsTemplate = 1,
    EmailTemplate = 2,
    Form = 3,
    InformationPage = 4,
    Journey = 5,
    LookupList = 6,
    EsignTemplate = 7,
    EmailTemplateType = 8
}

export const DtoItemTypes = [
    new EnumValueTitle(DtoItemType.SmsTemplate, 'SMS template'),
    new EnumValueTitle(DtoItemType.EmailTemplate, 'email template'),
    new EnumValueTitle(DtoItemType.Form, 'form'),
    new EnumValueTitle(DtoItemType.InformationPage, 'information page'),
    new EnumValueTitle(DtoItemType.Journey, 'journey'),
    new EnumValueTitle(DtoItemType.LookupList, 'lookup list'),
    new EnumValueTitle(DtoItemType.EsignTemplate, 'esign template'),
    new EnumValueTitle(DtoItemType.EmailTemplateType, 'email template type')
];

export function getDtoItemTypeDescription(dtoItemType: DtoItemType): string {
    return DtoItemTypes.find(x => x.value === dtoItemType)?.title;
}