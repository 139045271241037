import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';

// ngrx | rxjs
import { ScannedActionsSubject, Store, select } from '@ngrx/store';
import { ofType } from '@ngrx/effects';
import { Observable, takeUntil, take } from 'rxjs';

// store
import * as fromStore from 'app/connect/store';
import * as fromConnect from 'app/connect/store';

// components
import { BaseComponent } from 'app/shared/base/base-component';
import { UserNotificationsDialogComponent } from 'app/connect/components/user-notifications-dialog/user-notifications-dialog.component';

// services
import { AlertService } from 'app/shared/components/alert/services/alert.service';

// models
import { Client } from 'app/models/client.model';
import { UserGroup } from 'app/models/user-group.model';
import { UserAlert } from 'app/connect/models/user-alert.model';
import { UserAlertGroup } from 'app/connect/models/user-alert-group.model';

// enums
import {
    getUserAlertCategoryTitle,
    UserAlertCategory,
} from 'app/connect/enums/user-alert-category.enum';

@Component({
    selector: 'app-user-alert-list',
    templateUrl: 'user-alert-list.component.html',
    styleUrls: ['./user-alert-list.component.scss'],
})
export class UserAlertListComponent extends BaseComponent implements OnInit {
    alertGroups: UserAlertGroup[];
    highlightedAlert$: Observable<UserAlert>;
    
    private userGroup: UserGroup;
    private client: Client;

    constructor(
        private dialogRef: MatDialogRef<UserNotificationsDialogComponent>,
        private alertService: AlertService,
        private store: Store<fromStore.NotificationState>,
        private connectStore: Store<fromStore.ConnectStoreState>,
        private actionsSubject: ScannedActionsSubject,
        private router: Router
    ) {
        super();
    }

    ngOnInit() {        
        this.highlightedAlert$ = this.store.select(fromStore.getHighlightedAlert);

        this.store
            .select(fromStore.getUserAlerts)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((userAlerts) => this.createAlertGroups(userAlerts));

        this.connectStore.pipe(
            takeUntil(this.ngUnsubscribe), 
            select(fromConnect.getUserGroup))
            .subscribe((userGroup) => this.userGroup = userGroup);

        this.connectStore.pipe(
            takeUntil(this.ngUnsubscribe),
            select(fromConnect.getClient))
            .subscribe((client) => this.client = client);            
    }

    createAlertGroups(alerts: UserAlert[]) {
        if (!alerts || !alerts.length) {
            return [];
        }

        const groups: UserAlertGroup[] = [];

        for (const alert of alerts) {
            const existingGroup = groups.find(
                (group) => group.category === alert.category
            );

            if (existingGroup) {
                existingGroup.alerts.push(alert);
            } else {
                groups.push({
                    alerts: [alert],
                    category: alert.category,
                    title: getUserAlertCategoryTitle(alert.category),
                    collapsed:
                        this.alertGroups?.find(
                            (x) => x.category === alert.category
                        )?.collapsed ?? true,
                });
            }
        }

        this.alertGroups = groups;
    }

    readAlert(alert: UserAlert) {       
        if (!alert.read) {
            this.store.dispatch(fromStore.ReadUserAlert({ id: alert.id }));  
        }
                
        // Check that the user is logged into the appropriate user group
        if (this.userGroup && this.userGroup.id !== alert.userGroupId) {
            // Need to switch user groups 
            const userGroup = this.client.userGroups.find(x => x.id == alert.userGroupId);            

            if (!userGroup) {
                this.alertService.error('You do not have access to the user group for ' + (alert.entityName ? alert.entityName : 'this individual') + '.');
                return;
            }

            this.alertService.info((alert.entityName ? alert.entityName : 'This individual') + ' belongs to the ' + userGroup.name + ' user group. Switching user groups.');
            
            this.connectStore.dispatch(fromConnect.SetUserGroup({
                clientId: this.client.id,
                userGroup,
                performRedirect: false,
                setServiceFromUrl: false
            }));

            this.actionsSubject.pipe(
                ofType(fromConnect.SetTokenSuccess),
                take(1))
                .subscribe(() => {
                    this.handleAlertPostReadAction(alert);        
                });

            
        } else {       
            // Already in the correct group - handle the action immediately
            this.handleAlertPostReadAction(alert);
        }      
    }

    handleAlertPostReadAction(alert: UserAlert) {
        switch (alert.category) {
            case UserAlertCategory.Task:
                this.store.dispatch(fromStore.SetHighlightedAlert({ alert }));
                break;
            case UserAlertCategory.EntityImportComplete:
                this.redirect(alert, ['/portal/entities']);
                break;
            default:
                this.redirect(alert);
        }
    }

    redirect(alert: UserAlert, route: string[] = ['/portal', 'entities', alert.entityId, 'active-checks']) {
        this.dialogRef.close();
        this.router.navigate(route);
    }

    dismissAlert(alert: UserAlert) {
        if (alert.dismissed) {
            return;
        }

        this.store.dispatch(fromStore.DismissUserAlert({ id: alert.id }));
    }

    toggleAlertGroup(category: UserAlertCategory) {
        this.alertGroups.forEach((group) => {
            if (group.category === category) {
                group.collapsed = !group.collapsed;
            } else {
                // Collapse other groups
                group.collapsed = true;
            }
        });
    }
}
