// color
import * as Color from 'color';

export function isLightColor(color: string) {
    try {
        return Color(color).isLight();
    } catch {
        return false;
    }
}
