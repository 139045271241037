import { createAction, props } from '@ngrx/store';

// models
import { UserAlert } from 'app/connect/models/user-alert.model';

// enums
import { UserAlertCategory } from 'app/connect/enums/user-alert-category.enum';

export const GetUserAlerts = createAction(
    '[User Alerts] Get user alerts'
);

export const GetUserAlertsSuccess = createAction(
    '[User Alerts] Get user alerts success',
    props<{ alerts: UserAlert[] }>()
);

export const GetUserAlertsFail = createAction(
    '[User Alerts] Get alerts fail'
);

export const ReadUserAlert = createAction(
    '[User Alerts] Read user alert',
    props<{ id: number }>()
);

export const ReadUserAlertSuccess = createAction(
    '[User Alerts] Read user alert success',
    props<{ alert: UserAlert }>()
);

export const ReadUserAlertFail = createAction(
    '[User Alerts] Read user alert fail'
);

export const DismissUserAlert = createAction(
    '[User Alerts] Dismiss user alert',
    props<{ id: number }>()
);

export const DismissUserAlertSuccess = createAction(
    '[User Alerts] Dismiss user alert success',
    props<{ alert: UserAlert }>()
);

export const DismissUserAlertFail = createAction(
    '[User Alerts] Dismiss user alert fail'
);

export const UserAlertGroupToggle = createAction(
    '[User Alerts] User alert group toggled',
    props<{ category: UserAlertCategory }>()
);

export const SetHighlightedAlert = createAction(
    '[User Alerts] Set highlighted alert',
    props<{ alert: UserAlert }>()
);

export const ClearHighlightedAlert = createAction(
    '[User Alerts] Clear highlighted alert'
);

