<div id="entity-merged-status">
    <div *ngIf="entity"
         class="wrapper {{ entity.approvalStatus | enumTransformer:getApprovalStatusClassName }}"
         [class.icon]="variant === 'icon'"
         #tooltip="matTooltip"
         [matTooltip]="helpText">
        <i
           class="fa-solid {{ entity.approvalStatus | enumTransformer:getEntityApprovalStatusIcon}}"></i>
        <span *ngIf="variant === 'default'" class="font-weight-medium font-size-s">
            <app-entity-status [item]="entity" [setTextColor]="false" />
        </span>
    </div>
</div>