import { Directive, HostBinding, HostListener } from '@angular/core';

@Directive({
    selector: '[appTrackHover]',
})
export class TrackHoverDirective {
    hovering: boolean;

    @HostBinding('attr.data-hovering') get applyHovering() {
        return this.hovering ? true : null;
    }

    @HostListener('mouseover') onTransitionStart() {
        this.hovering = true;
    }

    @HostListener('mouseleave') onTransitionEnd() {
        this.hovering = false;
    }
}
