import { createAction, props } from '@ngrx/store';

// models
import { TermsDetails } from 'app/authentication-v2/models/terms-details.model';

export const GetCurrentTerms = createAction(
    '[Terms V2] Get current terms'
);

export const GetCurrentTermsSuccess = createAction(
    '[Terms V2] Get current terms success',
    props<{terms: TermsDetails}>()
);

export const GetCurrentTermsFail = createAction(
    '[Terms V2] Get current terms fail',
);
