import { Component, OnInit } from '@angular/core';

// ngrx | rxjs
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

// store
import * as fromStore from 'app/connect/store';

// components
import { BaseComponent } from 'app/shared/base/base-component';

// models
import { UserNotification } from 'app/connect/models/user-notification.model';

@Component({
    selector: 'app-user-notification-list',
    templateUrl: 'user-notification-list.component.html',
})
export class UserNotificationListComponent
    extends BaseComponent
    implements OnInit {
    notifications$: Observable<UserNotification[]>;
    highlightedNotification$: Observable<UserNotification>;

    constructor(private store: Store<fromStore.NotificationState>) {
        super();
    }

    ngOnInit() {
        this.notifications$ = this.store.select(fromStore.getNotifications);
        this.highlightedNotification$ = this.store.select(
            fromStore.getHighlightedNotification
        );
    }

    readNotification(notification: UserNotification) {
        this.store.dispatch(fromStore.SetHighlightedNotification({ notification }));

        if (!notification.read) {
            this.store.dispatch(
                fromStore.ReadNotification({ id: notification.id })
            );
        }
    }

    dismissNotification(notification: UserNotification) {
        if (notification.dismissed) {
            return;
        }

        this.store.dispatch(
            fromStore.DismissNotification({ id: notification.id })
        );
    }
}
