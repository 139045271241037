// NGRX
import { Action, createReducer, on } from '@ngrx/store';

// Actions
import * as ProcessListActions from 'app/portal/modules/process-list/store/actions/process-list.actions';

// Models
import { JourneySummary } from 'app/portal/modules/process-list/models/journey-summary.model';
import { EntitySummary } from 'app/portal/modules/process-list/models/entity-summary.model';
import { ProcessSearchResults } from 'app/portal/modules/process-list/models/process-search-results.model';
import { SmsTemplateAvailability } from 'app/portal/modules/process-list/models/sms-template-availability.model';
import { FolderSummary } from 'app/connect/models/folder-summary.model';
import { InvitedByUser } from 'app/portal/modules/process-list/models/invited-by-user.model';

export interface ProcessListState {
    loadingProcesses: boolean;
    processes: ProcessSearchResults;
    loadingJourneys: boolean;
    journeys: JourneySummary[];
    journeysLoaded: boolean;
    loadingEntities: boolean;
    entities: EntitySummary[];
    entitiesLoaded: boolean;
    creatingProcess: boolean;
    loadingFolders: boolean;
    folders: FolderSummary[];
    foldersLoaded: boolean;
    savingFolders: boolean;
    loadingActiveJourneys: boolean;
    activeJourneys: JourneySummary[];
    activeJourneysLoaded: boolean;
    smsTemplateAvailability: SmsTemplateAvailability;
    invitedByUsers: InvitedByUser[];
    exporting: boolean;
}

const initialState: ProcessListState = {
    loadingProcesses: false,
    processes: undefined,
    loadingJourneys: false,
    journeys: [],
    journeysLoaded: false,
    loadingEntities: false,
    entities: [],
    entitiesLoaded: false,
    creatingProcess: false,
    loadingFolders: false,
    folders: [],
    foldersLoaded: false,
    savingFolders: false,
    loadingActiveJourneys: false,
    activeJourneys: null,
    activeJourneysLoaded: false,
    smsTemplateAvailability: null,
    invitedByUsers: null,
    exporting: false
};

const entityReducer = createReducer(
    initialState,

    on(ProcessListActions.GetProcesses, (state: ProcessListState) => ({
        ...state,
        loadingProcesses: true
    })),
    on(ProcessListActions.GetProcessesSuccess, (state: ProcessListState, action) => ({
        ...state,
        loadingProcesses: false,
        processes: action.processes
    })),
    on(ProcessListActions.GetProcessesFail, (state: ProcessListState) => ({
        ...state,
        loadingProcesses: false
    })),
    on(ProcessListActions.GetJourneys, (state: ProcessListState) => ({
        ...state,
        journeysLoaded: false,
        loadingJourneys: true
    })),
    on(ProcessListActions.GetJourneysSuccess, (state: ProcessListState, action) => ({
        ...state,
        journeysLoaded: true,
        loadingJourneys: false,
        journeys: action.journeys
    })),
    on(ProcessListActions.GetJourneysFail, (state: ProcessListState) => ({
        ...state,
        journeysLoaded: false,
        loadingJourneys: false
    })),

    on(ProcessListActions.GetActiveJourneys, (state: ProcessListState) => ({
        ...state,
        activeJourneysLoaded: false,
        loadingActiveJourneys: true
    })),
    on(ProcessListActions.GetActiveJourneysSuccess, (state: ProcessListState, action) => ({
        ...state,
        activeJourneysLoaded: true,
        loadingActiveJourneys: false,
        activeJourneys: action.journeys
    })),
    on(ProcessListActions.GetActiveJourneysFail, (state: ProcessListState) => ({
        ...state,
        activeJourneysLoaded: false,
        loadingActiveJourneys: false
    })),

    on(ProcessListActions.GetEntities, (state: ProcessListState) => ({
        ...state,
        entitiesLoaded: false,
        loadingEntities: true
    })),
    on(ProcessListActions.GetEntitiesSuccess, (state: ProcessListState, action) => ({
        ...state,
        entitiesLoaded: true,
        loadingEntities: false,
        entities: action.entities
    })),
    on(ProcessListActions.GetEntitiesFail, (state: ProcessListState) => ({
        ...state,
        entitiesLoaded: false,
        loadingEntities: false
    })),

    on(ProcessListActions.GetFolders, (state: ProcessListState) => ({
        ...state,
        foldersLoaded: false,
        loadingFolders: true
    })),
    on(ProcessListActions.GetFoldersSuccess, (state: ProcessListState, action) => ({
        ...state,
        foldersLoaded: true,
        loadingFolders: false,
        folders: action.folders
    })),
    on(ProcessListActions.GetFoldersFail, (state: ProcessListState) => ({
        ...state,
        foldersLoaded: false,
        loadingFolders: false
    })),

    on(ProcessListActions.CreateFolder, (state: ProcessListState) => ({
        ...state,
        savingFolders: true
    })),
    on(ProcessListActions.CreateFolderSuccess, (state: ProcessListState) => ({
        ...state,
        savingFolders: false
    })),
    on(ProcessListActions.CreateFolderFail, (state: ProcessListState) => ({
        ...state,
        savingFolders: false
    })),

    on(ProcessListActions.EditFolder, (state: ProcessListState) => ({
        ...state,
        savingFolders: true
    })),
    on(ProcessListActions.EditFolderSuccess, (state: ProcessListState) => ({
        ...state,
        savingFolders: false
    })),
    on(ProcessListActions.EditFolderFail, (state: ProcessListState) => ({
        ...state,
        savingFolders: false
    })),

    on(ProcessListActions.GetSmsTemplatesAvailable, (state: ProcessListState) => ({
        ...state,
        smsTemplateAvailability: null
    })),
    on(ProcessListActions.GetSmsTemplatesAvailableSuccess, (state: ProcessListState, action) => ({
        ...state,
        smsTemplateAvailability: action.templateAvailability
    })),
    on(ProcessListActions.GetSmsTemplatesAvailableFail, (state: ProcessListState) => ({
        ...state,
        smsTemplateAvailability: null
    })),
    on(ProcessListActions.LockProcessSuccess, (state, action) => ({
        ...state,
        processes: state.processes ? {...state.processes, processes: state.processes.processes.map(p => p.id === action.processId ? {...p, locked: true}: p)} : undefined
    })),
    on(ProcessListActions.UnlockProcessSuccess, (state, action) => ({
        ...state,
        processes: state.processes ? {...state.processes, processes: state.processes.processes.map(p => p.id === action.processId ? {...p, locked: false}: p)} : undefined
    })),

    on(ProcessListActions.GetInvitedByUsers, (state: ProcessListState) => ({
        ...state,
        invitedByUsers: null
    })),
    on(ProcessListActions.GetInvitedByUsersSuccess, (state: ProcessListState, action) => ({
        ...state,
        invitedByUsers: action.invitedByUsers
    })),
    on(ProcessListActions.GetInvitedByUsersFail, (state: ProcessListState) => ({
        ...state,
        invitedByUsers: null
    })),
    on(ProcessListActions.ExportProcesses, (state: ProcessListState) => ({
        ...state,
        exporting: true
    })),
    on(ProcessListActions.ExportProcessesSuccess, (state: ProcessListState) => ({
        ...state,
        exporting: false
    })),
    on(ProcessListActions.ExportProcessesFail, (state: ProcessListState) => ({
        ...state,
        exporting: false
    })),
);

export function reducer(state: ProcessListState | undefined, action: Action) {
    return entityReducer(state, action);
}