<app-dialog id="view-image-dialog"
            contentPadding="0"
            defaultHeight="1200px"
            defaultWidth="1000px"
            [showClose]="true"
            [dialogTitle]="title">
    <div body class="overflow-hidden">
        <app-image [src]="imageBase64" [canZoom]="true" className="full-width" #imgComponent />
    </div>
    <div footer-actions class="padding-top-8">
        <button class="btn large primary light" (click)="onDownloadFile()">Download</button>
        <button class="btn large primary light" (click)="imgComponent.rotate()">Rotate</button>
        <button class="btn large primary dark" mat-dialog-close>Close</button>
    </div>
</app-dialog>