import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-loading-card',
    templateUrl: './loading-card.component.html',
    styleUrls: ['./loading-card.component.scss']
})

export class LoadingCardComponent {

    @Input()
    public title: string = 'Processing';

    @Input()
    public message: string = 'Please wait...';

    @Input()
    useSpinner: boolean = false;

    @Input()
    spinnerSize: string = '20';
}
