import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

// ngrx | rxjs
import { Store, select, ActionsSubject } from '@ngrx/store';
import { map, takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ofType } from '@ngrx/effects';

// store
import * as fromAuth from 'app/authentication-v2/store';

// components
import { BaseComponent } from 'app/shared/base/base-component';

// services
import { NavigationService } from 'app/shared/services/navigation.service';

// models
import { RegExRules } from 'app/shared/models';
import { EnumValueTitle } from 'app/shared/models/enum-value-title.model';

// utils
import { getBoolean } from 'app/shared/utilities/boolean-utilities';

// enums
import { ContactType } from 'app/authentication-v2/enumerations/contact-type.enum';
import { OtpReason } from 'app/shared/enums/otp-reason.enum';

@Component({
    templateUrl: 'mobile-number.component.html'
})
export class MobileNumberComponent extends BaseComponent implements OnInit {
    RegExRules = new RegExRules();

    working$: Observable<boolean>;
    form: FormGroup;
    showLoginLink$: Observable<boolean>;

    callingCodes: EnumValueTitle<string>[] = [];

    constructor(
        private store: Store<fromAuth.AuthenticationState>,
        private fb: FormBuilder,
        public navigation: NavigationService,
        private route: ActivatedRoute,
        private actionsSubject: ActionsSubject
    ) {
        super();
    }

    ngOnInit(): void {
        this.showLoginLink$ = this.route.queryParams
            .pipe(
                takeUntil(this.ngUnsubscribe),
                map(query => !getBoolean(query?.existingUser, false)));

        this.working$ = this.store.pipe(
            takeUntil(this.ngUnsubscribe),
            select(fromAuth.getAuthenticationWorking));

        this.actionsSubject
            .pipe(
                takeUntil(this.ngUnsubscribe),
                ofType(fromAuth.SetMobileNumberSuccess)
            )
            .subscribe((action) => {
                this.navigation.gotoOtp(action.confirmationId, ContactType.Phone, OtpReason.MobileNumberConfirmation);
            });

        this.form = this.fb.group({});
    }

    onEnterPressed() {
        if (this.form.invalid) {
            return;
        }

        const form = this.form.value.mobile;
        this.store.dispatch(fromAuth.SetMobileNumber({
            mobileNumber: form.mobileNumber,
            diallingCode: form.diallingCode
        }));
    }
}