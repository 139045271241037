import { createSelector, createFeatureSelector } from '@ngrx/store';

import * as fromFeature from 'app/store/reducers';
import * as fromGlobalUi from 'app/store/reducers/user-interface.reducers';

// models
import { StoreNames } from 'app/connect/models/store-names.model';

const getState = createFeatureSelector<fromFeature.State>(StoreNames.shared);
const getGlobalUiState = createSelector(getState, (state: fromFeature.State) => state.globalUi);

export const isMobileDevice = createSelector(getGlobalUiState, (state: fromGlobalUi.State) => state.isMobileDevice);
export const getDisplayScreen = createSelector(getGlobalUiState, (state: fromGlobalUi.State) => state.displayScreenType);