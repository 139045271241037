<div>
    <div *ngIf="configuration" class="filter">
        <div fxLayout="row" fxLayoutAlign="space-between center" class="border-bottom-grey"  fxLayoutGap="10px">
            <div fxLayout="column" fxFlex>
                <div class="search-bar">
                    <i class="fa fa-light fa-magnifying-glass"></i>
                    <input #searchTextField type="text" (keyup)="searchText = $event.target.value" [placeholder]="configuration?.searchLabel" (keyup.enter)="onSearchTextUpdated($event.target.value); applyFilters();" [value]="filter.searchText ?? ''"
                           data-testid="filter-search-input" />
                    <a *ngIf="searchText" class="clear" (click)="searchTextField.value = ''; searchText = ''"><i class="times">&times;</i></a>
                </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-between center" class="buttons-container" fxLayoutGap="10px">
                <div fxHide.lt-lg fxLayout="row" fxLayoutAlign="start" fxLayoutGap="10px">
                    <ng-content fxHide.lt-lg select="[standalone-filter-buttons]"></ng-content>
                    <ng-container *ngFor="let filter of standaloneFilters">
                        <div>
                            <button [id]="filter.name + '-button'" (click)="openFilterMenu(filter)" class="btn large primary light icon-r">
                                {{filter.name}}
                                <i class="fa fa-light fa-angle-down"></i>
                            </button>
                            <div [class.hide]="!filter.open" [id]="filter.name + '-container'">
                                <div class="menu-wrapper">
                                    <div class="menu-content">
                                        <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="4px" class="padding-8">
                                            <div class="full-width" *ngFor="let value of filter.values | orderBy: filter.sort ? 'text': ''">
                                                <mat-checkbox class="medium" [checked]="isChecked(filter, value)" (change)="toggleValueSelected(filter, value)">{{value.text}}</mat-checkbox>
                                            </div>
                                        </div>
                                        <div class="padding-8" fxLayoutAlign="end">
                                            <button class="btn small primary dark full-width" (click)="applyFilters(); filter.open = false;">
                                                Apply
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div fxLayout="row" fxLayoutGap="10px">
                    <a *ngIf="configuration?.options?.length" class="btn large primary light icon-r" (click)="$event.stopPropagation(); filterVisible = !filterVisible">All filters <i class="fa fa-solid fa-grip-dots"></i></a>
                </div>
            </div>
        </div>

        <div fxLayout="row" fxLayoutGap="10px" *ngIf="filter.searchText || fromDate || toDate || flattenedSelectedValues?.length" class="clear-filters">
            <a *ngIf="filter.searchText" class="btn medium primary light icon-r" (click)="removeSearchText()">{{filter.searchText}}<i class="times">&times;</i></a>
            <a *ngFor="let value of flattenedSelectedValues" class="btn medium primary light icon-r" (click)="removeFilter(value)">{{value.text}}<i class="times">&times;</i></a>
            <a *ngIf="fromDate" class="btn medium primary light icon-r" (click)="removeFromDate()">{{fromDate}}<i class="times">&times;</i></a>
            <a *ngIf="toDate" class="btn medium primary light icon-r" (click)="removeToDate()">{{toDate}}<i class="times">&times;</i></a>
            <a class="btn medium primary dark clear-all" (click)="clearFilters()">Clear all</a>
        </div>

        <div id="filter-popout" class="filter-popout" [class.visible]="filterVisible">
            <div class="title" fxLayout="row" fxLayoutAlign="space-between center">
                <h3>All filters</h3>
                <a class="btn medium primary dark icon" (click)="filterVisible = false"><i class="fa fa-solid fa-times"></i></a>
            </div>
            <div id="filter-popout-content" class="white-bg" fxLayout="column" fxLayoutGap="16px">
                <div fxLayout="column" class="filters-inner" fxFlex fxLayoutGap="16px">

                    <app-accordion *ngIf="configuration.dateOptions?.length" title="Date Range">
                        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
                            <div fxFlex.gt-sm="150px" *ngFor="let dateOption of configuration.dateOptions; let i = index;" fxLayout="column">
                                <div class="font-question-label">{{dateOption.name}}</div>
                                <mat-form-field appearance="outline" class="mat-form-field-date-picker">
                                    <input matInput [matDatepicker]="dateOption_i" [formControl]="dateOptionControls[i]" 
                                        [min]="dateOption.minDate" [max]="dateOption.maxDate" placeholder="Select date">
                                    <mat-datepicker-toggle matSuffix [for]="dateOption_i"></mat-datepicker-toggle>
                                    <mat-datepicker #dateOption_i></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                    </app-accordion>

                    <div *ngFor="let option of configuration.options">
                        <app-accordion class="accordion" [title]="option.name">
                            <div *ngFor="let value of option.values | orderBy: option.sort ? 'text': ''" class="filter-options">
                                <mat-checkbox [checked]="isChecked(option, value)" (change)="toggleValueSelected(option, value)">{{value.text}}</mat-checkbox>
                            </div>
                        </app-accordion>
                    </div>

                </div>
                <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="10px" class="button-container">
                    <a class="btn large primary light clear-all" (click)="clearFilters()">Clear All</a>
                    <button class="btn large primary dark" (click)="applyFilters(); filterVisible = false;">Apply</button>
                </div>
            </div>
            
        </div>
    </div>
</div>
