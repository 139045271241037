import * as moment from 'moment';

// converts javascript date object to 'YYYY-MM-DD'
export function toIsoDatePartString(date: any): string {
    if (!date) {
        return null;
    }

    if (date instanceof Date) {
        return date.toISOString().split('T')[0];
    } else if (moment.isMoment(date)) {
        return toIsoDatePartString(date.toDate());
    } else {
        return null;
    }
}

// converts a Javascript date with local timezone information into
// a date object that only contains the date part, with time at midnight in UTC
export function toDateOnlyStripTimezone(date: any): Date {
    if (!date) {
        return null;
    }

    if (date instanceof Date) {
        return new Date(date.getTime() - (date.getTimezoneOffset() * 60000 ));
    } else if (moment.isMoment(date)) {
        return toDateOnlyStripTimezone(date.toDate());
    } else {
        return null;
    }
}

// converts string 'YYYY-MM-DD' to javascript Date at midnight UTC
export function toDatefromIsoDatePartString(dateString: string): Date {
    if (!dateString) {
        return null;
    }
    // convert to ISO format UTC
    const isoDateString = dateString + 'T00:00:00Z';
    return new Date(isoDateString);
}