<div id="authenticator-app-setup">
    <app-loading-card *ngIf="(working$ | async)"></app-loading-card>
    <p class="margin-bottom-8">
        Using an authenticator application on your phone such as Google Authenticator, scan the QR code below;
    </p>
    <div [formGroup]="form">
        <div *ngIf="qrCodeBase64$ | async"
             [class.hide]="showManualConnection"
             fxLayout="column"
             fxLayoutAlign="start center">
            <img [src]="qrCodeBase64$ | async" height="256px" width="256px" />
            <button class="btn text-link margin-bottom-24" (click)="toggleManualConnection()">
                Can't scan the QR? Click here
            </button>
        </div>

        <div *ngIf="manualEntryKey$ | async"
             [class.hide]="!showManualConnection"
             fxLayout="column"
             fxLayoutAlign="start center">
            <div class="manual-connection-wrapper">
                <div class="font-size-xs text-gray-6">Key</div>
                <div class="font-heading-4">
                    {{manualEntryKey$ | async}}
                </div>
            </div>
            <button class="btn text-link" (click)="toggleManualConnection()">
                View QR code
            </button>
        </div>

        <p class="margin-bottom-16">Enter the code created by your Authenticator app</p>
        <mat-form-field appearance="outline">
            <mat-label>Verification code</mat-label>
            <input matInput
                   placeholder="Verification code"
                   formControlName="code"
                   mask="000-000"
                   (keydown.enter)="onConfirm()" />
        </mat-form-field>


        <button *ngIf="showConfirmButton"
                class="btn large primary dark margin-top-8 full-width"
                [disabled]="(working$ | async) || form.invalid"
                (click)="onConfirm()">
            Complete setup
        </button>
    </div>
</div>