import { Directive, Output, EventEmitter, HostListener } from '@angular/core';

@Directive({
    selector: '[appFileDrop]',
})
export class FileDropDirective {
    @Output() filesDropped = new EventEmitter<File[]>();
    @Output() startHovering = new EventEmitter<any>();
    @Output() stopHovering = new EventEmitter<any>();
    private hovering = false;

    //Dragover listener, when something is dragged over our host element
    @HostListener('dragover', ['$event']) onDragOver(event: any): void {
        event.preventDefault();
        event.stopPropagation();

        if (!this.hovering) {
            this.hovering = true;
            this.startHovering.emit();
        }
    }

    //Dragleave listener, when something is dragged away from our host element
    @HostListener('dragleave', ['$event']) public onDragLeave(event: any): void {
        event.preventDefault();
        event.stopPropagation();
        if (this.hovering) {
            this.hovering = false;
            this.stopHovering.emit();
        }
    }

    @HostListener('drop', ['$event']) public ondrop(event: any): void {
        event.preventDefault();
        event.stopPropagation();
        const files = event.dataTransfer.files;
        if (files.length > 0) {
            this.filesDropped.emit(files);
            this.hovering = false;
        }
    }
}
