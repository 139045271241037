import { FolderSummary } from 'app/connect/models/folder-summary.model';
import { FilterOptionDate } from './filter-option-date.model';
import { FilterOption } from './filter-option.model';

export class FilterConfiguration {
    searchLabel: string;
    isDialog: boolean;
    options: FilterOption[];
    dateOptions: FilterOptionDate[];
    folders: FolderSummary[];

    constructor(
        searchLabel: string = 'Search',
        isDialog: boolean = false) {
        this.searchLabel = searchLabel;
        this.isDialog = isDialog;
        this.options = [];
        this.dateOptions = [];
    }

    addOption(name: string, sort: boolean = true, multiSelect: boolean = false, groupWithStandaloneFilters: boolean = false): FilterOption {
        const option = new FilterOption(name, sort, multiSelect, groupWithStandaloneFilters);
        this.options.push(option);
        return option;
    }

    addDateOption(name: string, minDate: Date, maxDate: Date): FilterOptionDate {
        const dateOption = new FilterOptionDate(name, minDate, maxDate);
        this.dateOptions.push(dateOption);
        return dateOption;
    }

    getOption(name: string): FilterOption {
        return this.options.filter(o => o.name === name)[0];
    }

    getDateOption(name: string): FilterOptionDate {
        return this.dateOptions.filter(o => o.name === name)[0];
    }

    removeOption(name: string): void {
        this.options = this.options.filter(o => o.name !== name);
        this.dateOptions = this.dateOptions.filter(o => o.name !== name);
    }
}