import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// rxjs
import { Observable } from 'rxjs';

// models
import { UploadFileResponse } from 'app/shared/models/upload-file-response.model';

@Injectable()
export class FileUploadService {
    private baseUrl = 'api/v2/files';

    constructor(private http: HttpClient) {}

    public uploadFileToHoldingArea$(file: File): Observable<UploadFileResponse> {
        const url = `${this.baseUrl}`;

        const formData = new FormData();
        formData.append(file.name, file);

        return this.http.post<UploadFileResponse>(url, formData);
    }

    public uploadBase64FileToHoldingArea$(base64: string, mimeType: string, filename: string): Observable<UploadFileResponse> {
        const url = `${this.baseUrl}/base64`;

        const request = {
            base64,
            mimeType,
            filename
        };

        return this.http.post<UploadFileResponse>(url, request);
    }

    public deleteFileFromHoldingArea$(uploadFileId): Observable<void> {
        const url = `${this.baseUrl}/${uploadFileId}`;
        return this.http.delete<void>(url);
    }
}
