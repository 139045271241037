import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

// ngrx | rxjs
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, mergeMap, switchMap, tap } from 'rxjs/operators';

// store
import * as actions from 'app/authentication-v2/store/actions';
import * as ConnectActions from 'app/connect/store/actions/application.actions';
import * as HelpFaqActions from 'app/connect/store/actions/help-faq.actions';
import * as TooltipActions from 'app/connect/store/actions/tooltip.actions';

// services
import { AuthenticationV2Service } from 'app/authentication-v2/services/authentication-v2.service';
import { AuthenticationTokenService } from 'app/shared/services/authentication-token.service';
import { AuthenticationEventTrackingService } from 'app/authentication-v2/services/authentication-event-tracking.service';
import { AlertService } from 'app/shared/components/alert/services/alert.service';
import { NavigationService } from 'app/shared/services/navigation.service';
import { PasswordService } from 'app/authentication-v2/services/password.service';

// models
import { User } from 'app/models/user.model';
import { TokenResponse } from 'app/shared/models/token-response.model';
import { LogoutOptions } from 'app/models/logout-options.model';
import { TemporaryPasswordReset } from 'app/authentication-v2/models/temporary-password-reset.model';
import { TermsDialogOptions } from 'app/authentication-v2/models/terms-dialog-options.model';

// enum
import { ClientUserRequiredAction } from 'app/shared/enums/client-user-required-action.enum';

// components
import { TimeoutExplanationDialogComponent } from 'app/authentication-v2/dialogs/timeout-explanation-dialog/timeout-explanation-dialog.component';
import { TermsDialogComponent } from 'app/authentication-v2/components/terms-dialog/terms-dialog.component';

@Injectable()
export class AuthEffects {

    timeoutPopup: MatDialogRef<TimeoutExplanationDialogComponent>;

    login$ = createEffect(() => this.actions$.pipe(
        ofType(actions.Login),
        mergeMap(action =>
            this.authService.login(action.request).pipe(
                switchMap((response) => {
                    if (response.success) {
                        const user = response.user;
                        if (user) {
                            if (user.token.password_reset_required) {
                                const request = new TemporaryPasswordReset(user.email, user.token.reset_password_token);
                                return [actions.PasswordResetRequired({ request })];
                            }

                            if (user.termsAcceptanceNeeded) {
                                return [actions.TermsAcceptanceNeeded({ user })];
                            }

                            if (user.clients?.every(client => client.locked)) {
                                this.authenticationEventTrackingService.loginFailed();
                                return [actions.LoginFail({ message: 'Your account has been locked. Please contact your company administrator for more information.' })];
                            }

                            return [actions.LoginSuccess({ user })];
                        } else {
                            this.authenticationEventTrackingService.loginFailed();
                            return [actions.LoginFail({ message: 'There was an error signing you in, please check and try again.' })];
                        }
                    } else {
                        if (response.isLockedout) {
                            this.authenticationEventTrackingService.loginFailed();
                            const minutesRemaining = Math.max(0, Math.ceil(response.lockoutSecondsRemaining / 60));
                            return [actions.LoginFail({ message: `Your account has been locked, please wait ${minutesRemaining} minute${(minutesRemaining === 1 ? '' : 's')} and then try again.` })];
                        } else {
                            this.authenticationEventTrackingService.loginFailed();
                            const message = `Incorrect login details entered. You have ${response.attemptsRemaining} more attempt${(response.attemptsRemaining === 1 ? '' : 's')} to log in before your account is locked for 15 minutes.`;
                            return [actions.LoginFail({ message })];
                        }
                    }
                }),
                catchError(() => of(actions.LoginFail({ message: 'There was an error signing you in, please check and try again.'}))))
        )));

    loginSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(actions.LoginSuccess),
        switchMap((action) => {
            if (action.user?.email) {
                this.authenticationToken.setUserEmail(action.user?.email);
            }

            if (action.user.requiredAction !== ClientUserRequiredAction.None) {
                this.navigation.gotoRequiredAction(action.user.requiredAction);
            }

            const newActions: any[] = [
                actions.SetToken({ token: action.user.token}),
                HelpFaqActions.GetShowHelpFaqsOnNavigation(),
                TooltipActions.GetShowTooltipsTourOnNavigation()
            ];

            if (!action.user.isRestrictedAccess) {
                newActions.push(ConnectActions.SetUser({ user: action.user, performRedirect: true, setServiceFromUrl: false }));
            }

            return newActions;
        })));


    rehydrateUser$ = createEffect(() => this.actions$.pipe(
        ofType(actions.RehydrateUser),
        switchMap(() => this.authService.rehydrateUser().pipe(
                map((user: User) => actions.RehydrateUserSuccess({user})),
                catchError(() => of(actions.RehydrateUserFail()))
            ))
    ));

    rehydrateUserSuccess$ = createEffect(() =>  this.actions$.pipe(
        ofType(actions.RehydrateUserSuccess),
        switchMap((action) => {
            const clientId = this.authenticationToken.clientId();
            const userGroupId = this.authenticationToken.userGroupId();
            this.authenticationToken.setAuthToken(action.user.token);
            const client = action.user.clients?.find(c => c.id === clientId);
            let userGroup = null;
            if (userGroupId && client && client.userGroups) {
                userGroup = client.userGroups.find(g => g.id === userGroupId);
            }
            return [
                ConnectActions.RehydrateUserSuccess({user: action.user, client, userGroup }),
                HelpFaqActions.GetShowHelpFaqsOnNavigation(),
                TooltipActions.GetShowTooltipsTourOnNavigation()
            ];
        }
    )));

    rehydrateUserFail$ = createEffect(() =>  this.actions$.pipe(
        ofType(actions.RehydrateUserFail),
        map(() => actions.Logout({ options: new LogoutOptions(false, true, null)})
    )));

    refreshToken$ = createEffect(() =>  this.actions$.pipe(
        ofType(actions.RefreshToken),
        mergeMap((action) => {
            if (action.request.accessToken === 'undefined' ||
                action.request.refreshToken === 'undefined' ||
                action.request.userId === 'undefined') {
                    return of(actions.RefreshTokenFail());
                }

            return this.authService.refreshToken(action.request).pipe(
                map((tokenResponse: TokenResponse) => {
                    if (tokenResponse && tokenResponse.success) {
                        return actions.RefreshTokenSuccess({token: tokenResponse});
                    }
                    return actions.RefreshTokenFail();
                }),
                catchError(() => of(actions.RefreshTokenFail())));
            }
        ),
        catchError(() => of(actions.RefreshTokenFail()))));

    refreshTokenSuccess$ = createEffect(() =>  this.actions$.pipe(
        ofType(actions.RefreshTokenSuccess),
        switchMap((action) => [actions.SetToken({token: action.token})])));

    refreshTokenFailed$ = createEffect(() =>  this.actions$.pipe(
        ofType(actions.RefreshTokenFail),
        switchMap(() => {
            if (!this.timeoutPopup) {
                this.timeoutPopup = this.dialog.open(TimeoutExplanationDialogComponent, {
                    disableClose: true
                });
                this.timeoutPopup.afterClosed().subscribe(() => this.timeoutPopup = null);
            }
            return [actions.Logout({ options: new LogoutOptions(false, true, null)})];
        })));

    setToken$ = createEffect(() =>   this.actions$.pipe(
        ofType(actions.SetToken),
        tap((action) => this.authenticationToken.setAuthToken(action.token))),
        { dispatch: false });

    logout$ = createEffect(() =>  this.actions$.pipe(
        ofType(actions.Logout),
        switchMap((action) => {
            const request = this.authenticationToken.getLogoutRequest();

            // Regardless of the result of logout, we want to pass along
            // to logout success.
            return this.authService.logout(request).pipe(
                map(() => actions.LogoutSuccess({options : action.options})),
                catchError(() => of(actions.LogoutSuccess({options : action.options})))
            );
        })));

    logoutSuccess$ = createEffect(() =>  this.actions$.pipe(
        ofType(actions.LogoutSuccess),
        tap((action) => {
            this.authenticationToken.clear();
            if (action.options.redirectUrl) {
                window.location.href = action.options.redirectUrl;
            } else if (action.options.redirectToLoginPage) {
                window.location.href = '/auth-v2/login';
            }
        })),
        { dispatch: false });

    setPassword$ = createEffect(() =>  this.actions$.pipe(
        ofType(actions.SetPassword),
        switchMap(action => this.authService.resetPassword(action.request).pipe(
                map((user: User) => {
                    if (user) {
                        return actions.SetPasswordSuccess({user});
                    }
                    return actions.SetPasswordFail();
                }),
                catchError(() => of(actions.SetPasswordFail()))
            ))
    ));

    setPasswordFail$ = createEffect(() =>  this.actions$.pipe(
        ofType(actions.SetPasswordFail),
        tap(() => this.alertService.error('We were unable to set your password at this time.')
        )
    ),
    { dispatch: false });

    resetPassword$ = createEffect(() => this.actions$.pipe(
        ofType(actions.PasswordResetRequired),
        tap(() => this.router.navigate(['/auth-v2/reset-password']))),
        { dispatch: false });


    checkPasswordLink$ = createEffect(() =>  this.actions$.pipe(
        ofType(actions.CheckPasswordLink),
        switchMap(action => this.authService.isPasswordLinkValid(action.email, action.token).pipe(
                map((valid: boolean) => actions.CheckPasswordLinkSuccess({valid})),
                catchError(() => of(actions.CheckPasswordLinkFail()))
            ))
    ));

    checkPasswordLinkSuccess$ = createEffect(() =>  this.actions$.pipe(
        ofType(actions.CheckPasswordLinkSuccess),
        tap((action) => {
            if (!action.valid) {
                this.router.navigate(['/auth-v2/login']);
            }
    })), { dispatch: false });

    tokenLogin$ = createEffect(() => this.actions$.pipe(
        ofType(actions.TokenLogin),
        mergeMap(action =>
            this.authService.tokenLogin(action.request).pipe(
                map((user: User) => {
                    if (user?.termsAcceptanceNeeded === true) {
                        return actions.TermsAcceptanceNeeded({
                            user,
                            tokenLogin: action.request
                        });
                    }

                    const client = user.clients.find(c => c.id === action.request.clientId);

                    if (user && client) {
                        return actions.TokenLoginSuccess({ user, client, tokenLogin: action.request });//
                    } else {
                        return actions.LoginFail({ message: 'Token is invalid or expired.'});
                    }
                }),
                catchError(() => of(actions.LoginFail({ message: 'Error occurred on login attempt.'}))))
        )));

    tokenLoginSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.TokenLoginSuccess),
            switchMap((action) =>
                of(ConnectActions.SetUserForTokenLogin({
                    user: action.user,
                    client: action.client,
                    token: action.tokenLogin,
                }))
            )
        )
    );

    termsAcceptanceNeeded$ = createEffect(() => this.actions$.pipe(
        ofType(actions.TermsAcceptanceNeeded),
        map(action => {
            this.dialog.open(TermsDialogComponent, {
                data: {
                    acceptanceRequired: true,
                    user: action.user,
                    tokenLogin: action.tokenLogin
                } as TermsDialogOptions,
                disableClose: true,
                autoFocus: false
            });
            return actions.SetToken({ token: action.user.token});
        })
    ));

    termsAccepted$ = createEffect(() => this.actions$.pipe(
        ofType(actions.TermsAccepted),
        mergeMap(action =>
            this.authService.acceptTerms().pipe(
                map((user: User) => actions.TermsAcceptedSuccess({ user, tokenLogin: action.tokenLogin })),
                catchError(() => of(actions.TermsAcceptedFail())))
        )));

    termsAcceptedSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(actions.TermsAcceptedSuccess),
        map(action => {
            const user = action.user;
            if (user) {
                if (action.tokenLogin) {
                    const clientId = action.tokenLogin?.clientId;
                    const client = action.tokenLogin?.clientId ? user.clients.find(c => c.id === action.tokenLogin.clientId) : null;

                    if (!clientId || client) {
                        return ConnectActions.SetUserForTokenLogin({ user, client, token: action.tokenLogin });
                    }
                } else {
                    return actions.LoginSuccess({ user: action.user });
                }
            }

            return actions.LoginFail({ message: 'Token is invalid or expired.' });
        }),
    ));

    termsAcceptedFail$ = createEffect(() => this.actions$.pipe(
        ofType(actions.TermsAcceptedFail),
        map(() => actions.LoginFail({ message: 'Error occurred on login attempt.'})),
    ));

    setTemporaryPin$ = createEffect(() => this.actions$.pipe(
        ofType(actions.SetTemporaryPin),
        switchMap((action) =>
            this.authService.setTemporaryPin$(action.userId, action.pin).pipe(
                map(() => actions.SetTemporaryPinSuccess()),
                catchError(() => of(actions.SetTemporaryPinFail())))
        )));

    setTemporaryPinSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(actions.SetTemporaryPinFail),
        tap(() => this.alertService.success('Temporary PIN has been set.')),
    ), { dispatch: false });

    setTemporaryPinFail$ = createEffect(() => this.actions$.pipe(
        ofType(actions.SetTemporaryPinFail),
        tap(() => this.alertService.error('We could not set the temporary PIN.')),
    ), { dispatch: false });


    generateTemporaryPassword$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.GenerateTemporaryPassword),
            switchMap((action) => {
                const password = this.passwordService.generateTempPassword();

                return this.authService
                    .setTemporaryPassword({
                        password,
                        email: action.email,
                        confirmPassword: null,
                        token: null,
                    })
                    .pipe(
                        map((success) =>
                            actions.GenerateTemporaryPasswordSuccess({
                                success,
                                password,
                            })
                        ),
                        catchError(() =>
                            of(actions.GenerateTemporaryPasswordFail())
                        )
                    );
            })
        )
    );

    constructor(
        private dialog: MatDialog,
        private actions$: Actions,
        private authService: AuthenticationV2Service,
        private router: Router,
        private alertService: AlertService,
        private authenticationToken: AuthenticationTokenService,
        private authenticationEventTrackingService: AuthenticationEventTrackingService,
        private navigation: NavigationService,
        private passwordService: PasswordService) { }
}
