// Angular / RXJS
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Models
import { EntitySummary } from 'app/portal/modules/process-list/models/entity-summary.model';
import { EntitySearch } from 'app/portal/modules/process-list/models/entity-search.model';

@Injectable()
export class EntityService {

    private baseEndpoint = 'api/v2/entities';

    constructor(private http: HttpClient) { }

    getEntities(searchData: EntitySearch): Observable<EntitySummary[]> {
        // Can't use HttpParams because they don't encode + symbols and convert them to spaces
        const url = `${this.baseEndpoint}?emailAddress=${encodeURIComponent(searchData.emailAddress)}`;
        return this.http.get<EntitySummary[]>(url);
    }
}