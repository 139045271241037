import { Injectable } from '@angular/core';

// Enums
import { MobileDevice, DisplayScreen } from 'app/shared/enums';

// Store
import { Store } from '@ngrx/store';
import * as fromRoot from 'app/store';
import * as userInterfaceActions from 'app/store/actions/user-interface.actions';

@Injectable()
export class DeviceDetectionService {

    public displayScreen: DisplayScreen;

    private mobileListner: MediaQueryList;
    private tabletListner: MediaQueryList;
    private desktopListner: MediaQueryList;


    public constructor(private store: Store<fromRoot.State>) {

        this.mobileListner = window.matchMedia('screen and (max-width: 40em)');

        this.mobileListner.addListener(this.onMobileChanged);

        this.tabletListner = window.matchMedia('screen and (min-width: 40.063em) and (max-width: 64.062em)');
        this.tabletListner.addListener(this.onTabletChanged);

        this.desktopListner = window.matchMedia('screen and (min-width: 64.063em)');
        this.desktopListner.addListener(this.onDesktopChanged);
    }

    public init(): void {
        this.getDisplay();
        this.getDevice();
    }

    private getDevice(): MobileDevice {
        let mobileDeviceType: MobileDevice = MobileDevice.Unknown;
        const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;

        if (/android/i.test(userAgent)) {
            mobileDeviceType = MobileDevice.Android;
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
            mobileDeviceType = MobileDevice.iOS;
        }

        this.store.dispatch(new userInterfaceActions.SetMobileDeviceType(mobileDeviceType));
        return mobileDeviceType;
    }

    private isMobileDevice(device: MobileDevice): boolean {
        return device === MobileDevice.Android || device === MobileDevice.iOS;
    }

    // Get the detected device display
    private getDisplay(): DisplayScreen {

        if (!this.mobileListner || !this.tabletListner || !this.desktopListner) {
            return;
        }

        if (this.mobileListner.matches) {
            this.displayScreen = DisplayScreen.Mobile;
        } else if (this.tabletListner.matches) {
            this.displayScreen = DisplayScreen.Tablet;
        } else if (this.desktopListner.matches) {
            this.displayScreen = DisplayScreen.Desktop;
        }

        this.store.dispatch(new userInterfaceActions.SetDisplayScreenType(this.displayScreen));
        return this.displayScreen;
    }

    private onMobileChanged(mediaQueryList) {
        if (mediaQueryList.matches) {
            this.displayScreen = DisplayScreen.Mobile;
            // this.store.dispatch(new userInterfaceActions.SetDisplayScreenType(this.displayScreen));
        }
    }

    private onTabletChanged(mediaQueryList) {
        if (mediaQueryList.matches) {
            this.displayScreen = DisplayScreen.Tablet;
            // this.store.dispatch(new userInterfaceActions.SetDisplayScreenType(this.displayScreen));
        }
    }

    private onDesktopChanged(mediaQueryList) {
        if (mediaQueryList.matches) {
            this.displayScreen = DisplayScreen.Desktop;
            // this.store.dispatch(new userInterfaceActions.SetDisplayScreenType(this.displayScreen));
        }
    }
}
