// ngrx
import { createAction, props } from '@ngrx/store';

// models
import { Branding } from 'app/models/branding.model';

export const SetBranding = createAction(
    '[Connect Branding] Set branding',
    props<{branding: Branding}>()
);

export const ClearBranding = createAction(
    '[Connect Branding] Clear branding'
);