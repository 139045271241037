import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'replace',
})
export class ReplaceInStringPipe implements PipeTransform {
    transform(
        str: string,
        searchValue: string,
        replaceValue: string
    ): string {
        if (!str) {
            return str;
        }

        return str.replace(new RegExp(searchValue, 'g'), replaceValue);
    }
}
