import { Component, Input, OnInit } from '@angular/core';

// ngrx | rxjs
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// store
import * as fromConnect from 'app/connect/store';

// components
import { BaseComponent } from 'app/shared/base/base-component';

// models
import { Client } from 'app/models/client.model';
import { LogoutOptions } from 'app/models/logout-options.model';
import { UserGroup } from 'app/models/user-group.model';
import { User } from 'app/models/user.model';


@Component({
    selector: 'app-user-group-selector',
    templateUrl: './user-group-selector.component.html',
    styleUrls: ['./user-group-selector.component.scss']
})
export class UserGroupSelectorComponent extends BaseComponent implements OnInit {

    @Input()
    logoutOnBackClick: boolean = false;

    @Input()
    backLabel: string = 'Back';

    @Input()
    float: boolean = true;

    @Input()
    showClose = true;

    client: Client;
    user$: Observable<User>;
    visible$: Observable<boolean>;
    searchTerm: string;

    constructor(private connectStore: Store<fromConnect.ConnectStoreState>) {
        super();
    }

    ngOnInit(): void {
        this.connectStore.pipe(
            takeUntil(this.ngUnsubscribe),
            select(fromConnect.getClient))
            .subscribe((client: Client) => this.client = client);

        this.user$ = this.connectStore.select(fromConnect.getUser);
        this.visible$ = this.connectStore.select(fromConnect.getShowUserGroupSelector);
    }

    handleSearch(searchTerm: string ): void {
        this.searchTerm = searchTerm.toLocaleLowerCase().trim();
    }

    handleUserGroupSelected(userGroup: UserGroup): void {
        this.connectStore.dispatch(fromConnect.SetUserGroup({
            clientId: this.client.id,
            userGroup,
            performRedirect: true,
            setServiceFromUrl: false
        }));
        this.connectStore.dispatch(fromConnect.CloseUserGroupSelector());
    }

    handleBackLinkClicked(): void {
        if (this.logoutOnBackClick) {
            this.connectStore.dispatch(fromConnect.LogoutUser({options: new LogoutOptions(true, true, null)}));
        }
        this.connectStore.dispatch(fromConnect.CloseUserGroupSelector());
    }

    filterUserGroups(userGroups: UserGroup[]): UserGroup[] {
        if (!this.searchTerm) {
            return userGroups;
        }
        return userGroups.filter(c => c.name.toLocaleLowerCase().includes(this.searchTerm));
    }

    closeBrandPanel(): void {
        this.connectStore.dispatch(fromConnect.CloseUserGroupSelector());
    }
}