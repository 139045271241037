import { EnumValueTitle } from 'app/shared/models/enum-value-title.model';

export enum NotificationPriority {
    Low = 0,
    Medium = 1,
    High = 2,
}

export const NotificationPriorities: EnumValueTitle<NotificationPriority>[] = [
    { title: 'Low', value: NotificationPriority.Low },
    { title: 'Medium', value: NotificationPriority.Medium },
    { title: 'High', value: NotificationPriority.High },
];

export function getNotificationPriorityTitle(priority: NotificationPriority) {
    return NotificationPriorities.find((x) => x.value === priority).title;
}
