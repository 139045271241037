import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnInit,
    ViewChild,
    OnDestroy
} from '@angular/core';

// components
import { BaseComponent } from 'app/shared/base/base-component';

// utilities
import { generateRandomInt } from 'app/shared/utilities/random.utlities';

@Component({
    selector: 'app-skeleton-loader',
    templateUrl: 'skeleton-loader.component.html',
    styleUrls: ['skeleton-loader.component.scss'],
})
export class SkeletonLoaderComponent
    extends BaseComponent
    implements OnChanges, OnInit, AfterViewInit, OnDestroy {
    @ViewChild('wrapper', { static: true })
    wrapper: ElementRef<HTMLDivElement>;

    @Input()
    count: number;

    @Input()
    randomWidths: boolean;

    @Input()
    fill: boolean;

    @Input()
    heightInPx: number = 28;

    resizeObserver: ResizeObserver;
    skeletons: { width: number }[];

    ngOnChanges() {
        this.createSkeletons();
    }

    ngOnInit() {
        this.resizeObserver = new ResizeObserver(() =>
            this.createSkeletons()
        );

        this.resizeObserver.observe(this.wrapper.nativeElement);
    }

    ngAfterViewInit() {
        this.createSkeletons();
    }

    ngOnDestroy(): void {
        this.resizeObserver.unobserve(this.wrapper.nativeElement);
        this.resizeObserver.disconnect();
    }

    createSkeletons() {
        const skeletons: { width: number }[] = [];
        const count = this.fill ? this.getSkeletonFilLCount() : this.count;

        for (let index = 0; index < count; index++) {
            skeletons.push({
                width: this.randomWidths ? generateRandomInt(20, 100) : 100,
            });
        }

        this.skeletons = skeletons;
    }

    getSkeletonFilLCount() {
        const windowHeight = this.wrapper.nativeElement.clientHeight;
        const padding = 16;
        const elementHeight = this.heightInPx + padding;
        const count = Math.floor(windowHeight / elementHeight);

        return count;
    }
}
