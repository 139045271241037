import { Component, OnInit } from '@angular/core';

// services
import { NavigationService } from 'app/shared/services/navigation.service';
@Component({
    templateUrl: 'forgot-login-details.component.html',
})
export class ForgotLoginDetailsComponent implements OnInit {
    freshworksLoaded: boolean;

    constructor(public navigation: NavigationService) {}

    ngOnInit() {
        this.freshworksLoaded = !!window.FreshworksWidget;
    }

    openFreshworks() {
        if (!this.freshworksLoaded) {
            return;
        }

        window.FreshworksWidget('open');
    }
}
