<div #button
     id="segmented-button"
     class="button-wrapper"
     [class.active]="checked">
    <input type="radio"
           [id]="id"
           [name]="id"
           [checked]="checked"
           [value]="value"
           (change)="onButtonChange()" />
    <label [for]="id">
        <ng-content></ng-content>
    </label>
</div>