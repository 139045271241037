export enum ClientUserTokenType {
    None = 0,
    EntityPortalMagicLinkToken = 1,
    EntityPortalRefreshToken = 2,
    ClientPortalInviteWizardToken = 3,
    ClientPortalEntityModuleToken = 4,
    ClientPortalEntityProfileToken = 5,
    ClientPortalProcess = 6,
    ClientPortalProcessPdf = 7,
}

export function getClientUserTokenTypeAllowedRoutes(type: ClientUserTokenType): string[] {
    const baseRoutes = ['/auth-v2/login']

    switch (type) {
        case ClientUserTokenType.ClientPortalInviteWizardToken:
            return [...baseRoutes, '/portal/create-invite/launch'];
        case ClientUserTokenType.ClientPortalEntityProfileToken:
            return [...baseRoutes, '/portal/entities/{entityId}/*','/portal/processes/*'];
        default:
            return ['*'];
    }
}
