import { Injectable } from '@angular/core';

// ngrx | rxjs
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, mergeMap } from 'rxjs/operators';

// store
import * as actions from 'app/authentication-v2/store/actions/terms.actions';

// services
import { TermsService } from 'app/authentication-v2/services/terms.service';

@Injectable()
export class TermsEffects {

    getCurrentTerms$ = createEffect(() => this.actions$.pipe(
        ofType(actions.GetCurrentTerms),
        mergeMap(() =>
            this.termsService.getCurrentTerms$().pipe(
                map(terms => actions.GetCurrentTermsSuccess({ terms })),
                catchError(() => of(actions.GetCurrentTermsFail()))
        ))));

    constructor(
        private actions$: Actions,
        private termsService: TermsService) { }
}
