import { Injectable } from '@angular/core';

// ngrx | rxjs
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap, catchError, tap } from 'rxjs/operators';

// actions
import * as ProcessActions from 'app/portal/modules/process-list/store/actions/process.actions';

// services
import { ProcessService } from 'app/portal/modules/process-list/services/process.service';
import { AlertService } from 'app/shared/components/alert/services/alert.service';

@Injectable()
export class ProcessEffects {
    getProcess$ = createEffect(() => this.actions$.pipe(
        ofType(ProcessActions.GetProcess),
        switchMap((action) => this.processService.getProcess$(action.processId).pipe(
                map((process) => ProcessActions.GetProcessSuccess({process})),
                catchError(() => of(ProcessActions.GetProcessFail()))
            ))
    ));

    restartWorkflow$ = createEffect(() => this.actions$.pipe(
        ofType(ProcessActions.RestartWorkflow),
        switchMap((action) => this.processService.restartWorkflow$(action.processId, action.reinviteUser).pipe(
                map((process) => ProcessActions.RestartWorkflowSuccess({process})),
                catchError(() => of(ProcessActions.RestartWorkflowFail()))
            ))
    ));

    restartWorkflowFail$ = createEffect(() => this.actions$.pipe(
        ofType(ProcessActions.RestartWorkflowFail),
        tap(() => this.alertService.error('Unable to re-start the workflow on the process.'))
    ), { dispatch: false });

    closeWorkflow$ = createEffect(() => this.actions$.pipe(
        ofType(ProcessActions.CloseWorkflow),
        switchMap((action) => this.processService.closeWorkflow$(action.processId).pipe(
                map((process) => ProcessActions.CloseWorkflowSuccess({process})),
                catchError(() => of(ProcessActions.CloseWorkflowFail()))
            ))
    ));

    closeWorkflowFail$ = createEffect(() => this.actions$.pipe(
        ofType(ProcessActions.CloseWorkflowFail),
        tap(() => this.alertService.error('Unable to close the workflow on the process.'))
    ), { dispatch: false });

    getMagicLinkClientUser$ = createEffect(() => this.actions$.pipe(
        ofType(ProcessActions.GetMagicLinkClientUser),
        switchMap((action) => this.processService.getMagicLinkClientUser$(action.processId).pipe(
                map((magicLink) => ProcessActions.GetMagicLinkClientUserSuccess({magicLink})),
                catchError(() => of(ProcessActions.GetMagicLinkClientUserFail()))
            ))
    ));

    constructor(
        private actions$: Actions,
        private processService: ProcessService,
        private alertService: AlertService) { }
}