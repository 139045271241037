import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-copy-button',
    templateUrl: 'copy-button.component.html',
    styleUrls: ['copy-button.component.scss'],
})
export class CopyButtonComponent {
    @Input()
    icon: string;

    @Input()
    label: string;

    @Input()
    labelWhenNull: string = '-';

    copied = false;

    onClick() {
        if (!this.label) {
            return;
        }

        navigator.clipboard.writeText(this.label);

        this.copied = true;

        setTimeout(() => {
            this.copied = false;
        }, 1000);
    }
}
