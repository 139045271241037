<app-brand-panel *ngIf="visible" id="help-faq-search" [expandable]="true" fxLayout="column" fxLayoutGap="24px" [showLogo]="false" (closeClicked)="closeBrandPanel()" (expandClicked)="brandPanelExpand($event)">
    <div class="primary-bg brand-panel-header">
        <h1 class="margin-top-none margin-bottom-none white-text">How can we help?</h1>
    </div>

    <div class="help-faq-search-content padding-20" fxLayout="column" fxLayoutGap="16px" fxLayoutAlign="space-between">
        <div fxFlex="grow">
           <div *ngIf="brandPanelComponent?.expanded" class="info-panel margin-bottom-10 primary-bg primary-reverse-text padding-20 border-radius-top-5 white-text">
                <h3 class="margin-top-none">{{ selectedHelpFaq?.title }}</h3>
                <div>{{ selectedHelpFaq?.description }}</div>
            </div>
            <div fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap="24px" class="content-panel">
                <div fxLayout="column" fxFlex="{{ brandPanelComponent?.expanded ? '23%' : '100%' }}">
                    <div class="search_box">
                        <mat-form-field appearance="outline">
                            <i class="fa fa-light fa-search"></i>
                            <input matInput placeholder="Search Help and FAQ items" (input)="handleSearch(searchInput.value)" #searchInput/>
                        </mat-form-field>
                        <app-connect-tooltip id="client-portal-help-faqs-search-help-faqs-search-bar"></app-connect-tooltip>
                    </div>
            
                    <div class="search_items padding-right-10">
                        <app-connect-tooltip id="client-portal-help-faqs-search-help-faqs-search-item"></app-connect-tooltip>

                        <div *ngIf=" !helpFaqs || helpFaqs.length === 0">No Help and FAQs found</div>
                        <div *ngFor="let helpFaq of helpFaqs" (click)="handleHelpFaqSelected(helpFaq)"
                            class="search_item round-corners shadow-light padding-20 border-radius-5 margin-bottom-20 pointer"
                            [hidden]="brandPanelComponent?.expanded && selectedHelpFaq?.id === helpFaq?.id">
                            <h3 class="margin-top-none font-size-regular">{{ helpFaq.title }}</h3>
                            <p class="margin-bottom-none font-size-s">{{ helpFaq.description }}</p>
                        </div>
                    </div>
                </div>

                <div *ngIf="brandPanelComponent?.expanded" fxLayout="column" fxFlex="60%" class="padding-20 content-description">
                    <app-connect-tooltip id="client-portal-help-faqs-search-help-faqs-search-item-content"></app-connect-tooltip>

                    <div *ngIf="selectedHelpFaq?.content" [innerHtml]="selectedHelpFaq?.content | safe: 'html'" class="content-description-html"></div>
                </div>

                <div *ngIf="brandPanelComponent?.expanded" fxLayout="column" fxFlex="20%" class="steps padding-right-10">
                    <div *ngFor="let helpFaqStep of selectedHelpFaq?.helpFaqSteps; let i = index" class="step shadow-light pointer white-bg margin-bottom-10 round-corners" (click)="showHelpFaqStep(helpFaqStep)">
                        <img [src]="'api/v2/help-faq-steps/' + helpFaqStep.id + '/image'" class="step-image padding-5">
                        <div class="padding-15">
                            <h3 class="margin-top-none text body"><b>Step {{ helpFaqStep.stepNumber }}</b></h3>
                            <p class="grey-350-text text font-body-xsmall margin-bottom-none">{{ helpFaqStep.description }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!brandPanelComponent?.expanded">            
            <button class="btn primary dark large icon-r full-width" (click)="redirectToSupport()">
                Contact support
                <i class="fa-solid fa-comments-question"></i>
            </button>
        </div>
    </div>
</app-brand-panel>
<app-loading-card *ngIf="loading$ | async"></app-loading-card>
