import { Component, EventEmitter, Input, Output } from '@angular/core';


// Replaced by ColorInputComponent and scheduled to be deleted once story 12976 is complete
@Component({
    selector: 'app-color-picker',
    templateUrl: './color-picker.component.html',
    styleUrls: ['./color-picker.component.scss']
})
export class ColorPickerComponent {
    @Input()
    label: string;

    @Input()
    color: string;

    @Input()
    helpText: string;

    @Output()
    colorReset: EventEmitter<void> = new EventEmitter();

    @Output()
    colorSelected: EventEmitter<string> = new EventEmitter();

    onColorResetClick(): void {
        this.colorReset.emit();
    }

    onSelectColor(color: string): void {
        this.colorSelected.emit(color);
    }
}
