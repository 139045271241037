// models
import { EnumValueTitle } from 'app/shared/models/enum-value-title.model';

// enums
import { LozengeVariant } from 'app/connect/enums/lozenge-variant.enum';

export enum EntityStatus {
    NotInvited = 0,
    Invited = 1,
    Active = 2,
    Inactive = 3,
    Expired = 6,
    BouncedInvite = 7
}

export const EntityStatuses = [
    new EnumValueTitle(EntityStatus.NotInvited, 'Not invited'),
    new EnumValueTitle(EntityStatus.Invited, 'Invited'),
    new EnumValueTitle(EntityStatus.Active, 'Active'),
    new EnumValueTitle(EntityStatus.Inactive, 'Inactive'),
    new EnumValueTitle(EntityStatus.Expired, 'Expired'),
    new EnumValueTitle(EntityStatus.BouncedInvite, 'Invite bounced'),
];

export function getEntityStatusText(entityStatus: EntityStatus): string {
    return EntityStatuses.find(x => x.value === entityStatus)?.title;
}

export function getEntityStatusClassName(entityStatus: EntityStatus): string {
    switch (entityStatus) {
        case EntityStatus.Invited:
            return 'invited';
        case EntityStatus.Active:
            return 'active';
        case EntityStatus.NotInvited:
            return 'new';
        case EntityStatus.BouncedInvite:
            return 'bounced';
        case EntityStatus.Expired:
        case EntityStatus.Inactive:
            return 'expired';
    }
}

export function getEntityStatusLozengeVariant(entityStatus: EntityStatus): LozengeVariant {
    switch(entityStatus) {
        case EntityStatus.Active:
            return LozengeVariant.Green;
        case EntityStatus.BouncedInvite:
            return LozengeVariant.Red;
        case EntityStatus.Invited:
            return LozengeVariant.Amber;
        case EntityStatus.NotInvited:
            return LozengeVariant.Black;
        case EntityStatus.Expired:
        case EntityStatus.Inactive:
            return LozengeVariant.Gray;

    }
}
