import { Component, Input, OnInit } from '@angular/core';

// ngrx | rxjs
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

// store
import * as fromConnect from 'app/connect/store';

// models
import { Client } from 'app/models/client.model';
import { User } from 'app/models/user.model';
import { LogoutOptions } from 'app/models/logout-options.model';

@Component({
    selector: 'app-client-selector',
    templateUrl: './client-selector.component.html',
    styleUrls: ['./client-selector.component.scss']
})
export class ClientSelectorComponent implements OnInit {

    @Input()
    logoutOnBackClick: boolean = false;

    @Input()
    backLabel: string = 'Back';

    @Input()
    float: boolean = true;

    @Input()
    showClose = true;

    user$: Observable<User>;
    client$: Observable<Client>;
    visible$: Observable<boolean>;
    searchTerm: string;

    constructor(private connectStore: Store<fromConnect.ConnectStoreState>) { }

    ngOnInit(): void {
        this.user$ = this.connectStore.select(fromConnect.getUser);
        this.client$ = this.connectStore.select(fromConnect.getClient);
        this.visible$ = this.connectStore.select(fromConnect.getShowClientSelector);
    }

    handleSearch(searchTerm: string ): void {
        this.searchTerm = searchTerm.toLocaleLowerCase().trim();
    }

    handleClientSelected(client: Client): void {
        if(client.locked) {
            return;
        }

        this.connectStore.dispatch(fromConnect.SelectClient({ clientId: client.id, performRedirect: true, setServiceFromUrl: false }));
        this.connectStore.dispatch(fromConnect.CloseClientSelector());
    }

    handleBackLinkClicked(): void {
        if (this.logoutOnBackClick) {
            this.connectStore.dispatch(fromConnect.LogoutUser({options: new LogoutOptions(true, true, null)}));
        }
        this.connectStore.dispatch(fromConnect.CloseClientSelector());
    }

    filterClients(clients: Client[]): Client[] {
        if (!this.searchTerm) {
            return clients;
        }

        return clients.filter(c => c.name.toLocaleLowerCase().includes(this.searchTerm));
    }

    closeBrandPanel(): void {
        this.connectStore.dispatch(fromConnect.CloseClientSelector());
    }
}