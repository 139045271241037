// ngrx
import { Action, createReducer, on } from '@ngrx/store';

// actions
import * as ProcessActions from 'app/portal/modules/process-list/store/actions/process.actions';
import * as ProcessListActions from 'app/portal/modules/process-list/store/actions/process-list.actions';

// models
import { ProcessDetails } from 'app/portal/modules/process-details/models/process-details.model';

export interface ProcessState {
    loadingProcess: boolean;
    process: ProcessDetails;
    updatingProcess: boolean;
    validating: boolean;
    magicLinkClientUser: string;
    magicLinkClientUserLoading: boolean;
    magicLinkClientUserFailed: boolean;
}

const initialState: ProcessState = {
    loadingProcess: false,
    process: null,
    updatingProcess: false,
    validating: false,
    magicLinkClientUser: null,
    magicLinkClientUserLoading: false,
    magicLinkClientUserFailed: false
};

const processReducer = createReducer(
    initialState,

    on(ProcessActions.GetProcess, (state: ProcessState) => ({
        ...state,
        loadingProcess: true
    })),
    on(ProcessActions.GetProcessSuccess, (state: ProcessState, action) => ({
        ...state,
        loadingProcess: false,
        process: action.process
    })),
    on(ProcessActions.GetProcessFail, (state: ProcessState) => ({
        ...state,
        loadingProcess: false
    })),
    on(ProcessActions.RestartWorkflow, (state: ProcessState) => ({
        ...state,
        updatingProcess: true
    })),
    on(ProcessActions.RestartWorkflowSuccess, (state: ProcessState, action) => ({
        ...state,
        updatingProcess: false,
        process: action.process
    })),
    on(ProcessActions.RestartWorkflowFail, (state: ProcessState) => ({
        ...state,
        updatingProcess: false
    })),
    on(ProcessActions.CloseWorkflow, (state: ProcessState) => ({
        ...state,
        updatingProcess: true
    })),
    on(ProcessActions.CloseWorkflowSuccess, (state: ProcessState, action) => ({
        ...state,
        updatingProcess: false,
        process: action.process
    })),
    on(ProcessActions.CloseWorkflowFail, (state: ProcessState) => ({
        ...state,
        updatingProcess: false
    })),
    on(ProcessListActions.LockProcessSuccess, (state, action) => ({
        ...state,
        process: state.process ? state.process.id === action.processId ? { ...state.process, locked: true }: state.process : null
    })),
    on(ProcessListActions.UnlockProcessSuccess, (state, action) => ({
        ...state,
        process: state.process ? state.process.id === action.processId ? { ...state.process, locked: false }: state.process : null
    })),
    on(ProcessActions.StartProcessValidation, (state: ProcessState) => ({
        ...state,
        validating: true
    })),
    on(ProcessActions.StopProcessValidation, (state: ProcessState) => ({
        ...state,
        validating: false
    })),
    on(ProcessActions.GetMagicLinkClientUser, (state: ProcessState) => ({
        ...state,
        magicLinkClientUserFailed: false,
        magicLinkClientUserLoading: true
    })),
    on(ProcessActions.GetMagicLinkClientUserSuccess, (state: ProcessState, action) => ({
        ...state,
        magicLinkClientUserFailed: false,
        magicLinkClientUserLoading: false,
        magicLinkClientUser: action.magicLink
    })),
    on(ProcessActions.GetMagicLinkClientUserFail, (state: ProcessState) => ({
        ...state,
        magicLinkClientUserFailed: true,
        magicLinkClientUserLoading: false,
        magicLinkClientUser: null
    }))
    );

export function reducer(state: ProcessState | undefined, action: Action) {
    return processReducer(state, action);
}