export class StoreNames {
    static accreditation = 'accreditation';
    static authentication = 'authentication';
    static authenticationV2 = 'authentication-v2';
    static brandingAdverstisments = 'branding-advertisments';
    static brandingClientBranding = 'branding-client-branding';
    static brandingEntityBranding = 'branding-entity-branding';
    static connectCommon = 'connect-common';
    static connectIdDocuments = 'connect-id-documents';
    static connectIdentityVerification = 'connect-identity-verification';
    static connectManagementApplications = 'connect-management-applications';
    static connectManagementClients = 'connect-management-clients';
    static connectManagementRoles = 'connect-management-roles';
    static connectManagementSuperUsers = 'connect-management-super-users';
    static connectManagementTerms = 'connect-management-terms';
    static connectUsers = 'connect-users';
    static customisationCentreJourneys = 'customisation-centre-journeys';
    static customisationCentreSmsTemplates = 'customisation-centre-sms-templates';
    static createInvite = 'create-invite';
    static createInviteFolder = 'create-invite-folder';
    static dataCheckStore = 'data-check-store';
    static dataConnectorTaskStore = 'data-connector-task-store';
    static documentLibrary = 'document-library';
    static documents = 'documents';
    static endUsers = 'end-users';
    static entities = 'entities';
    static entityList = 'entity-list';
    static esignTaskStore = 'esign-task-store';
    static forms = 'forms';
    static formTaskStore = 'form-task-store';
    static groups = 'groups';
    static identityVerification = 'identity-verification';
    static identityVerificationTaskStore = 'identity-verification-task-store';
    static homepage = 'homepage';
    static informationPages = 'information-pages';
    static informationPageTaskStore = 'information-page-task-store';
    static infoRoadmaps = 'info-roadmaps';
    static infoGlossary ='info-glossary';
    static infoGuides ='info-guides';
    static lookupLists = 'lookup-lists';
    static mobilePhotoStore = 'mobile-photo-store';
    static modulePricing = 'module-pricing';
    static notes = 'notes';
    static openBankingTaskStore = 'open-banking-task-store';
    static organisationManagement = 'organisation-management';
    static portalDocumentLibrary = 'portal-document-library';
    static processList = 'process-list';
    static productConfigurationEmailTemplateTypes = 'product-configuration-email-template-types';
    static productConfigurationNonBiometricDocumentTypes = 'product-configuration-non-biometric-document-types';
    static productConfigurationRoadmaps = 'product-configuration-roadmaps';
    static productConfigurationTooltips = 'product-configuration-tooltips';
    static productConfigurationPackages = 'product-configuration-packages';
    static productConfigurationGlossary = 'product-configuration-glossary';
    static productConfigurationGuides = 'product-configuration-guides';
    static productConfigurationTelephoneNumbers = 'product-configuration-telephone-numbers';
    static productConfigurationNotifications = 'product-configuration-notifications';
    static productConfigurationID3ProfileMappings = 'product-configuration-id3-profile-mappings';
    static roadmap = 'roadmap';
    static glossary = 'glossary';
    static setObservations = 'set-observations';
    static setTemplates = 'set-templates';
    static shared = 'shared';
    static submitTaskStore = 'submit-task-store';
    static tableTaskStore = 'table-task-store';
    static telephoneNumbersV1Store = 'telephone-numbers-v1-store';
    static templateManagement = 'template-management';
    static termsManagement = 'terms-management';
    static userGroups = 'user-groups';
    static userManagement = 'user-management';
    static users = 'users';
    static portalSettings = 'portal-settings';
    static webhookTaskStore = 'webhook-task-store';
    static clientUsers = 'client-users';
    static rtxChecks = 'rtx-checks';
    static safeHarbourChecks = 'safe-harbour-checks';
    static backOfficeVerifier = 'back-office-verifier';
    static dashboard = 'dashboard';
    static openBankingChecks = 'open-banking-checks';
    static identityRetry = 'identity-retry';
    static folderList = 'folder-list';
    static companyList = 'company-list';
    static inviteWizard = 'invite-wizard';
    static sourceOfFunds = 'source-of-funds';
}
