<app-authentication-layout id="forgot-credentials-container" fxFlex pageTitle="Enter your email" data-auto="forgot-credentials-container">
    <app-loading-card *ngIf="(working$ | async)"></app-loading-card>
    <form name="form" [formGroup]="form" novalidate fxLayout="column">
        <p class="font-size-s">
            This is the email address you use to log in.
        </p>
        <div>
            <mat-form-field appearance="outline">
                <input placeholder="Email address" matInput formControlName="email" type="email" data-auto="email-input">
                <mat-error *ngIf="form.get('email').hasError('required')">
                    Email is required
                </mat-error>
                <mat-error *ngIf="!form.get('email').hasError('required') &&
                                form.get('email').hasError('email')">
                    Please enter a valid email address
                </mat-error>
            </mat-form-field>
        </div>
        <button class="btn large primary dark" (click)="onSubmitClick()"
                aria-label="Get me in" [disabled]="form.invalid" data-auto="submit-button">
            Get me in
        </button>

        <a (click)="navigation.gotoLogin()" class="margin-top-24 text-connect-primary" fxFlex fxLayout="row" fxLayoutAlign="flex-start center">
            <mat-icon>chevron_left</mat-icon>
            <div>Back to login</div>
        </a>
    </form>
</app-authentication-layout>