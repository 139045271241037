import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

// ngrx | rxjs
import { Store, select, ActionsSubject } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { ofType } from '@ngrx/effects';

// store
import * as fromAuth from 'app/authentication-v2/store';
import * as fromConnect from 'app/connect/store';

// components
import { BaseComponent } from 'app/shared/base/base-component';

// services
import { NavigationService } from 'app/shared/services/navigation.service';
import { AlertService } from 'app/shared/components/alert/services/alert.service';

// models
import { RegExRules } from 'app/shared/models';
import { ValueChangedEvent } from 'app/connect/components/code-entry/code-entry.component';
import { OtpCheckDialogData } from 'app/authentication-v2/models/otp-check-dialog-data.model';

// enums
import { ContactType } from 'app/authentication-v2/enumerations/contact-type.enum';

@Component({
    templateUrl: 'otp-check-dialog.component.html'
})
export class OtpCheckDialogComponent extends BaseComponent implements OnInit {
    RegExRules = new RegExRules();
    ContactType = ContactType;

    working: boolean;
    form: FormGroup;
    type?: ContactType;
    confirmationId: Guid;

    constructor(
        @Inject(MAT_DIALOG_DATA) data: OtpCheckDialogData,
        private store: Store<fromAuth.AuthenticationState>,
        private fb: FormBuilder,
        public navigation: NavigationService,
        private dialogRef: MatDialogRef<OtpCheckDialogComponent>,
        private actionsSubject: ActionsSubject,
        private alertService: AlertService
    ) {
        super();

        this.confirmationId = data.confirmationId;
        this.type = data.type;
    }

    get source(): string {
        return this.type === ContactType.Phone ? 'a text' : this.type === ContactType.Email ? 'an email' : 'a message';
    }

    get device(): string {
        return this.type === ContactType.Phone ? 'your mobile' : this.type === ContactType.Email ? 'your email address' : 'you';
    }

    get pageTitle(): string {
        const source = this.type === ContactType.Phone ? 'texts' : this.type === ContactType.Email ? 'emails' : 'messages';

        return `Check your ${source}`;
    }

    ngOnInit(): void {
        this.store.pipe(
            takeUntil(this.ngUnsubscribe),
            select(fromAuth.getAuthenticationWorking))
            .subscribe((working: boolean) => this.working = working);

        this.actionsSubject
            .pipe(
                takeUntil(this.ngUnsubscribe),
                ofType(fromAuth.ConfirmOtpSuccess)
            )
            .subscribe(() => {
                this.dialogRef.close();
                this.alertService.success(`We have updated ${this.device}.`);
                this.store.dispatch(fromConnect.RehydrateUser());
            });

        this.form = this.fb.group({
            otpCode: [null, [Validators.required, Validators.pattern(this.RegExRules.otp_code)]]
        });
    }

    onEnterPressed() {
        if (this.form.invalid) {
            return;
        }

        this.store.dispatch(fromAuth.ConfirmOtp({
            code: this.form.value.otpCode,
            confirmationId: this.confirmationId
        }));
    }

    onValueChanged(event: ValueChangedEvent): void {
        this.form.get('otpCode').setValue(event.code);
    }
}