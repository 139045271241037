import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

// rxjs
import { Observable } from 'rxjs';

// models
import { UserAlert } from 'app/connect/models/user-alert.model';

@Injectable()
export class UserAlertService {
    baseUrl = 'api/v2/user-alerts';

    constructor(private http: HttpClient) {}

    getAlerts$(): Observable<UserAlert[]> {
        return this.http.get<UserAlert[]>(this.baseUrl);
    }

    readAlert$(id: number): Observable<UserAlert> {
        return this.http.put<UserAlert>(`${this.baseUrl}/${id}/read`, {});
    }

    dismissAlert$(id: number): Observable<UserAlert> {
        return this.http.put<UserAlert>(`${this.baseUrl}/${id}/dismiss`, {});
    }
}
