import { ActionReducerMap } from '@ngrx/store';

import * as fromGlobalUi from './user-interface.reducers';
import * as fromRouter from './router.reducers';

export interface State {
    globalUi: fromGlobalUi.State;
    router: fromRouter.State;
}

export const reducers: ActionReducerMap<State> = {
    globalUi : fromGlobalUi.reducer,
    router: fromRouter.reducer
};