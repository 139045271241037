import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// rxjs
import { Observable } from 'rxjs';

// models
import { User } from 'app/models/user.model';
import { RequestPinResetResponse } from 'app/authentication-v2/models/request-pin-reset-response.model';
import { RequestPasswordResetResponse } from 'app/authentication-v2/models/request-password-reset-response.model';
import { LoginResponse } from 'app/models/login-response.model';
import { AuthenticatorSetup } from 'app/authentication-v2/models/authenticator-setup.model';

// enums
import { UserAuthenticationMethod } from 'app/shared/enums/user-authentication-method.enum';

@Injectable()
export class UserProfileService {
    constructor(private http: HttpClient) { }

    private baseUrl = 'api/v2/user-profile';

    setPin$(pin: string, token: string): Observable<User> {
        const url = `${this.baseUrl}/set-pin`;
        const request = {
            newPin: pin,
            token
        };
        return this.http.put<User>(url, request);
    }

    setMobileNumber$(mobileNumber: string, diallingCode: string): Observable<{ confirmationId: Guid }> {
        const url = `${this.baseUrl}/set-mobile-number`;
        const request = {
            diallingCode,
            mobileNumber
        };
        return this.http.put<{ confirmationId: Guid }>(url, request);
    }

    confirmOtp$(code: string, confirmationId: Guid): Observable<{ user: User, expired: boolean, passwordResetToken?: string }> {
        const url = `${this.baseUrl}/confirm-otp`;
        const request = {
            code,
            confirmationId
        };
        return this.http.put<{ user: User, expired: boolean, passwordResetToken?: string }>(url, request);
    }

    confirmPin$(pin: string): Observable<LoginResponse<User>> {
        const url = `${this.baseUrl}/confirm-pin`;
        const request = {
            pin
        };
        return this.http.post<LoginResponse<User>>(url, request);
    }

    triggerSecurityReset$(credential: string): Observable<{ confirmationId: Guid; notFound: boolean }> {
        const url = `${this.baseUrl}/trigger-security-reset`;
        const request = {
            credential
        };
        return this.http.post<{ confirmationId: Guid; notFound: boolean }>(url, request);
    }

    requestPinReset$(): Observable<RequestPinResetResponse> {
        const url = `${this.baseUrl}/pin-reset`;
        return this.http.post<RequestPinResetResponse>(url, {});
    }

    requestPasswordReset$(): Observable<RequestPasswordResetResponse> {
        const url = `${this.baseUrl}/password-reset`;
        return this.http.post<RequestPasswordResetResponse>(url, {});
    }

    generateAuthenticatorSetup$(): Observable<AuthenticatorSetup> {
        const url = `${this.baseUrl}/generate-authenticator-setup`;
        return this.http.get<AuthenticatorSetup>(url);
    }

    confirmAuthenticatorSetup$(authenticatorCode: string): Observable<User> {
        const url = `${this.baseUrl}/confirm-authenticator-setup`;
        const request = {
            authenticatorCode,
        };
        return this.http.put<User>(url, request);
    }

    validateAuthenticatorCode$(authenticatorCode: string): Observable<LoginResponse<User>> {
        const url = `${this.baseUrl}/validate-authenticator-code`;
        const request = {
            authenticatorCode,
        };
        return this.http.post<LoginResponse<User>>(url, request);
    }

    confirmAuthenticationMethod$(authenticationMethod: UserAuthenticationMethod): Observable<User> {
        const url = `${this.baseUrl}/confirm-authentication-method`;
        const request = {
            authenticationMethod,
        };
        return this.http.post<User>(url, request);
    }
}
