import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

// models
import { FolderEntity } from 'app/portal/modules/folder-list/models/folder-entity.model';
import { EntityDetails } from 'app/portal/modules/entity-list/models/entity-details.model';
import { EntitySummary } from 'app/portal/modules/entity-list/models/entity-summary.model';

// enums
import { EntityApprovalStatus, getEntityApprovalStatusIcon, getEntityApprovalStatusTitle } from 'app/portal/modules/entity-list/enumerations/entity-approval-status.enum';
import { getEntityStatusText } from 'app/portal/modules/entity-list/enumerations/entity-status.enum';

type EntityType = EntityDetails | EntitySummary | FolderEntity;

@Component({
    selector: 'app-entity-merged-status',
    templateUrl: 'entity-merged-status.component.html',
    styleUrls: ['entity-merged-status.component.scss'],
})
export class EntityMergedStatusComponent implements OnChanges {
    @Input()
    entity: EntityType;

    @Input()
    variant: 'default' | 'icon' = 'default';

    getEntityApprovalStatusIcon = getEntityApprovalStatusIcon;

    helpText: string;

    ngOnChanges(changes: SimpleChanges) {
        if (changes.entity) {
            this.setHelpText(changes.entity.currentValue);
        }
    }

    setHelpText(entity: EntityType) {
        if (!entity) {
            return;
        }

        this.helpText = `Approval status is ${getEntityApprovalStatusTitle(
            entity.approvalStatus
        ).toLowerCase()} \nInvite status is ${getEntityStatusText(entity.status).toLowerCase()}`;
    }

    getApprovalStatusClassName(approvalStatus: EntityApprovalStatus) {
        switch (approvalStatus) {
            case EntityApprovalStatus.Approved:
                return 'approved';
            case EntityApprovalStatus.Rejected:
                return 'rejected';
            case EntityApprovalStatus.Pending:
            default:
                return 'pending';
        }
    }
}
