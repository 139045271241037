import { Injectable } from '@angular/core';

// ngrx || rxjs
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';

// store
import * as fromStore from 'app/connect/store';

// services
import { PostcodeLookupService } from 'app/connect/services/postcode-lookup.service';
import { AlertService } from 'app/shared/components/alert/services/alert.service';

@Injectable()
export class PostcodeLookupEffects {
    lookupPostcode$ = createEffect(() =>
        this.actions.pipe(
            ofType(fromStore.LookupPostcode),
            switchMap((a) =>
                this.postcodeLookupService.postCodeLookup$(a.postcode).pipe(
                    map((r) =>
                        r.isSuccess ?
                            fromStore.LookupPostcodeSuccess({ addresses: r.addresses }) :
                            fromStore.LookupPostcodeFail()
                    ),
                    catchError(() => of(fromStore.LookupPostcodeFail()))
                )
            )
        )
    );

    lookupPostcodeFail$ = createEffect(() => this.actions.pipe(
        ofType(fromStore.LookupPostcodeFail),
        tap(() => this.alertService.error('Could not lookup the postcode.'))
    ), { dispatch: false });

    constructor(
        private actions: Actions,
        private postcodeLookupService: PostcodeLookupService,
        private alertService: AlertService
    ) {}
}
