import { DisplayScreen, MobileDevice } from 'app/shared/enums';
import * as UserInterfaceActions from 'app/store/actions/user-interface.actions';

export type Action = UserInterfaceActions.AllUserInterfaceActions;

export interface State {
    displayScreenType: DisplayScreen;
    mobileDeviceType: MobileDevice;
    isMobileDevice: boolean;
}

const initialState: State = {
    displayScreenType: null,
    mobileDeviceType: null,
    isMobileDevice: false
};

export function reducer(state = initialState, action: Action): State {
    switch (action.type) {
        case UserInterfaceActions.SET_DISPLAY_SCREEN_TYPE: {
            return Object.assign({}, state, {
                displayScreenType: action.payload
            });
        }

        case UserInterfaceActions.SET_MOBILE_DEVICE_TYPE: {
            return Object.assign({}, state, {
                mobileDeviceType: action.payload,
                isMobileDevice: action.payload === MobileDevice.Android || action.payload === MobileDevice.iOS
            });
        }

        default: {
            return state;
        }
    }
}
