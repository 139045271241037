<app-authentication-layout id="contact-administrator" fxFlex pageTitle="Contact your company administrator" data-auto="contact-administrator">
    <p class="font-size-s font-weight-bold">
        For security reasons, we can't reset your email or mobile number.
    </p>
    <p class="font-size-s">
        No need to panic though, your company administrator will be able to update your details or reset your password.
    </p>

    <div class="margin-bottom-20">
        <a class="btn large primary dark full-width"
                aria-label="Back to login page"
                (click)="navigation.gotoLogin()">
            Back to login page
        </a>
    </div>

    <div>
        <a class="btn large full-width secondary"
                aria-label="Close this window"
                (click)="onCloseWindowClick()">
            Close this window
        </a>
    </div>
</app-authentication-layout>