<header *ngIf="(showHeader$ | async)" class="connect-header">
    <div class="inset-container wrapper">
        <img *ngIf="logoUrl && showClientLogo$ | async"
             class="pointer"
             height="40px"
             [src]="logoUrl | safe: 'resourceUrl'"
             (click)="onLogoClicked()" />
        <div class="separator"></div>
        <nav *ngIf="showNavigationMenu$ | async">
            <ul>
                <li *ngIf="(applications$ | async) && showNavigationMenu$ | async">
                    <a fxLayout="row"
                       fxLayoutGap="8px"
                       data-auto="header-portal-dropdown"
                       [matMenuTriggerFor]="applicationsMenu">
                        <span class="secondary-text" data-testid="header-portal-dropdown">
                            <strong>{{ currentApplication?.title }}</strong>
                        </span>
                        <i class="fa fa-light fa-angle-down"></i>
                    </a>
                    <mat-menu #applicationsMenu="matMenu" data-auto="header-applications-menu">
                        <a *ngFor="let application of applications$ | async"
                           mat-menu-item
                           (click)="onApplicationChange(application.id)"
                           [attr.data-auto]="'header-portal-application-' + getDataAutoModuleName(application.id)">
                            {{application.title }}
                        </a>
                    </mat-menu>
                </li>
                <li *ngFor="let service of services$ | async">
                    <a routerLinkActive="active"
                       [routerLink]="service.url"
                       [title]="service.description ?? ''"
                       [attr.data-auto]="'header-portal-module-' + getDataAutoModuleName(service.id)"
                       (click)="onServiceClick(service.id)">{{service.title}}</a>
                </li>
            </ul>
        </nav>

        <div fxLayout="row" fxLayoutAlign="end">
            <button *ngIf="!(supportUrl$ | async)" class="btn empty icon action-button"
                    (click)="onOpenHelpFaqSearchClick()">
                <i class="fa fa-light fa-circle-info"></i>
            </button>
            <a *ngIf="(supportUrl$ | async) as url"
               class="btn empty icon action-button"
               target="_blank"
               (click)="onOpenHelpFaqSearchNotClick($event)"
               [href]="url"> 
                <i class="fa fa-light fa-circle-info"></i>
            </a>
            <button class="btn empty icon-right action-button notification-bell-wrapper"
                    (click)="onNotificationBellClick()"
                    data-auto="header-portal-notification-bell">
                <span *ngIf="unreadCount$ | async" class="notification-bell-count secondary-bg">
                    {{unreadCount$ | async}}
                </span>
                <i class="fa fa-light fa-bell"></i>
            </button>
            <button *ngIf="showUserProfileButton$ | async"
                    class="menu-button border-radius-5"
                    [title]="userName"
                    (click)="onUserMenuClick()">{{userInitials}}
            </button>
        </div>
        <app-user-menu></app-user-menu>
    </div>
</header>