import { Injectable } from '@angular/core';

// ngrx || rxjs
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { switchMap, map, filter } from 'rxjs/operators';
import { of } from 'rxjs';

// store
import * as fromAuth from 'app/authentication-v2/store';
import * as fromStore from 'app/connect/store';

// services
import { AuthenticationTokenService } from 'app/shared/services/authentication-token.service';

// enums
import { SignalRHub } from 'app/connect/enums/signalr-hub.enum';

const MessagingHubEvents = Object.freeze({
    ReceiveNotification: 'ReceiveNotification',
    ReceiveAlert: 'ReceiveAlert',
    ReceiveVerifierTaskTimeout: 'ReceiveVerifierTaskTimeout'
});

const MessagingHubActions = Object.freeze({
    SetClientGroup: 'SetClientGroup',
});

@Injectable()
export class MessagingHubEffects {
    connectHub$ = createEffect(() =>
        this.actions.pipe(
            ofType(fromStore.SetTokenSuccess),
            switchMap(() =>
                of(
                    fromStore.StartHubConnection({
                        hub: SignalRHub.Messaging,
                        events: [
                            {
                                name: MessagingHubEvents.ReceiveNotification,
                                returnAction: fromStore.ReceiveNotification,
                            },
                            {
                                name: MessagingHubEvents.ReceiveAlert,
                                returnAction: fromStore.ReceiveAlert,
                            },
                            {
                                name: MessagingHubEvents.ReceiveVerifierTaskTimeout,
                                returnAction: fromStore.ReceiveVerifierTaskTimeout,
                            },
                        ],
                    })
                )
            )
        )
    );

    rehydrateNotifications$ = createEffect(() =>
        this.actions.pipe(
            ofType(
                fromStore.SetUser,
                fromStore.SetAndPopulateClientSuccess,
                fromAuth.RehydrateUserSuccess,
                fromStore.SetUserForTokenLogin
            ),
            switchMap(() => [
                fromStore.GetNotifications(),
                fromStore.GetUserAlerts()
            ])
        )
    );

    hubConnected$ = createEffect(() =>
        this.actions.pipe(
            ofType(fromStore.StartHubConnectionSuccess),
            filter((result) => result.hub === SignalRHub.Messaging),
            filter(() => !!this.tokenService.clientId()),
            map(() => this.tokenService.clientId()),
            switchMap((clientId) => of(fromStore.SetClientGroup({ clientId })))
        )
    );

    setClientGroup$ = createEffect(() =>
        this.actions.pipe(
            ofType(fromStore.SetClientGroup),
            switchMap((action) =>
                of(
                    fromStore.InvokeAction({
                        hub: SignalRHub.Messaging,
                        methodName: MessagingHubActions.SetClientGroup,
                        args: [action.clientId],
                        successAction: fromStore.SetClientGroupSuccess({
                            clientId: action.clientId,
                        }),
                    })
                )
            )
        )
    );

    constructor(
        private actions: Actions,
        private tokenService: AuthenticationTokenService
    ) {}
}
