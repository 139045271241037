// ngrx
import { createReducer, on, Action } from '@ngrx/store';

// store
import * as actions from 'app/connect/store/actions/application.actions';
import { Client } from 'app/models/client.model';

// models
import { UserGroup } from 'app/models/user-group.model';
import { UserModule } from 'app/models/user-module.model';
import { User } from 'app/models/user.model';

export interface ApplicationState {
    user: User;
    client: Client;
    userGroup: UserGroup;
    applications: UserModule[];
    application: UserModule;
    services: UserModule[];
    service: UserModule;
    loading: boolean;
}

const initialState: ApplicationState = {
    user: null,
    client: null,
    userGroup: null,
    applications: null,
    application: null,
    services: null,
    service: null,
    loading: false
};

const applicationReducer = createReducer(
    initialState,
    on(actions.SetUser, (state, action) => ({
        ...state,
        user: action.user,
        applications: null,
        application: null,
        services: null,
        service: null
    })),
    on(actions.SelectClient, (state, action) => ({
        ...state,
        client: state.user.clients.find(x => x.id === action.clientId)
    })),
    on(actions.SetApplications, (state, action) => {
        const applications = action.modules?.filter(
            (m) =>
                !m.parentId &&
                m.showOnNavigation &&
                !!state.client.userModules.find(x => x.id === m.id)
        );

        applications.sort(
            (m1, m2) => m1.displayOrder - m2.displayOrder
        );

        return {
            ...state,
            applications,
        };
    }),
    on(actions.SetApplication, (state, action) => ({
        ...state,
        application: action.application,
    })),
    on(actions.SetServices, (state, action) => ({
        ...state,
        services: action.services,
    })),
    on(actions.SetService, (state, action) => ({
        ...state,
        service: action.service
    })),
    on(actions.SetAndPopulateClientSuccess, (state, action) => ({
        ...state,
        client: action.client
    })),
    on(actions.RehydrateUserSuccess, (state, action) => ({
        ...state,
        user: action.user,
        client: action.client,
        userGroup: action.userGroup,
        applications: null,
        application: null,
        services: null,
        service: null
    })),
    on(actions.SetUserForTokenLogin, (state, action) => ({
        ...state,
        user: action.user,
        client: action.client,
        applications: null,
        application: null,
        services: null,
        service: null
    })),
    on(actions.SetUserGroup, (state, action) => ({
        ...state,
        userGroup: action.userGroup
    })),
    on(actions.ClearUserGroup, (state) => ({
        ...state,
        userGroup: null
    })),
    on(actions.SetAndPopulateClient, (state) => ({
        ...state,
        loading: true
    })),
    on(actions.SetAndPopulateClientSuccess, (state) => ({
        ...state,
        loading: false
    })),
    on(actions.SetClientFail, (state) => ({
        ...state,
        loading: false
    })),
);

export function reducer(state: ApplicationState | undefined, action: Action) {
    return applicationReducer(state, action);
}
