import { Action, createReducer, on } from '@ngrx/store';

// store
import * as actions from 'app/connect/store/actions';

// models
import { UserAlert } from 'app/connect/models/user-alert.model';

export interface UserAlertState {
    alerts: UserAlert[];
    highlightedAlert: UserAlert;
    loading: boolean;
}

const initialState: UserAlertState = {
    alerts: [],
    highlightedAlert: null,
    loading: false,
};

const userAlertReducer = createReducer(
    initialState,
    on(actions.GetUserAlerts, (state) => ({
        ...state,
        alerts: [],
        loading: true,
    })),
    on(actions.GetUserAlertsSuccess, (state, action) => ({
        ...state,
        loading: false,
        alerts: action.alerts,
    })),
    on(actions.GetUserAlertsFail, (state) => ({
        ...state,
        loading: false,
    })),
    on(actions.ReadUserAlertSuccess, (state, action) => ({
        ...state,
        alerts: state.alerts.map((alert) =>
            alert.id === action.alert.id ? { ...alert, read: true } : alert
        ),
    })),
    on(actions.DismissUserAlertSuccess, (state, action) => ({
        ...state,
        alerts: state.alerts.filter((alert) => alert.id !== action.alert.id),
    })),
    on(actions.ReceiveAlert, (state, action) => ({
        ...state,
        alerts: [action.alert, ...state.alerts],
    })),
    on(actions.SetHighlightedAlert, (state, action) => ({
        ...state,
        highlightedAlert: action.alert,
    })),
    on(actions.ClearHighlightedAlert, (state) => ({ ...state, highlightedAlert: null }))
);

export function reducer(state: UserAlertState, action: Action) {
    return userAlertReducer(state, action);
}
