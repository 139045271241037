import { ProcessExportEffects } from './process-export.effects';
import { ProcessListEffects } from './process-list.effects';
import { ProcessEffects } from './process.effects';

export const effects: any[] = [
    ProcessExportEffects,
    ProcessListEffects,
    ProcessEffects
];

export * from './process-export.effects';
export * from './process-list.effects';
export * from './process.effects';
