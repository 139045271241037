<app-dialog dialogTitle="Access Denied"
            defaultWidth="430px"
            defaultHeight="auto">
    <div body>
        <p>
            Access granted via the magic link is restricted to the purpose the link is generated for.
            Please generate an alternative magic link to navigate to other areas of the system.
        </p>
        <p>Click the button below to go back to where you were.</p>
    </div>
    <div footer-actions fxLayout="column" fxLayoutGap="16px">
        <button class="btn large primary dark" (click)="onBack()">Take me back</button>
    </div>
</app-dialog>