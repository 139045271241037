import { EnumValueTitle } from 'app/shared/models/enum-value-title.model';

export enum PortalRegistrationType {
    GuestRegistration = 1,
    AccountRegistration = 2
}

export const PortalRegistrationTypes = [
    new EnumValueTitle(PortalRegistrationType.GuestRegistration, 'Guest registration'),
    new EnumValueTitle(PortalRegistrationType.AccountRegistration, 'Account registration')
];