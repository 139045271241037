<app-authentication-layout *ngIf="!isIframe" id="sign-in" fxFlex pageTitle="Sign In">
    <app-loading-card *ngIf="working"></app-loading-card>
    <div [formGroup]="form" class="sign-in-form" fxLayout="column" (keydown.enter)="loginUser()">
        <p>Enter your login details to access your account.</p>
        <div fxLayout="column">
            <div>
                <mat-form-field appearance="outline">
                    <input matInput placeholder="Email address" formControlName="email" autocomplete="off" type="text" data-auto="login-username">
                    <mat-error *ngIf="form.get('email').hasError('required')">Email is required</mat-error>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field appearance="outline">
                    <input matInput placeholder="Password" formControlName="password" autocomplete="off" [type]="hidePassword ? 'password' : 'text'" data-auto="login-password">
                    <mat-icon matSuffix class="pointer" (click)="hidePassword = !hidePassword">{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                    <mat-error *ngIf="form.get('password').hasError('required')">Password is required</mat-error>
                </mat-form-field>
            </div>

            <p class="mat-error" *ngIf="authenticationMessage" data-auto="login-error-message">
                {{authenticationMessage}}
            </p>

            <button
                class="btn large primary dark"
                [disabled]="working || form.invalid"
                (click)="loginUser()" data-auto="login-button"
                data-testid="get-me-in">
                Get me in
            </button>
            <div class="margin-top-24 font-size-xs">
                Can't remember your login details?
                <button (click)="navigation.gotoForgotLoginDetails()" class="text-link font-size-inherit">
                    Click here
                </button>
            </div>
        </div>
    </div>
</app-authentication-layout> 