import { Component, Input, ElementRef, ViewChild } from '@angular/core';

// components
import { SegmentedButtonGroupComponent } from 'app/shared/components/segmented-button-group/segmented-button-group.component';

@Component({
    selector: 'app-segmented-button',
    templateUrl: 'segmented-button.component.html',
    styleUrls: ['segmented-button.component.scss'],
})
export class SegmentedButtonComponent {
    @Input()
    id: string;

    @Input()
    value: any;

    @Input()
    checked: boolean;

    @ViewChild('button')
    buttonElement: ElementRef<HTMLButtonElement>;

    constructor(private buttonGroup: SegmentedButtonGroupComponent) {}

    onButtonChange() {
        this.buttonGroup.syncValue(this.value);
    }
}
