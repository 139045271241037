import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, ViewEncapsulation, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

// rxjs
import { distinctUntilChanged, takeUntil } from 'rxjs';

// components
import { BaseComponent } from 'app/shared/base/base-component';
@Component({
    selector: 'app-date-input',
    templateUrl: './date-input.component.html',
    styleUrls: ['./date-input.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DateInputComponent extends BaseComponent implements OnInit, OnChanges {

    dateForm: FormGroup;

    @Output()
    dateChanged: EventEmitter<Date> = new EventEmitter();

    @Output()
    dateTooEarly: EventEmitter<Date> = new EventEmitter();

    @Output()
    dateTooLate: EventEmitter<Date> = new EventEmitter();

    @Output()
    dateInvalid: EventEmitter<string> = new EventEmitter();

    @Input()
    label: string;

    @Input()
    minimumDate: string;

    @Input()
    maximumDate: string;

    @Input()
    initialDate: string;

    @Input()
    required: boolean;

    @Input()
    disabled: boolean;

    @ViewChild('month', { static: true }) monthField: ElementRef;

    @ViewChild('year', { static: true }) yearField: ElementRef;

    date: Date;

    minimumDateObject: Date;

    maximumDateObject: Date;

    constructor(private fb: FormBuilder) {
        super();
     }

    ngOnInit(): void {
        const initialDateObject: Date = this.getDate(this.initialDate);

        this.dateForm = this.fb.group({
            day: [ initialDateObject ? this.padDateComponent(initialDateObject.getDate()) : '' ],
            month: [ initialDateObject ? this.padDateComponent(initialDateObject.getMonth() + 1) : '' ],
            year: [ initialDateObject ? initialDateObject.getFullYear().toString() : '' ],
        });

        this.minimumDateObject = this.getDate(this.minimumDate);
        this.maximumDateObject = this.getDate(this.maximumDate);

        this.onChanges();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes?.disabled) {
            if (this.disabled) {
                this.dateForm?.disable();
            } else {
                this.dateForm?.enable();
            }
        }

        // Check if currentValue is set, otherwise any invalid date (null) would reset the form.
        if (changes?.initialDate &&
            changes.initialDate.currentValue &&
            this.dateForm
        ) {
            const initialDateObject: Date = this.getDate(this.initialDate);
            this.dateForm.patchValue({
                day: [ initialDateObject ? this.padDateComponent(initialDateObject.getDate()) : '' ],
                month: [ initialDateObject ? this.padDateComponent(initialDateObject.getMonth() + 1) : '' ],
                year: [ initialDateObject ? initialDateObject.getFullYear().toString() : '' ],
            });
            this.dateForm.clearValidators();
        }

        // We want to reset the form if an empty value has been passed
        if(this.dateForm && changes.initialDate && changes.initialDate.currentValue === '') {
            this.dateForm.patchValue({
                day: '',
                month: '',
                year: ''
            });

            this.dateForm.clearValidators();
            this.dateForm.markAsPristine();
        }
    }

    get value() {
        return this.date;
    }

    private getDate(dateString: string): Date {
        if (dateString) {
            if (dateString === 'today') {
                return new Date();
            }
            const dateTimestamp = Date.parse(dateString);
            if (!isNaN(dateTimestamp)) {
                return new Date(dateTimestamp);
            }
        }

        return null;
    }

    private onChanges(): void {
        this.dateForm.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe), distinctUntilChanged())
            .subscribe((val) => {
                if (!val.year && !val.month && !val.day) {
                    if (this.required) {
                        this.dateForm.setErrors({ dateRequired: true });
                    }

                    this.date = null;
                    this.dateChanged.emit(this.date);

                    return;
                } else if (val.year && val.month && val.day) {
                    const newDate = new Date(val.year, val.month - 1, val.day);

                    // Remove the timezone
                    const timeZoneDifference =
                        (newDate.getTimezoneOffset() / 60) * -1; //convert to positive value.
                    newDate.setTime(
                        newDate.getTime() + timeZoneDifference * 60 * 60 * 1000
                    );

                    if (
                        newDate.getDate() === parseInt(val.day, 10) &&
                        newDate.getMonth() === parseInt(val.month, 10) - 1 &&
                        newDate.getFullYear() === parseInt(val.year, 10) &&
                        parseInt(val.year, 10) > 999 &&
                        parseInt(val.year, 10) < 10000
                    ) {
                        if (
                            this.minimumDateObject &&
                            this.minimumDateObject > newDate
                        ) {
                            this.dateChanged.emit(null);
                            this.dateTooEarly.emit(newDate);
                            this.dateForm.setErrors({ dateTooEarly: true });
                            return;
                        }

                        if (
                            this.maximumDateObject &&
                            this.maximumDateObject < newDate
                        ) {
                            this.dateChanged.emit(null);
                            this.dateTooLate.emit(newDate);
                            this.dateForm.setErrors({ dateTooLate: true });
                            return;
                        }

                        this.date = new Date(newDate);
                        this.dateForm.setErrors(null);
                        this.dateChanged.emit(this.date);
                    } else {
                        this.dateChanged.emit(null);
                        this.dateInvalid.emit(
                            `${this.padDateComponent(
                                val.day
                            )}/${this.padDateComponent(val.month)}/${val.year}`
                        );
                        this.dateForm.setErrors({ dateInvalid: true });
                    }
                } else {
                    this.dateChanged.emit(null);
                    this.dateInvalid.emit(
                        `${this.padDateComponent(
                            val.day
                        )}/${this.padDateComponent(val.month)}/${val.year}`
                    );
                    this.dateForm.setErrors({ dateInvalid: true });
                }
            });

        if (this.disabled) {
            this.dateForm.disable();
        }
      }

    private padDateComponent(component: number): string {
        if (component === 0) {
            return '';
        }
        if (component >= 10) {
            return component.toString();
        }

        return '0' + component;
    }
}