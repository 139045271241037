import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';

// models
import { RegExRules } from 'app/shared/models';
import { EnumValueTitle } from 'app/shared/models/enum-value-title.model';
import { codes } from 'country-calling-code';

// utils
import { compareStringCaseInsensitive, isNullOrWhiteSpace } from 'app/shared/utilities/string-utilities';

// rxjs
import { distinctUntilChanged, takeUntil } from 'rxjs';

// components
import { BaseComponent } from 'app/shared/base/base-component';

function requiredWhenNumberEntered(): ValidatorFn {
    return (c) => !isNullOrWhiteSpace(c.parent?.get('mobileNumber')?.value) &&
        isNullOrWhiteSpace(c.value) ? { required: true } : null;
}

export function getFormGroup(fb: FormBuilder, required: boolean): FormGroup {
    const validators = [];
    if (required) {
        validators.push(Validators.required);
    }

    return fb.group({
        diallingCode: [null, [...validators, requiredWhenNumberEntered()]],
        mobileNumber: [null, [
            ...validators,
            Validators.pattern(new RegExRules().phone_number_no_code),
            Validators.minLength(3),
            Validators.maxLength(15)
        ]]
    });
}

@Component({
    selector: 'app-mobile-phone-entry',
    templateUrl: './mobile-phone-entry.component.html'
})
export class MobilePhoneEntryComponent extends BaseComponent implements OnInit {
    constructor(private fb: FormBuilder) {
        super();
    }

    @Input()
    formGroup: FormGroup;

    @Input()
    formGroupName: string;

    @Input()
    isRequired = false;

    @Input()
    includeEmpty = false;

    form: FormGroup;
    callingCodes: EnumValueTitle<string>[] = [];

    ngOnInit(): void {
        this.callingCodes = this.getCallingCodes();

        if (!this.formGroup?.contains(this.formGroupName)) {
            this.form = getFormGroup(this.fb, this.isRequired);
            this.formGroup.addControl(this.formGroupName, this.form);
        } else {
            this.form = this.formGroup.get(this.formGroupName) as FormGroup;
        }

        this.form.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe), distinctUntilChanged())
            .subscribe(() => {
                this.form
                    .get('mobileNumber')
                    .updateValueAndValidity({
                        onlySelf: true,
                        emitEvent: false,
                    });
                this.form
                    .get('diallingCode')
                    .updateValueAndValidity({
                        onlySelf: true,
                        emitEvent: false,
                    });
                this.form.updateValueAndValidity({ emitEvent: false });
            });
    }

    private getCallingCodes(): EnumValueTitle<string>[] {
        const uk = codes.find(x => compareStringCaseInsensitive(x.isoCode2, 'GB'));
        const items = [
            uk,
            ...codes.filter(x => x !== uk)
        ];

        const result = items
            .filter(x => !!x && x.countryCodes.length > 0)
            .map(x => {
                const code = '+' + x.countryCodes[0].replace(/([^\d]+)/g, '');
                return new EnumValueTitle(code, `${x.country} (${code})`);
            });

        if (this.includeEmpty) {
            result.unshift(new EnumValueTitle('', ''));
        }

        return result;
    }
}