<div id="user-notification-list">
    <app-notification-details *ngIf="(highlightedNotification$ | async) else list"
                              [notification]="highlightedNotification$ | async">
    </app-notification-details>
    <ng-template #list>
        <div fxLayout="column" fxLayoutGap="16px">
            <div *ngIf="!(notifications$ | async)?.length">
                <h3>You're all caught up!</h3>
                <h4 class="font-weight-light">Great news, you don't have any new notifications.</h4>
            </div>
            <div *ngFor="let notification of ((notifications$ | async) | orderBy: 'date':'desc')">
                <app-notification-card [notification]="notification"
                                       (read)="readNotification(notification)"
                                       (dismiss)="dismissNotification(notification)"></app-notification-card>
            </div>
        </div>
    </ng-template>
</div>