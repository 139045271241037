<div id="entity-contact-card">
    <div class="background-gray-0">
        <div class="content-padding font-weight-bold">
            Contact information
        </div>
    </div>
    <div class="section-wrapper">
        <div class="title">Status</div>
        <app-entity-status [item]="entity" [showIcon]="true" [setTextColor]="false" />
        <app-entity-approval-status [item]="entity" [showIcon]="true" />
    </div>
    <div class="section-wrapper">
        <div class="title">Personal identifiers</div>
        <app-copy-button icon="fa-hashtag-lock" labelWhenNull="N/A" [label]="entity.registrationCode" />
        <app-copy-button icon="fa-user" labelWhenNull="N/A" [label]="entity.reference" />
        <app-copy-button icon="fa-calendar" labelWhenNull="N/A" [label]="entity.dateOfBirth | date:'dd/MM/yyyy'" />
    </div>
    <div class="section-wrapper">
        <div class="title">Contact information</div>
        <app-copy-button icon="fa-paper-plane" labelWhenNull="N/A" [label]="entity.emailAddress" />
        <app-copy-button icon="fa-phone"
                         labelWhenNull="N/A"
                         [label]="entity.phoneNumber ? (entity.phoneNumber | mobileNumber:entity.senderTelephoneNumberDiallingCode) : null" />
        <app-copy-button icon="fa-home" labelWhenNull="N/A" [label]="entity.address | address" />
    </div>
    <div *ngIf="entity.entityTags?.length"
         class="section-wrapper small-gap">
        <div class="title margin-bottom-8">Tags</div>
        <div *ngFor="let tag of entity.entityTags; let last = last;"
             class="btn primary small dark tag"
             [class.margin-bottom-8]="last">
            {{tag.value}}
        </div>
    </div>
    <div class="divider margin-none"></div>
    <a class="btn empty icon-l edit-button"
       [routerLink]="['/portal', 'entities', entity.id, 'user-details']">
        <i class="fa-light fa-eye"></i>
        Expand view
    </a>
</div>