export enum ActiveInactiveOptions {
    Active = 1,
    Inactive = 2,
}

export function getActiveInactiveOptionsLabel(value: ActiveInactiveOptions): string {
    switch (value) {
        case ActiveInactiveOptions.Active:
            return 'Active';
        case ActiveInactiveOptions.Inactive:
            return 'Inactive';
        default:
            return '';
    }
}