// NGRX
import { Action, createReducer, on } from '@ngrx/store';

// Actions
import * as ProcessExportActions from 'app/portal/modules/process-list/store/actions/process-export.actions';

// Models
import { ExportProcessData } from 'app/portal/modules/process-list/models/export-process-data.model';


export interface ProcessExportState {
    creatingExport: boolean;
    loadingData: boolean;
    processExportData: ExportProcessData;
    markingAsDownloaded: boolean;
    exportingProcessPdf: boolean;
}

const initialState: ProcessExportState = {
    creatingExport: false,
    loadingData: false,
    processExportData: undefined,
    markingAsDownloaded: false,
    exportingProcessPdf: false
};

const entityReducer = createReducer(
    initialState,

    on(ProcessExportActions.GetExportProcessData, (state: ProcessExportState) => ({
        ...state,
        loadingData: true
    })),
    on(ProcessExportActions.GetExportProcessDataSuccess, (state: ProcessExportState, action) => ({
        ...state,
        loadingData: false,
        processExportData: action.data
    })),
    on(ProcessExportActions.GetExportProcessDataFail, (state: ProcessExportState) => ({
        ...state,
        loadingData: false
    })),

    on(ProcessExportActions.ExportProcess, (state: ProcessExportState) => ({
        ...state,
        creatingExport: true
    })),
    on(ProcessExportActions.ExportProcessSuccess, (state: ProcessExportState) => ({
        ...state,
        creatingExport: false
    })),
    on(ProcessExportActions.ExportProcessFail, (state: ProcessExportState) => ({
        ...state,
        creatingExport: false
    })),

    on(ProcessExportActions.ProcessExportDownloaded, (state: ProcessExportState) => ({
        ...state,
        markingAsDownloaded: true
    })),
    on(ProcessExportActions.ProcessExportDownloadedSuccess, (state: ProcessExportState) => ({
        ...state,
        markingAsDownloaded: false
    })),
    on(ProcessExportActions.ProcessExportDownloadedFail, (state: ProcessExportState) => ({
        ...state,
        markingAsDownloaded: false
    })),
    on(ProcessExportActions.ExportProcessPdf, (state: ProcessExportState) => ({
        ...state,
        exportingProcessPdf: true
    })),
    on(ProcessExportActions.ExportProcessPdfSuccess, (state: ProcessExportState) => ({
        ...state,
        exportingProcessPdf: false
    })),
    on(ProcessExportActions.ExportProcessPdfFail, (state: ProcessExportState) => ({
        ...state,
        exportingProcessPdf: false
    }))
);

export function reducer(state: ProcessExportState | undefined, action: Action) {
    return entityReducer(state, action);
}