// ngrx
import { createAction, props } from '@ngrx/store';

// models
import { UserProfileUpdate, UserProfileUpdateResult } from 'app/authentication-v2/models';
import { AuthenticatorSetup } from 'app/authentication-v2/models/authenticator-setup.model';
import { RequestPasswordResetResponse } from 'app/authentication-v2/models/request-password-reset-response.model';
import { RequestPinResetResponse } from 'app/authentication-v2/models/request-pin-reset-response.model';
import { LoginResponse } from 'app/models/login-response.model';
import { User } from 'app/models/user.model';

// enums
import { UserAuthenticationMethod } from 'app/shared/enums/user-authentication-method.enum';
import { ClientUserRequiredAction } from 'app/shared/enums/client-user-required-action.enum';

export const UpdateUserProfile = createAction(
    '[Authentication User] update user profile',
    props<{request: UserProfileUpdate}>()
);
export const UpdateUserProfileSuccess = createAction(
    '[Authentication User] update user profile success',
    props<{response: UserProfileUpdateResult}>()
);
export const UpdateUserProfileFail = createAction(
    '[Authentication User] update user profile fail'
);

export const SetPin = createAction(
    '[Auth v2] Set PIN',
    props<{ pin: string; token: string }>()
);
export const SetPinSuccess = createAction(
    '[Auth v2] Set PIN success',
    props<{ user: User }>()
);
export const SetPinFail = createAction(
    '[Auth v2] Set PIN fail'
);

export const SetMobileNumber = createAction(
    '[Auth v2] Set mobile number',
    props<{ mobileNumber: string; diallingCode: string }>()
);
export const SetMobileNumberSuccess = createAction(
    '[Auth v2] Set mobile number success',
    props<{ confirmationId: Guid }>()
);
export const SetMobileNumberFail = createAction(
    '[Auth v2] Set mobile number fail'
);

export const ConfirmOtp = createAction(
    '[Auth v2] Confirm OTP',
    props<{ code: string; confirmationId: Guid }>()
);
export const ConfirmOtpSuccess = createAction(
    '[Auth v2] Confirm OTP success',
    props<{ user: User, passwordResetToken: string }>()
);
export const ConfirmOtpExpired = createAction(
    '[Auth v2] Confirm OTP expired',
);
export const ConfirmOtpFail = createAction(
    '[Auth v2] Confirm OTP fail'
);

export const ActionRequired = createAction(
    '[Auth v2] Action required',
    props<{ requiredAction: ClientUserRequiredAction }>()
);

export const ConfirmPin = createAction(
    '[Auth v2] Confirm PIN',
    props<{ pin: string }>()
);
export const ConfirmPinSuccess = createAction(
    '[Auth v2] Confirm PIN success',
    props<{ user: User }>()
);
export const ConfirmPinFail = createAction(
    '[Auth v2] Confirm PIN fail',
    props<{ message: string }>()
);

export const TriggerSecurityReset = createAction(
    '[Auth v2] Trigger security reset',
    props<{ credential: string }>()
);
export const TriggerSecurityResetSuccess = createAction(
    '[Auth v2] Trigger security reset success',
    props<{ confirmationId: Guid; notFound: boolean }>()
);
export const TriggerSecurityResetFail = createAction(
    '[Auth v2] Trigger security reset fail'
);

export const RequestPinReset = createAction(
    '[Auth v2] Request PIN reset',
    props<{ resetPassword: boolean }>()
);
export const RequestPinResetSuccess = createAction(
    '[Auth v2] Request PIN reset success',
    props<{ request: RequestPinResetResponse}>()
);
export const RequestPinResetFail = createAction(
    '[Auth v2] Request PIN reset fail'
);

export const RequestPasswordReset = createAction(
    '[Auth v2] Request password reset',
    props<{ resetPin: boolean }>()
);
export const RequestPasswordResetSuccess = createAction(
    '[Auth v2] Request password reset success',
    props<{ request: RequestPasswordResetResponse}>()
);
export const RequestPasswordResetFail = createAction(
    '[Auth v2] Request password reset fail'
);

export const GenerateAuthenticatorSetup = createAction(
    '[Auth v2] Generate authenticator setup',
);
export const GenerateAuthenticatorSetupSuccess = createAction(
    '[Auth v2] Generate authenticator setup success',
    props<{ authenticatorSetup: AuthenticatorSetup}>()
);
export const GenerateAuthenticatorSetupFail = createAction(
    '[Auth v2] Generate authenticator setup fail'
);

export const ConfirmAuthenticatorSetup = createAction(
    '[Auth v2] Confirm authenticator setup',
    props<{ code: string }>()
);
export const ConfirmAuthenticatorSetupSuccess = createAction(
    '[Auth v2] Confirm authenticator setup success',
    props<{ user: User }>()
);
export const ConfirmAuthenticatorSetupFail = createAction(
    '[Auth v2] Confirm authenticator setup fail'
);

export const ValidateAuthenticatorCode = createAction(
    '[Auth v2] Validate authenticator code',
    props<{ code: string }>()
);
export const ValidateAuthenticatorCodeSuccess = createAction(
    '[Auth v2] Validate authenticator code success',
    props<{ user: User }>()
);
export const ValidateAuthenticatorCodeFail = createAction(
    '[Auth v2] Validate authenticator code fail',
    props<{ response: LoginResponse<User> }>()
);

export const ConfirmAuthenticationMethod = createAction(
    '[Auth v2] Confirm authentication method',
    props<{ authenticationMethod: UserAuthenticationMethod }>()
);
export const ConfirmAuthenticationMethodSuccess = createAction(
    '[Auth v2] Confirm authentication method success',
    props<{ user: User }>()
);
export const ConfirmAuthenticationMethodFail = createAction(
    '[Auth v2] Confirm authentication method fail'
);