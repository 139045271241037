export const RegexPatterns = Object.freeze({
    Alphanumeric: `^[\\w]+`,
    AlphanumericSpace: `^[\\w\\s]+$`,
    Numeric: `^([0-9])`,
    Decimal: `^(0|[1-9]\\d*)(\\.\\d+)?$`,
    Name: `^([A-Za-z '\`-]+)$`,
    /**
     * Optional '(' or '+' at start, spaces and brackets allowed, min 10 digits
     */
    MobileNumber: `^[0-9]{10,13}$`,
    UkMobileNumber: `^(\\+447|07|0500)[0-9]+$`,
    UkPostcode: `^(([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))\\s?[0-9][A-Za-z]{2}))$`,
    NumberPrefixedWithPlus: '^[+]?[0-9]+$',
    /**
     * Ensure that password is 9 characters long and contains a mix of upper and lower case characters and  at least one special character
     * ^	                The password string will start this way
     * (?=.*[a-z])	        The string must contain at least 1 lowercase alphabetical character
     * (?=.*[A-Z])	        The string must contain at least 1 uppercase alphabetical character
     * (?=.*[^a-zA-Z0-9])   The string must contain at least 1 non-alphanumeric character
     * (?=.{9,})	        The string must be 9 characters or longer
     */
    Password: `^(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{9,}`,
    EmailAddress: '^.+@.+..+$',
    NullOrEmailAddress: '|^.+@.+..+$',
    HttpHeaderName: `^[a-zA-Z][a-zA-Z\\d-]*[a-zA-Z\\d]?(?<!-)$`,
    UkTitleNumber: `^[a-zA-Z]{1,}\\d{1,}$`,
    Pin: `^\\d{4}$`
});
