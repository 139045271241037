import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

// ngrx | rxjs
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

// store
import * as fromConnect from 'app/connect/store';

// services
import { ModuleService } from 'app/shared/services/module.service';

// models
import { Client } from 'app/models/client.model';
import { CloneClientOwnedItemRequest } from 'app/shared/models/clone-client-owned-item-request.model';

// enums
import { getDtoItemTypeDescription } from 'app/shared/enumerations/dto-item-type.enum';

// validators
import { ConnectValidators } from 'app/shared/validators/connect-validators';

@Component({
    templateUrl: './clone-client-owned-item-dialog.component.html',
    styleUrls: ['./clone-client-owned-item-dialog.component.scss']
})
export class CloneClientOwnedItemDialogComponent {
    isSuperUser = ModuleService.isSuperUser;
    title: string;
    itemTypeTitle: string;
    privateAllowed = false;
    publicAllowed = false;
    client$: Observable<Client>;
    message: string;
    form: FormGroup;

    constructor(
        @Inject(MAT_DIALOG_DATA) data,
        connectStore: Store<fromConnect.ConnectStoreState>,
        formBuilder: FormBuilder,
        private dialogRef: MatDialogRef<CloneClientOwnedItemDialogComponent>) {
            this.itemTypeTitle = getDtoItemTypeDescription(data.itemType);
            this.message = `Clone a new ${this.itemTypeTitle}`;
            this.title = data.initialTitle;
            this.publicAllowed = data.publicAllowed === undefined ? true : data.publicAllowed;
            this.privateAllowed = data.privateAllowed === undefined ? true : data.privateAllowed;
            this.client$ = connectStore.select(fromConnect.getClient);

            this.form = formBuilder.group({
                title: [data.initialTitle, [Validators.required, ConnectValidators.notEqualValue([data.initialTitle])]],
                importAsPublic: [!this.privateAllowed && this.publicAllowed]
            });
        }

    onCloneClick(): void {
        const request: CloneClientOwnedItemRequest = this.form.value;
        this.dialogRef.close(request);
    }

    setImportAsPublic(importAsPublic: boolean): void {
        const field = this.form.get('importAsPublic');
        if (importAsPublic && this.publicAllowed) {
            field.setValue(true);
        } else if (!importAsPublic && this.privateAllowed) {
            field.setValue(false);
        }
    }

    get importAsPublic(): boolean {
        return this.form?.get('importAsPublic')?.value ?? false;
    }
}
