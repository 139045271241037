import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// rxjs
import { Observable } from 'rxjs';

// models
import { UserProfileUpdate, UserProfileUpdateResult } from 'app/authentication-v2/models';

@Injectable()
export class UserService {
    constructor(private http: HttpClient) { }

    userProfileUpdate$(request: UserProfileUpdate): Observable<UserProfileUpdateResult> {
        return this.http.put<UserProfileUpdateResult>('api/v2/users', request);
    }
}
