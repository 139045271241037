import { OnInit, OnDestroy, Component, AfterViewInit } from '@angular/core';
import { LocationStrategy } from '@angular/common';
import { Router, NavigationStart, Event as NavigationEvent } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';

// ngrx / rxjs
import { Store } from '@ngrx/store';
import { Observable, Subject, merge, fromEvent } from 'rxjs';
import { takeUntil, filter, distinctUntilChanged, debounceTime } from 'rxjs/operators';

// store
import * as fromConnect from 'app/connect/store';
import * as fromApp from 'app/store';
import * as fromUserInterface from 'app/store/actions/user-interface.actions';

// Services
import { DeviceDetectionService } from './shared/services';
import { AuthenticationTokenService } from './shared/services/authentication-token.service';
import { GoogleTagManagerService } from './shared/services/google-tag-manager.service';
import { SessionStateService } from 'app/shared/services/session-state.service';

// models
import { LogoutOptions } from 'app/models/logout-options.model';
import { pdfDefaultOptions } from 'ngx-extended-pdf-viewer';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
    private ngUnsubscribe: Subject<void> = new Subject();
    showHeader$: Observable<boolean>;

    constructor(
        private deviceDetectionService: DeviceDetectionService,
        private titleService: Title,
        private metaService: Meta,
        private translate: TranslateService,
        private authenticationTokenService: AuthenticationTokenService,
        private connectStore: Store<fromConnect.ConnectStoreState>,
        private router: Router,
        private locationStrategy: LocationStrategy,
        private gtmService: GoogleTagManagerService,
        private appStore: Store<fromApp.State>,
        private sessionStateService: SessionStateService
    ) {
        pdfDefaultOptions.assetsFolder = 'assets/pdf-viewer';
    }

    ngOnInit(): void {
        this.showHeader$ = this.connectStore.select(fromConnect.getShowHeader);

        this.appInit();

        this.router.events
            .pipe(
                filter((event: NavigationEvent) => (event instanceof NavigationStart)),
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe((event: NavigationStart) => {
                if (event.restoredState) {
                    this.appInit();
                }
            });

        // Check if there is a token
        if (this.authenticationTokenService.getToken()) {
            // if the selected path is an auth page, dispatch the logout call
            // else rehydrate the user details from the token.
            if (window.location.pathname.startsWith('/auth')) {
                if (window.location.pathname.indexOf('login') > 0) {
                    this.connectStore.dispatch(fromConnect.LogoutUser({ options: new LogoutOptions(true, false, null) }));
                }
            } else {
                this.connectStore.dispatch(fromConnect.RehydrateUser());
            }
        }

        this.chargeableWarningWhenPageReloadedDuringPortalSelection();

        this.locationStrategy.onPopState(() => {
            if (!window.location.pathname.startsWith('/auth')) {
                this.connectStore.dispatch(fromConnect.SetApplicationFromUrl());
            } else {
                history.go(1);
            }
        });
    }

    appInit(): void {
        this.deviceDetectionService.init();

        this.translate.setDefaultLang(`en-GB_${environment.translationPack}`);
        this.translate.onLangChange.subscribe(() => {
            this.updateTitle();
        });

        this.gtmService.addGoogleTagManager();

    }

    ngAfterViewInit(): void {
        this.restartSessionTimeoutOnUserActivity();
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    private updateTitle(): void {
        this.translate.get('titles.platform').pipe(takeUntil(this.ngUnsubscribe)).subscribe((text: string) => {
            this.titleService.setTitle(text);
        });

        this.translate.get('descriptions.metaDescription').pipe(takeUntil(this.ngUnsubscribe)).subscribe((text: string) => {
            this.metaService.updateTag({ name: 'description', content: text }, `name='description'`);
        });
    }

    private restartSessionTimeoutOnUserActivity() {
        const scrollEvents$ = fromEvent(window, 'scroll');
        const mouseMoveEvents$ = fromEvent(window, 'mousemove');
        const keydownEvents$ = fromEvent(window, 'keydown');
        const clickEvents$ = fromEvent(window, 'click');
        const mouseWheelEvents$ = fromEvent(window, 'mousewheel');
        const touchstartEvents$ = fromEvent(window, 'touchstart');

        const allEvents$ = merge(
            scrollEvents$,
            mouseMoveEvents$,
            keydownEvents$,
            clickEvents$,
            mouseWheelEvents$,
            touchstartEvents$
        );

        allEvents$.pipe(
            takeUntil(this.ngUnsubscribe),
            distinctUntilChanged(),
            debounceTime(1500)
        )
        .subscribe(() => {
            this.appStore.dispatch(new fromUserInterface.ClearSessionTimeout(false));
            this.appStore.dispatch(new fromUserInterface.StartSessionTimeout());
        });
    }

    private chargeableWarningWhenPageReloadedDuringPortalSelection() {
        if (this.sessionStateService.getDisplayChargeableModeWarning()) {
            this.connectStore.dispatch(fromConnect.DisplayChargeableModeWarning());
        }
    }
}
