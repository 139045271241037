import { Injectable } from '@angular/core';

// ngrx || rxjs
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

// store
import * as fromStore from 'app/connect/store';

// services
import { UserAlertService } from 'app/connect/services/user-alert.service';

@Injectable()
export class UserAlertEffects {
    getUserAlerts$ = createEffect(() =>
        this.actions.pipe(
            ofType(fromStore.GetUserAlerts),
            switchMap(() =>
                this.userAlertService.getAlerts$().pipe(
                    map((alerts) => fromStore.GetUserAlertsSuccess({ alerts })),
                    catchError(() => of(fromStore.GetUserAlertsFail()))
                )
            )
        )
    );

    readUserAlerts$ = createEffect(() =>
        this.actions.pipe(
            ofType(fromStore.ReadUserAlert),
            switchMap((action) =>
                this.userAlertService.readAlert$(action.id).pipe(
                    map((alert) =>
                        fromStore.ReadUserAlertSuccess({
                            alert,
                        })
                    ),
                    catchError(() => of(fromStore.ReadUserAlertFail()))
                )
            )
        )
    );

    dismissUserAlerts$ = createEffect(() =>
        this.actions.pipe(
            ofType(fromStore.DismissUserAlert),
            switchMap((action) =>
                this.userAlertService.dismissAlert$(action.id).pipe(
                    map((alert) =>
                        fromStore.DismissUserAlertSuccess({
                            alert,
                        })
                    ),
                    catchError(() => of(fromStore.DismissUserAlertFail()))
                )
            )
        )
    );

    constructor(
        private actions: Actions,
        private userAlertService: UserAlertService
    ) {}
}
