import { Component, EventEmitter, inject, Input, Output } from '@angular/core';

// ngrx | rxjs
import { Store } from '@ngrx/store';
import { filter, map } from 'rxjs';

// store
import * as fromConnect from 'app/connect/store';

// models
import { UserAlertGroup } from 'app/connect/models/user-alert-group.model';
import { UserAlert } from 'app/connect/models/user-alert.model';

// enums
import { UserAlertCategory } from 'app/connect/enums/user-alert-category.enum';
import {
    getUserAlertStatusLozengeText,
    getUserAlertStatusLozengeVariant,
    UserAlertStatus,
} from 'app/connect/enums/user-alert-status.enum';
import { getUserAlertTaskStatusLozengeVariant, getUserAlertTaskStatusTitle } from 'app/connect/enums/user-alert-task-status.enum';
import { getUserAlertImportStatusLozengeVariant, getUserAlertImportStatusTitle } from 'app/portal/modules/entity-list/enumerations/entity-import-status.enum';


@Component({
    selector: 'app-user-alert-group',
    templateUrl: 'user-alert-group.component.html',
    styleUrls: ['user-alert-group.component.scss'],
})
export class UserAlertGroupComponent {
    @Input()
    group: UserAlertGroup;

    @Output()
    read = new EventEmitter<UserAlert>();

    @Output()
    dismiss = new EventEmitter<UserAlert>();

    @Output()
    toggle = new EventEmitter<UserAlertCategory>();

    UserAlertStatus = UserAlertStatus;
    UserAlertCategory = UserAlertCategory;

    getUserAlertStatusLozengeText = getUserAlertStatusLozengeText;
    getUserAlertStatusLozengeVariant = getUserAlertStatusLozengeVariant;
    getUserAlertTaskStatusTitle = getUserAlertTaskStatusTitle;
    getUserAlertTaskStatusLozengeVariant = getUserAlertTaskStatusLozengeVariant;
    getUserAlertImportStatusLozengeVariant =
        getUserAlertImportStatusLozengeVariant;
    getUserAlertImportStatusTitle = getUserAlertImportStatusTitle;

    showUserGroupInBody$ = inject(Store)
        .select(fromConnect.getClient)
        .pipe(
            filter((client) => !!client),
            map((client) => client.userGroups.length > 1)
        );

    get unreadCount() {
        return this.group?.alerts.filter((x) => !x.read).length;
    }

    get dataAuto() {
        switch (this.group?.category) {
            case UserAlertCategory.BankAccountCheck:
                return 'notification-alert-category-bank-account-check';
            case UserAlertCategory.BouncedInvitation:
                return 'notification-alert-category-bounced-invitation';
            case UserAlertCategory.EsignDocument:
                return 'notification-alert-category-esgin-document';
            case UserAlertCategory.ExpiredInvitation:
                return 'notification-alert-category-expired-invitation';
            case UserAlertCategory.IdentityCheck:
                return 'notification-alert-category-identity-check';
            case UserAlertCategory.OpenBankingCheck:
                return 'notification-alert-category-open-banking-check';
            case UserAlertCategory.ProcessComplete:
                return 'notification-alert-category-process-complete';
            case UserAlertCategory.ProofOfOwnershipCheck:
                return 'notification-alert-category-proof-of-ownership-check';
            case UserAlertCategory.SanctionAndPeps:
                return 'notification-alert-category-sanction-and-peps';
            case UserAlertCategory.StandardCheck:
                return 'notification-alert-category-standard-check';
            case UserAlertCategory.LivenessCheck:
                return 'notification-alert-category-liveness-check';
        }
    }

    getButtonText(alert: UserAlert) {
        if (
            this.group?.category === UserAlertCategory.EntityImportComplete ||
            this.group?.category === UserAlertCategory.Task ||
            alert.actionedBy
        ) {
            return 'View';
        }

        return 'Review';
    }
}
