<div id="connect-app" fxFlex="100" fxLayout="column">
    <app-connect-branding></app-connect-branding>
    <app-connect-header></app-connect-header>

    <div id="content-container" fxLayout="column" fxFlex="fill">
        <content fxFlex="100"></content>
        <app-client-selector *ngIf="showHeader$ | async"></app-client-selector>
        <app-user-group-selector *ngIf="showHeader$ | async"></app-user-group-selector>
        <app-help-faq-search *ngIf="showHeader$ | async"></app-help-faq-search>
    </div>

</div>
