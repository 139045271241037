import {
    Component,
    forwardRef,
    Input,
    OnInit,
    QueryList,
    ContentChildren,
    AfterViewInit,
} from '@angular/core';
import {
    ControlValueAccessor,
    FormControl,
    NG_VALUE_ACCESSOR,
} from '@angular/forms';

// rxjs
import { takeUntil } from 'rxjs';

// components
import { BaseComponent } from 'app/shared/base/base-component';
import { TileComponent } from 'app/shared/components/tile/tile.component';

@Component({
    selector: 'app-tile-group',
    templateUrl: 'tile-group.component.html',
    styleUrls: ['tile-group.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TileGroupComponent),
            multi: true,
        },
    ],
})
export class TileGroupComponent
    extends BaseComponent
    implements OnInit, AfterViewInit, ControlValueAccessor {
    @Input()
    formControl: FormControl;

    @Input()
    name: string;

    @ContentChildren(forwardRef(() => TileComponent))
    tiles: QueryList<TileComponent>;

    ngOnInit() {}

    ngAfterViewInit() {
        this.setTilesName(this.tiles.toArray());

        this.tiles.changes
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((tiles: TileComponent[]) => this.setTilesName(tiles));
    }

    setTilesName(tiles: TileComponent[]) {
        tiles.forEach((tile) => tile.setName(this.name));
    }

    syncValue(value: any) {
        this.formControl.setValue(value);
    }

    writeValue(value: any) {
        // Wrapped in a setTimeout to allow Angular to update the view
        setTimeout(() =>
            this.tiles?.forEach((tile) => (tile.checked = tile.value === value))
        );
    }

    registerOnChange() {}

    registerOnTouched() {}
}
