// ngrx
import { createFeatureSelector, createSelector } from '@ngrx/store';

// store
import * as reducers from 'app/connect/store/reducers';

// models
import { StoreNames } from 'app/connect/models/store-names.model';

const storeState = createFeatureSelector<reducers.ConnectStoreState>(
    StoreNames.connectCommon
);

const reducerState = createSelector(
    storeState,
    (state: reducers.ConnectStoreState) => state.signalr
);

export const signalrState = reducerState;
