import { EnumValueTitle } from 'app/shared/models/enum-value-title.model';

export enum ClientType {
    Standard = 1,
    Reseller = 2,
    ResellerChild = 3
}

export const ClientTypes = [
    new EnumValueTitle(ClientType.Standard, 'Standard'),
    new EnumValueTitle(ClientType.Reseller, 'Reseller'),
    new EnumValueTitle(ClientType.ResellerChild, 'Reseller Child')
];

export function getClientTypeLabel(clientType: ClientType): string {
    return ClientTypes.find(t => t.value === clientType).title;
}