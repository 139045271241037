import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

// rxjs
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// models
import { ProcessDetails } from 'app/portal/modules/process-details/models/process-details.model';
import { ProcessSearchQuery } from 'app/portal/modules/process-list/models/process-search-query.model';
import { ProcessSearchResults } from 'app/portal/modules/process-list/models/process-search-results.model';
import { SmsTemplateAvailability } from 'app/portal/modules/process-list/models/sms-template-availability.model';
import { ExportProcessData } from 'app/portal/modules/process-list/models/export-process-data.model';
import { InvitedByUser } from 'app/portal/modules/process-list/models/invited-by-user.model';
import { ProcessPdfExportData } from 'app/portal/modules/process-details/models/process-pdf-export-data.model';


@Injectable()
export class ProcessService {
    private baseEndpoint = 'api/v2/processes';

    constructor(private http: HttpClient) { }

    getProcess$(id: string): Observable<ProcessDetails> {
        return this.http.get<ProcessDetails>(`${this.baseEndpoint}/${id}`);
    }

    getProcessList$(query: ProcessSearchQuery): Observable<ProcessSearchResults> {
        return this.http.post<ProcessSearchResults>(`${this.baseEndpoint}/search`, query);
    }

    deleteProcess$(id: string): Observable<any> {
        return this.http.delete(`${this.baseEndpoint}/${id}`);
    }

    getIdDocumentUrl$(processId: string, idDocumentId: string): Observable<string> {
        const url = `${this.baseEndpoint}/${processId}/actors/${null}/id-documents/${idDocumentId}`;
        return this.http.get<string>(url);
    }

    restartWorkflow$(processId: string, reinviteUser: boolean): Observable<ProcessDetails> {
        const url = `${this.baseEndpoint}/${processId}/restart-workflow?reinviteuser=${reinviteUser}`;
        return this.http.put<ProcessDetails>(url, null);
    }

    closeWorkflow$(processId: string): Observable<ProcessDetails> {
        const url = `${this.baseEndpoint}/${processId}/close-workflow`;
        return this.http.put<ProcessDetails>(url, null);
    }

    getSmsTemplateAvailability$(): Observable<any> {
        return this.http.get<SmsTemplateAvailability>('api/v2/sms-templates/availability');
    }

    lockProcess$(processId: string): Observable<void> {
        return this.http.put<void>(`${this.baseEndpoint}/${processId}/lock`, null);
    }

    unlockProcess$(processId: string): Observable<void> {
        return this.http.put<void>(`${this.baseEndpoint}/${processId}/unlock`, null);
    }

    exportProcess$(processId: string, data: ExportProcessData): Observable<ProcessPdfExportData> {
        const url = `${this.baseEndpoint}/${processId}/pdf-exports`;
        return this.http.post<ProcessPdfExportData>(url, data, { headers: { timeout: '600000' }  });
    }

    processExportDownloaded$(processId: string, pdfId: number): Observable<void> {
        const url = `${this.baseEndpoint}/${processId}/pdf-exports/${pdfId}/downloaded`;
        return this.http.put<void>(url, null);
    }

    getProcessExportData$(processId: string): Observable<ExportProcessData> {
        return this.http.get<ExportProcessData>(`${this.baseEndpoint}/${processId}/pdf-exports/sections`);
    }

    getInvitedByUsersList$(): Observable<InvitedByUser[]> {
        return this.http.get<InvitedByUser[]>(`${this.baseEndpoint}/invited-by-users`);
    }

    exportProcesses$(request: ProcessSearchQuery): Observable<Blob> {
        const url = `${this.baseEndpoint}/export-csv`;
        return this.http.post<Blob>(url, request, { responseType: 'blob' as 'json', observe: 'response', headers: { timeout: '600000' }  })
            .pipe(map((response: HttpResponse<Blob>) => response.body));
    }

    exportProcessPdf$(processId: string): Observable<Blob> {
        const url = `${this.baseEndpoint}/${processId}/export-pdf`;
        return this.http.get<Blob>(url, { responseType: 'blob' as 'json', observe: 'response', headers: { timeout: '600000' }  })
            .pipe(map((response: HttpResponse<Blob>) => response.body));
    }

    getMagicLinkClientUser$(processId: string): Observable<string> {
        const url = `${this.baseEndpoint}/${processId}/magic-link-client-user`;
        return this.http.get<string>(url);
    }

    getProcessPdfPack$(processId: string, processPdfPackId: number): Observable<Blob> {
        const url = `${this.baseEndpoint}/${processId}/pdf-packs/${processPdfPackId}`;
        return this.http.get<Blob>(url, { responseType: 'blob' as 'json', observe: 'response', headers: { timeout: '600000' }  })
            .pipe(map((response: HttpResponse<Blob>) => response.body));
    }
}