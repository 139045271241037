// ngrx
import { createAction, props } from '@ngrx/store';

// models
import { JourneySummary } from 'app/portal/modules/process-list/models/journey-summary.model';
import { EntitySummary } from 'app/portal/modules/process-list/models/entity-summary.model';
import { EntitySearch } from 'app/portal/modules/process-list/models/entity-search.model';
import { ProcessSearchResults } from 'app/portal/modules/process-list/models/process-search-results.model';
import { ProcessSearchQuery } from 'app/portal/modules/process-list/models/process-search-query.model';
import { SmsTemplateAvailability } from 'app/portal/modules/process-list/models/sms-template-availability.model';
import { FolderSummary } from 'app/connect/models/folder-summary.model';
import { InvitedByUser } from 'app/portal/modules/process-list/models/invited-by-user.model';

export const GetProcesses = createAction(
    '[Process List] Get Processes',
    props<{query: ProcessSearchQuery}>()
);
export const GetProcessesSuccess = createAction(
    '[Process List] Get Processes Success',
    props<{processes: ProcessSearchResults}>()
);
export const GetProcessesFail = createAction(
    '[Process List] Get Processes Fail'
);

export const GetJourneys = createAction(
    '[Process List] Get Journeys'
);
export const GetJourneysSuccess = createAction(
    '[Process List] Get Journeys Success',
    props<{journeys: JourneySummary[]}>()
);
export const GetJourneysFail = createAction(
    '[Process List] Get Journeys Fail'
);

export const GetActiveJourneys = createAction(
    '[Process List] Get Active Journeys'
);
export const GetActiveJourneysSuccess = createAction(
    '[Process List] Get Active Journeys Success',
    props<{journeys: JourneySummary[]}>()
);
export const GetActiveJourneysFail = createAction(
    '[Process List] Get Active Journeys Fail'
);

export const GetEntities = createAction(
    '[Process List] Get Entities',
    props<{searchData: EntitySearch}>()
);
export const GetEntitiesSuccess = createAction(
    '[Process List] Get Entities Success',
    props<{entities: EntitySummary[]}>()
);
export const GetEntitiesFail = createAction(
    '[Process List] Get Entities Fail'
);

export const GetFolders = createAction(
    '[Process List] Get Folders'
);
export const GetFoldersSuccess = createAction(
    '[Process List] Get Folders Success',
    props<{folders: FolderSummary[]}>()
);
export const GetFoldersFail = createAction(
    '[Process List] Get Folders Fail'
);

export const CreateFolder = createAction(
    '[Process List] Create Folder',
    props<{title: string}>()
);
export const CreateFolderSuccess = createAction(
    '[Process List] Create Folder Success',
    props<{folder: FolderSummary}>()
);
export const CreateFolderFail = createAction(
    '[Process List] Create Folder Fail'
);

export const EditFolder = createAction(
    '[Process List] Edit Folder',
    props<{id: number; title: string}>()
);
export const EditFolderSuccess = createAction(
    '[Process List] Edit Folder Success'
);
export const EditFolderFail = createAction(
    '[Process List] Edit Folder Fail'
);

export const DeleteFolder = createAction(
    '[Process List] Delete Folder',
    props<{id: number}>()
);
export const DeleteFolderSuccess = createAction(
    '[Process List] Delete Folder Success'
);
export const DeleteFolderFail = createAction(
    '[Process List] Delete Folder Fail'
);

export const AddProcessesToFolders = createAction(
    '[Process List] Add Processes To Folders',
    props<{processIds: string[]; folderIds: number[]}>()
);
export const AddProcessesToFoldersSuccess = createAction(
    '[Process List] Add Processes To Folders Success'
);
export const AddProcessesToFoldersFail = createAction(
    '[Process List] Add Processes To Folders Fail'
);

export const RemoveProcessesFromFolder = createAction(
    '[Process List] Remove Processes From Folder',
    props<{processIds: string[]; folderId: number}>()
);
export const RemoveProcessesFromFolderSuccess = createAction(
    '[Process List] Remove Processes From Folder Success'
);
export const RemoveProcessesFromFolderFail = createAction(
    '[Process List] Remove Processes From Folder Fail'
);

export const DeleteProcess = createAction(
    '[Process List] Delete Process',
    props<{id: string}>()
);
export const DeleteProcessSuccess = createAction(
    '[Process List] Delete Process Success'
);
export const DeleteProcessFail = createAction(
    '[Process List] Delete Process Fail'
);

export const GetSmsTemplatesAvailable = createAction(
    '[Process List] Get Sms Templates Availability'
);
export const GetSmsTemplatesAvailableSuccess = createAction(
    '[Process List] Get Sms Templates Availability success',
    props<{templateAvailability: SmsTemplateAvailability}>()
);
export const GetSmsTemplatesAvailableFail = createAction(
    '[Process List] Get Sms Templates Availability fail',
);

export const LockProcess = createAction(
    '[Process List] Lock a process',
    props<{processId: string}>()
);
export const LockProcessSuccess = createAction(
    '[Process List] Lock a process success',
    props<{processId: string}>()
);
export const LockProcessFail = createAction(
    '[Process List] Lock a process fail',
);

export const UnlockProcess = createAction(
    '[Process List] Unlock a process',
    props<{processId: string}>()
);
export const UnlockProcessSuccess = createAction(
    '[Process List] Unlock a process success',
    props<{processId: string}>()
);
export const UnlockProcessFail = createAction(
    '[Process List] Unlock a process fail',
);

export const GetInvitedByUsers = createAction(
    '[Process List] Get Invited By Users'
);
export const GetInvitedByUsersSuccess = createAction(
    '[Process List] Get Invited By Users Success',
    props<{invitedByUsers: InvitedByUser[]}>()
);
export const GetInvitedByUsersFail = createAction(
    '[Process List] Get Invited By Users Fail'
);

export const ExportProcesses = createAction(
    '[Process List] Export processes',
    props<{request: ProcessSearchQuery}>()
);
export const ExportProcessesSuccess = createAction(
    '[Process List] Export processes success',
    props<{csv: Blob}>()
);
export const ExportProcessesFail = createAction(
    '[Process List] Export processes fail'
);
