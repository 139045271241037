import { Action, createReducer, on } from '@ngrx/store';

import { HubConnection } from '@microsoft/signalr';

// store
import * as actions from 'app/connect/store/actions/signalr.actions';

export interface SignalRState {
    connections: { [name: string]: HubConnection };
}

const initialState: SignalRState = {
    connections: {},
};

const signalrReducer = createReducer(
    initialState,
    on(actions.StartHubConnectionSuccess, (state, action) => {
        state.connections[action.hub] = action.connection;

        return {
            ...state,
        };
    })
);

export function reducer(state: SignalRState, action: Action) {
    return signalrReducer(state, action);
}
