<button #button
        appTrackHover
        id="copy-button"
        (click)="onClick()"
        [class.pointer]="label">
    <span fxLayoutGap="8px" fxLayoutAlign="start center">
        <i *ngIf="icon" class="fa-light {{icon}}"></i>
        <span class="label-wrapper">{{label ? label.trim() : labelWhenNull}}</span>
    </span>
    <i *ngIf="label && !copied"
       class="fa-light fa-copy"
       [class.visibility-hidden]="!button.dataset.hovering"></i>
    <span *ngIf="copied" class="font-size-xs font-weight-bold">Copied!</span>
</button>