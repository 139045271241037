import { HttpClient, HttpResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';

// rxjs
import { catchError, of } from 'rxjs';
import { map } from 'rxjs/operators';

// services
import { ImageUploadService } from 'app/shared/services';

// utilities
import { rotateImage } from 'app/shared/utilities/image-utilities';

@Component({
    selector: 'app-bg-image',
    templateUrl: 'secure-background-image.component.html'
  })
  export class SecureBackgroundImageComponent implements OnChanges  {

    @ViewChild('image')
    image: ElementRef;


    @Input()
    src: string;

    @Input()
    notFoundSrc: string;

    @Input()
    notFoundBackgroundSize = 'cover';

    @Input()
    backgroundSize = 'cover';

    size: string;

    @Output()
    imageClick: EventEmitter<any> = new EventEmitter();

    dataUrl: string;

    constructor(
        private httpClient: HttpClient,
        private imageService: ImageUploadService) {
    }

    ngOnChanges(): void {
      this.loadImage();
    }

    onClicked($event: any): void {
      this.imageClick.emit($event);
    }

    rotate() {
      rotateImage(this.image.nativeElement);
    }

    private loadImage(): void {
        this.httpClient.get<Blob>(
          this.src,
          { responseType: 'blob' as 'json', observe: 'response'}).pipe(
              map((response: HttpResponse<Blob>) => {
                  if (!response?.body?.size) {
                      this.setNotFound();
                  } else {
                      this.imageService.getImageDataUrl(response.body, result => {
                        this.dataUrl = result;
                        this.size = this.backgroundSize;
                      });
                  }
              }),
              catchError(() => of(this.setNotFound()))).subscribe();
    }

    private setNotFound(): void {
        this.dataUrl = this.notFoundSrc;
        this.size = this.notFoundBackgroundSize;
    }
}