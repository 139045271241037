import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromTerms from 'app/authentication-v2/store/reducers/terms.reducers';

import { AuthenticationState } from 'app/authentication-v2/store/reducers';

// models
import { StoreNames } from 'app/connect/models/store-names.model';

const storeState = createFeatureSelector<AuthenticationState>(StoreNames.authenticationV2);
const reducerState = createSelector(storeState, (state: AuthenticationState) => state.termsState);

export const getLoadingTerms = createSelector(reducerState, (state: fromTerms.TermsState) => state.loading);
export const getCurrentTerms = createSelector(reducerState, (state: fromTerms.TermsState) => state.terms);