import { Injectable } from '@angular/core';

// environment
import { environment } from 'environments/environment';

// signalr
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';

// ngrx/rxjs
import { Store } from '@ngrx/store';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { from, of } from 'rxjs';
import {
    switchMap,
    map,
    catchError,
    withLatestFrom,
    filter,
} from 'rxjs/operators';

// store
import * as fromStore from 'app/connect/store';

// services
import { AuthenticationTokenService } from 'app/shared/services/authentication-token.service';

// models
import { SignalREvent } from 'app/connect/models/signalr-event.model';

@Injectable()
export class SignalREffects {
    startHubConnection$ = createEffect(() =>
        this.actions.pipe(
            ofType(fromStore.StartHubConnection),
            withLatestFrom(this.store.select(fromStore.signalrState)),
            filter(([action, state]) => !state.connections[action.hub]),
            switchMap(([action]) => {
                const connection = new HubConnectionBuilder()
                    .withUrl(`${environment.baseSiteUrl}${action.hub}`, {
                        accessTokenFactory: () => this.tokenService.getToken()
                    })
                    .withAutomaticReconnect()
                    .build();

                this.registerEvents(connection, action.events);

                return from(connection.start()).pipe(
                    map(() =>
                        fromStore.StartHubConnectionSuccess({
                            connection,
                            hub: action.hub,
                        })
                    ),
                    catchError((errors) =>
                        of(fromStore.StartHubConnectionFail({ errors }))
                    )
                );
            })
        )
    );

    invokeAction$ = createEffect(() =>
        this.actions.pipe(
            ofType(fromStore.InvokeAction),
            withLatestFrom(this.store.select(fromStore.signalrState)),
            filter(([action, state]) => {
                if (!state.connections[action.hub]) {
                    return false;
                }

                return true;
            }),
            switchMap(([action, state]) =>
                from(
                    state.connections[action.hub].invoke(
                        action.methodName,
                        ...action.args
                    )
                ).pipe(
                    map(() =>
                        action.successAction
                            ? action.successAction
                            : fromStore.InvokeActionSuccess({ ...action })
                    ),
                    catchError((errors) =>
                        of(fromStore.InvokeActionFail({ errors }))
                    )
                )
            )
        )
    );

    private registerEvents(connection: HubConnection, events: SignalREvent[]) {
        events.forEach((event) => {
            connection.on(event.name, (args: any) => {
                this.store.dispatch(event.returnAction(args));
            });
        });
    }

    constructor(
        private actions: Actions,
        private store: Store<fromStore.SignalRState>,
        private tokenService: AuthenticationTokenService
    ) {}
}
