<div fxLayout="row" [formGroup]="form">
    <mat-form-field appearance="outline" class="margin-right-16 mobile-phone-entry-dialling-code">
        <mat-select placeholder="Dialling code" formControlName="diallingCode" data-auto="dialling-code-dropdown">
            <mat-option *ngFor="let item of callingCodes" [value]="item.value">
                {{item.title}}
            </mat-option>
        </mat-select>
        <mat-error *ngIf="form.get('diallingCode').hasError('required')">Select dialling code</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="mobile-phone-entry-mobile-number">
        <input placeholder="Mobile number" matInput [formControl]="form.get('mobileNumber')" autocomplete="off" type="text" data-auto="mobile-number-input">
        <mat-error *ngIf="form.get('mobileNumber').hasError('required')">Mobile number is required</mat-error>
        <mat-error *ngIf="form.get('mobileNumber').hasError('pattern')">Sorry, that is not a valid mobile number</mat-error>
    </mat-form-field>
</div>