import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

// rxjs
import {
    BehaviorSubject,
    combineLatest,
    filter,
    map,
    Observable,
    takeUntil,
} from 'rxjs';

// components
import { BaseComponent } from 'app/shared/base/base-component';

// utilities
import { isLightColor } from 'app/shared/utilities/color.utilities';

@Component({
    selector: 'app-color-input',
    templateUrl: 'color-input.component.html',
    styleUrls: ['./color-input.component.scss'],
})
export class ColorInputComponent extends BaseComponent implements OnInit {
    @Output()
    colorReset = new EventEmitter();

    @Output()
    colorChange = new EventEmitter();

    @Input()
    title: string;

    @Input()
    titleClassList: string | string[];

    @Input()
    subtitle: string;

    @Input()
    description: string;

    @Input()
    disabled: boolean;

    @Input()
    set color(value: string) {
        if (!value) {
            return;
        }

        this.color$.next(value.toUpperCase());
    }

    color$ = new BehaviorSubject(this.color);
    pendingColor$: BehaviorSubject<string> = new BehaviorSubject(null);
    isLightColor$: Observable<boolean>;

    ngOnInit() {
        this.isLightColor$ = combineLatest([
            this.color$,
            this.pendingColor$,
        ]).pipe(
            takeUntil(this.ngUnsubscribe),
            map(([color, pendingColor]) => pendingColor ?? color),
            filter((value) => !!value),
            map((value) => isLightColor(value))
        );
    }

    onColorSelect(color: string) {
        this.pendingColor$.next(null);
        this.colorChange.emit(color);
    }

    onColorChange(color: string) {
        this.pendingColor$.next(color);
    }

    onColorReset() {
        this.colorReset.emit();
    }
}
