<app-authentication-layout id="what-to-reset" fxFlex pageTitle="What do you want to reset?" data-auto="what-to-reset">
    <p class="font-size-s font-weight-bold">
        No need to panic, we can help you reset your login detail in just a few minutes.
    </p>

    <div class="margin-bottom-20">
        <a class="btn large primary dark full-width"
                aria-label="My PIN number"
                data-auto="reset-pin-button"
                (click)="onResetPinClick()">
            My PIN number
        </a>
    </div>

    <div class="margin-bottom-20">
        <a class="btn large primary full-width background-connect-accent white-text"
                aria-label="My password"
                data-auto="reset-password-button"
                (click)="onResetPasswordClick()">
            My password
        </a>
    </div>

    <div>
        <a class="btn large full-width secondary"
                aria-label="My PIN number and my password"
                (click)="onResetPinPasswordClick()"
                data-auto="reset-pin-and-password-button">
            My PIN number and my password
        </a>
    </div>
</app-authentication-layout>