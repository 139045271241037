<label id="tile" [class.checked]="checked" [for]="id" [attr.data-testid]="testid">
    <input type="radio"
           [id]="id"
           [value]="value"
           [name]="name"
           [checked]="checked"
           (change)="onTileChange()">
    <i class="fa-solid fa-check-circle checked-icon"></i>
    <i class="icon-size-xxxl {{icon}}"></i>
    <div class="align-center">
        {{ label }}
    </div>
</label>