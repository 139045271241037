<div *ngIf="!simpleView && (!dragDropEnabled || (dragDropEnabled && dragDropAppearance === 'default'))"
     fxFlex
     fxLayout="row"
     fxLayoutAlign="start center"
     class="file-upload-container"
     [draggable]="draggableValue"
     [class.drop-active]="dropActive"
     (drop)="drop($event)"
     (dragover)="allowDrop($event)"
     (dragleave)="dragLeave($event)">

    <div class="text" fxFlex>
        <div class="label" *ngIf="!fileName && !uploadedFile && !invalidFileExtension && !invalidFileSize">
            {{uploadText}}
        </div>
        <div class="file-info" *ngIf="fileName && !downloadEnabled">{{fileName}}</div>
        <a class="file-info" href="#" (click)="$event.preventDefault(); handleFileDownloadClicked()"
           [download]="fileName" *ngIf="fileName && downloadEnabled">{{fileName}}</a>
        <div *ngIf="invalidFileExtension" class="error">Invalid file type</div>
        <div *ngIf="invalidFileSize && !invalidFileExtension" class="error">Invalid file size, must be smaller than
            {{maximumFileSizeMb}}MB</div>
    </div>

    <button class="btn {{uploadButtonSize}} primary {{uploadButtonTheme}} icon-l" [disabled]="working || disableUpload"
            (click)="addFile()">
        <i class="fa fa-solid fa-file-arrow-up"></i>
        <ng-container *ngIf="!working">{{uploadButtonLabel}}</ng-container>
        <ng-container *ngIf="working">Please wait</ng-container>
    </button>
</div>

<div *ngIf="!simpleView && dragDropEnabled && dragDropAppearance === 'outline'"
     class="drag-drop-outline"
     [draggable]="draggableValue"
     [class.drop-active]="dropActive"
     (drop)="drop($event)"
     (dragover)="allowDrop($event)"
     (dragleave)="dragLeave($event)">
    <ng-container *ngIf="!dropActive">
        <div class="padding-32 pointer" fxLayout="column" fxLayoutGap="8px" fxLayoutAlign="center center"
             (click)="addFile()">
            <i class="fa fa-light fa-file-arrow-up icon-size-m"></i>
            <div class="font-size-xs align-center">Drop a new document in here</div>
            <div class="font-size-xs align-center">or choose a file by <span
                      class="rag-blue-text font-size-regular">clicking here</span>.</div>
        </div>
        <div *ngIf="fileName" class="footer-box" fxLayout="row" fxLayoutGap="8px">
            <i class="fa fa-solid fa-check-circle rag-green-text icon-size-s"></i>
            <div>{{fileName}}</div>
        </div>
    </ng-container>
    <div *ngIf="dropActive" class="drop-target-wrapper">
        Drop here
    </div>
</div>

<button *ngIf="simpleView" class="btn {{uploadButtonSize}} primary {{uploadButtonTheme}} icon-l full-width"
        [disabled]="working || disableUpload" (click)="addFile()">
    <i class="fa fa-solid fa-file-arrow-up"></i>
    <ng-container *ngIf="!working">{{uploadButtonLabel}}</ng-container>
    <ng-container *ngIf="working">Please wait</ng-container>
</button>

<input *ngIf="!useCamera" type="file" #upload [hidden]="true" (change)="onFileAdded()" [accept]="fileTypes">
<input *ngIf="useCamera" type="file" #upload [hidden]="true" (change)="onFileAdded()" [accept]="imageFileTypes" capture>