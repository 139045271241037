import { Pipe, PipeTransform } from '@angular/core';

// models
import { UserSummary } from 'app/shared/modules/users/models/user-summary.model';
import { EntityDetails } from 'app/portal/modules/entity-list/models/entity-details.model';
import { EntitySummary } from 'app/portal/modules/entity-list/models/entity-summary.model';
import { LivenessDetails } from 'app/back-office/modules/verifier/models/tasks/liveness/liveness-details.model';
import { PersonWithSignificantControl } from 'app/portal/modules/company-list/models/person-with-significant-control.model';

@Pipe({
    name: 'name',
})
export class NamePipe implements PipeTransform {
    transform(
        model:
            | UserSummary
            | EntityDetails
            | EntitySummary
            | LivenessDetails
            | PersonWithSignificantControl,
        includeMiddleName = false
    ): string {
        if (!model) {
            return '';
        }

        if (!includeMiddleName || !('middleName' in model)) {
            return `${model.firstName} ${model.surname}`;
        }

        return model.middleName
            ? `${model.firstName} ${model.middleName} ${model.surname}`
            : `${model.firstName} ${model.surname}`;
    }
}
