<app-authentication-layout id="reset-password-container" data-auto="reset-password-container" [pageTitle]="title" fxFlex>
    <form name="form" [formGroup]="form" novalidate *ngIf="form && (!loggedIn || showForm)" fxLayout="column">
        <div class="font-size-s margin-bottom-20" *ngIf="invitation || isReset">
            To continue, please set a password.<br/>
            <br />
            Make sure you include a mix of upper and lower case characters, numbers and special case characters to keep your account secure.
        </div>
        <div class="advice-text" *ngIf="tempPasswordReset">
            You have been assigned a temporary password. Please now choose a new password for your account.
        </div>

        <div *ngIf="!tempPasswordReset">
            <mat-form-field appearance="outline">
                <input placeholder="Email address" matInput formControlName="email" type="email">
                <mat-error *ngIf="form.get('email').hasError('required')">
                    Email is required
                </mat-error>
                <mat-error *ngIf="!form.get('email').hasError('required') &&
                                    form.get('email').hasError('email')">
                    Please enter a valid email address
                </mat-error>
            </mat-form-field>
        </div>

        <div>
            <mat-form-field appearance="outline">
                <input matInput placeholder="Password" formControlName="password" [type]="hidePassword ? 'password' : 'text'">
                <mat-icon matSuffix class="pointer" (click)="hidePassword = !hidePassword">
                    {{ hidePassword ? 'visibility_off' : 'visibility' }}
                </mat-icon>
                <mat-error *ngIf="validationService.hasError(form.controls['password'])">
                    {{ validationService.getError(form.controls['password']) }}
                </mat-error>
            </mat-form-field>
        </div>

        <div>
            <mat-form-field appearance="outline">
                <input matInput placeholder="Confirm Password" formControlName="confirmPassword" [type]="hideConfirmPassword ? 'password' : 'text'">
                <mat-icon matSuffix class="pointer" (click)="hideConfirmPassword = !hideConfirmPassword">
                    {{ hideConfirmPassword ? 'visibility_off' : 'visibility' }}
                </mat-icon>
                <mat-error *ngIf="validationService.hasError(form.controls['confirmPassword'])">
                    {{ validationService.getError(form.controls['confirmPassword']) }}
                </mat-error>
            </mat-form-field>
        </div>

        <button class="btn large primary dark" (click)="resetPassword()"
                aria-label="Reset Password" [disabled]="form.invalid || (working$|async)">
            <span *ngIf="!(working$|async)">Get me in</span>
            <span *ngIf="working$|async">Please wait</span>
        </button>

        <div *ngIf="invitation" class="margin-top-24 font-size-xs">
            Already have an account?
            <span (click)="navigation.gotoLogin()" class="text-link font-size-inherit">
                Click here
            </span>
        </div>
    </form>
    <div *ngIf="loggedIn" class="margin-top-20">
        <button class="btn large primary dark" [routerLink]="['/auth-v2', 'login']">Back to login</button>
    </div>
</app-authentication-layout>