<app-authentication-layout id="otp-check" fxFlex [pageTitle]="pageTitle" data-auto="otp-check">
    <app-loading-card *ngIf="working"></app-loading-card>
    <div [formGroup]="form" fxLayout="column" (keydown.enter)="onEnterPressed()">
        <p class="font-size-s">
            We've just sent {{ source }} with an access code to {{ device }}.<br />
            <br />
            Please enter it below.
        </p>
        <div fxLayout="column">
            <div class="margin-bottom-10">
                <app-authentication-code-entry #codeEntry length="6"
                                                spacerInterval="3"
                                                (valueChanged)="onValueChanged($event)"
                                                [numericOnly]="true"></app-authentication-code-entry>
            </div>

            <button class="btn large primary dark"
                    [disabled]="working || form.invalid"
                    (click)="onEnterPressed()" data-auto="confirm-code-button">
                Confirm code
            </button>
            <div class="margin-top-24 font-size-xs">
                Don't have access to your {{ type === ContactType.Phone ? 'mobile device' : 'email account' }}?
                <span (click)="navigation.gotoContactAdmin()" class="text-link font-size-inherit">
                    Click here
                </span>
            </div>
        </div>
    </div>
</app-authentication-layout> 