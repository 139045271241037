import { Component } from '@angular/core';

@Component({
    templateUrl: 'route-disabled-dialog.component.html',
})
export class RouteDisabledDialogComponent {
    onBack() {
        location.reload();
    }
}
