import { TermsEffects } from './terms.effects';
import { AuthEffects } from './auth.effects';
import { UserEffects } from 'app/authentication-v2/store/effects/user.effects';

export const effects: any[] = [
    AuthEffects,
    TermsEffects,
    UserEffects
];

export * from './auth.effects';
export * from './terms.effects';
export * from './user.effects';