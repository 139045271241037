import { ApplicationEffects } from './application.effects';
import { BreadcrumbEffects } from './breadcrumb.effects';
import { UserInterfaceEffects } from './user-interface.effects';
import { HelpFaqEffects } from './help-faq.effects';
import { TooltipsEffects } from './tooltip.effects';
import { SignalREffects } from './signalr.effects';
import { MessagingHubEffects } from './messaging-hub.effects';
import { NotificationEffects } from './notification.effects';
import { UserAlertEffects } from './user-alert.effects';
import { PostcodeLookupEffects } from './postcode-lookup.effects';

export const effects: any[] = [
    ApplicationEffects,
    BreadcrumbEffects,
    UserInterfaceEffects,
    HelpFaqEffects,
    TooltipsEffects,
    SignalREffects,
    MessagingHubEffects,
    NotificationEffects,
    UserAlertEffects,
    PostcodeLookupEffects,
];

export * from './application.effects';
export * from './breadcrumb.effects';
export * from './user-interface.effects';
export * from './help-faq.effects';
export * from './tooltip.effects';
export * from './signalr.effects';
export * from './messaging-hub.effects';
export * from './notification.effects';
export * from './user-alert.effects';
export * from './postcode-lookup.effects';
