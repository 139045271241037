import { createAction, props } from '@ngrx/store';

// models
import { UserNotification } from 'app/connect/models/user-notification.model';

export const GetNotifications = createAction(
    '[Notifications] Get notifications'
);

export const GetNotificationsSuccess = createAction(
    '[Notifications] Get notifications success',
    props<{ notifications: UserNotification[] }>()
);

export const GetNotificationsFail = createAction(
    '[Notifications] Get notifications fail'
);

export const ReadNotification = createAction(
    '[Notifications] Read notification',
    props<{ id: number }>()
);

export const ReadNotificationSuccess = createAction(
    '[Notifications] Read notification success',
    props<{ notification: UserNotification }>()
);

export const ReadNotificationFail = createAction(
    '[Notifications] Read notification fail'
);

export const DismissNotification = createAction(
    '[Notifications] Dismiss notification',
    props<{ id: number }>()
);

export const DismissNotificationSuccess = createAction(
    '[Notifications] Dismiss notification success',
    props<{ notification: UserNotification }>()
);

export const DismissNotificationFail = createAction(
    '[Notifications] Dismiss notification fail'
);

export const SetHighlightedNotification = createAction(
    '[Notifications] Set highlighted notification',
    props<{ notification: UserNotification }>()
);

export const ClearHighlightedNotification = createAction(
    '[Notifications] Clear highlighted notification'
);
