import { Component, OnInit } from "@angular/core";

// ngrx | rxjs
import { Store, select } from "@ngrx/store";
import { Observable } from "rxjs";

// store
import * as fromStore from "app/authentication-v2/store";

// enums
import { UserAuthenticationMethod } from "app/shared/enums/user-authentication-method.enum";

@Component({
    templateUrl: "set-authentication-method.component.html",
})
export class SetAuthenticationMethodComponent implements OnInit {
    working$: Observable<boolean>;

    constructor(private store: Store<fromStore.AuthenticationState>) {}

    ngOnInit() {
        this.working$ = this.store.pipe(
            select(fromStore.getAuthenticationWorking)
        );
    }

    onPin() {
        this.store.dispatch(
            fromStore.ConfirmAuthenticationMethod({
                authenticationMethod: UserAuthenticationMethod.Pin,
            })
        );
    }

    onAuthenticatorApp() {
        this.store.dispatch(
            fromStore.ConfirmAuthenticationMethod({
                authenticationMethod: UserAuthenticationMethod.AuthenticatorApp,
            })
        );
    }
}
