import { Component, Input, OnChanges } from '@angular/core';

// models
import { EntitySummary } from 'app/portal/modules/entity-list/models/entity-summary.model';

// enums
import { getEntityApprovalStatusIcon, getEntityApprovalStatusTitle } from 'app/portal/modules/entity-list/enumerations/entity-approval-status.enum';

@Component({
    selector: 'app-entity-approval-status',
    templateUrl: './entity-approval-status.component.html',
    styleUrls: ['./entity-approval-status.component.scss']
})
export class EntityApprovalStatusComponent implements OnChanges {
    @Input()
    item: EntitySummary;

    @Input()
    showIcon = false;

    statusLabel: string;
    statusClass: string;

    ngOnChanges(): void {
        this.statusClass = getEntityApprovalStatusIcon(this.item.approvalStatus);
        this.statusLabel = getEntityApprovalStatusTitle(this.item.approvalStatus);
    }
}