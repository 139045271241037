import { Component } from '@angular/core';

// services
import { NavigationService } from 'app/shared/services/navigation.service';

@Component({
    templateUrl: 'contact-administrator.component.html'
})
export class ContactAdministratorComponent {
    constructor(public navigation: NavigationService) { }

    onCloseWindowClick(): void {
        window.close();
    }
}