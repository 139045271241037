import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
    ControlValueAccessor,
    FormControl,
    NG_VALUE_ACCESSOR,
} from '@angular/forms';

@Component({
    selector: 'app-negative-positive-input',
    templateUrl: 'negative-positive-input.component.html',
    styleUrls: ['negative-positive-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => NegativePositiveInputComponent),
            multi: true,
        },
    ],
})
export class NegativePositiveInputComponent
    implements OnInit, ControlValueAccessor {
    @Input()
    negativeLabel: string;

    @Input()
    positiveLabel: string;

    @Input()
    formControl: FormControl;

    id: string;

    ngOnInit() {
        this.id = crypto.randomUUID();
    }

    writeValue() {}

    registerOnChange() {}

    registerOnTouched() {}
}
