import { Component, OnInit } from '@angular/core';

// ngrx
import { Store } from '@ngrx/store';
import { Observable, takeUntil } from 'rxjs';

// store
import * as fromStore from 'app/connect/store';

// components
import { BaseComponent } from 'app/shared/base/base-component';

// models
import { UserNotification } from 'app/connect/models/user-notification.model';
import { UserAlert } from 'app/connect/models/user-alert.model';

const Tab = Object.freeze({
    Notifications: 'Notifications',
    Alerts: 'Alerts',
});

const ViewState = Object.freeze({
    List: 'List',
    Details: 'Details',
});

@Component({
    selector: 'app-user-notifications-dialog',
    templateUrl: 'user-notifications-dialog.component.html',
    styleUrls: ['user-notifications-dialog.component.scss'],
})
export class UserNotificationsDialogComponent
    extends BaseComponent
    implements OnInit {
    Tab = Tab;
    tab: string = Tab.Notifications;
    state: string = ViewState.List;

    unreadNotificationsCount$: Observable<number>;
    unreadAlertsCount$: Observable<number>;

    highlightedNotification: UserNotification;
    highlightedAlert: UserAlert;

    get dialogIcon() {
        return this.state === ViewState.List ? 'fa fa-thin fa-bell' : null;
    }

    get dialogWidth() {
        return this.state === ViewState.Details ? '750px' : '455px';
    }

    get dialogContentPadding() {
        return this.tab === Tab.Alerts && this.state === ViewState.List
            ? '0'
            : '16px 32px';
    }

    get showDialogClose() {
        return this.state === ViewState.List;
    }

    get showDialogBack() {
        return this.state === ViewState.Details;
    }

    constructor(private store: Store<fromStore.NotificationState>) {
        super();
    }

    ngOnInit() {
        this.unreadNotificationsCount$ = this.store.select(
            fromStore.getUnreadNotificationsCount
        );

        this.unreadAlertsCount$ = this.store.select(
            fromStore.getUnreadAlertsCount
        );

        this.store
            .select(fromStore.getHighlightedAlert)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((highlightedAlert) => {
                this.highlightedAlert = highlightedAlert;

                if (this.highlightedAlert) {
                    this.setDetailsView();
                }
            });

        this.store
            .select(fromStore.getHighlightedNotification)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((notification) => {
                this.highlightedNotification = notification;

                if (this.highlightedNotification) {
                    this.setDetailsView();
                }
            });
    }

    handleDialogBack() {
        this.clearFocusedItems();
        this.setListView();
    }

    clearFocusedItems() {
        this.store.dispatch(fromStore.ClearHighlightedNotification());
        this.store.dispatch(fromStore.ClearHighlightedAlert());
    }

    setDetailsView() {
        this.state = ViewState.Details;
    }

    setListView() {
        this.state = ViewState.List;
    }
}
