<app-dialog id="user-notifications-dialog"
            data-auto="notification-list-dialog"
            position="right"
            dialogTitle="Notifications"
            [dialogTitleIcon]="dialogIcon"
            [defaultWidth]="dialogWidth"
            [noSubHeaderPadding]="true"
            [contentPadding]="dialogContentPadding"
            [showClose]="showDialogClose"
            [showBack]="showDialogBack"
            [showFooterActions]="false"
            (Back)="handleDialogBack()">
    <div sub-header class="tabs-wrapper">
        <nav id="nav" class="dialog-navigation-wrapper overflow-x-auto" ngClass.xs="invisible-scroll">
            <ul fxLayout="row" class="overflow-x-auto" ngClass.xs="invisible-scroll" fxLayout="row">
                <li fxFlex>
                    <button data-auto="notification-tab-notifications"
                            [class.active]="tab === Tab.Notifications"
                            [class.font-weight-bold]="tab === Tab.Notifications"
                            (click)="tab = Tab.Notifications; clearFocusedItems(); setListView();">
                        System
                        <span *ngIf="unreadNotificationsCount$ | async" class="counter">
                            {{unreadNotificationsCount$ | async}}
                        </span>
                    </button>
                </li>
                <li fxFlex>
                    <button data-auto="notification-tab-alerts"
                            [class.active]="tab === Tab.Alerts"
                            [class.font-weight-bold]="tab === Tab.Alerts"
                            (click)="tab = Tab.Alerts; clearFocusedItems(); setListView();">
                        Actions
                        <span *ngIf="unreadAlertsCount$ | async" class="counter">
                            {{unreadAlertsCount$ | async}}
                        </span>
                    </button>
                </li>
            </ul>
        </nav>
    </div>
    <div header-content [ngSwitch]="tab">
        <ng-container *ngSwitchCase="Tab.Alerts">
            <app-user-alert-task-status *ngIf="highlightedAlert"
                                        [status]="highlightedAlert.taskStatus">
            </app-user-alert-task-status>
        </ng-container>
        <ng-container *ngSwitchCase="Tab.Notifications">
            <app-notification-priority *ngIf="highlightedNotification"
                                       [priority]="highlightedNotification.priority">
            </app-notification-priority>
        </ng-container>
    </div>
    <div body [ngSwitch]="tab">
        <ng-container *ngSwitchCase="Tab.Alerts">
            <app-user-alert-list></app-user-alert-list>
        </ng-container>
        <ng-container *ngSwitchCase="Tab.Notifications">
            <app-user-notification-list></app-user-notification-list>
        </ng-container>
    </div>
</app-dialog>