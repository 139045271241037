<app-dialog id="user-profile-dialog"
            position="right"
            footerPadding="0"
            defaultWidth="455px"
            [dialogTitle]="dialogTitle$ | async"
            [dialogSubTitle]="dialogSubtitle$ | async"
            [showClose]="true">
    <div body [ngSwitch]="dialogView$ | async">
        <div *ngSwitchCase="DialogView.Profile" [formGroup]="profileForm">
            <div>
                <h4>Personal Details</h4>
                <mat-form-field appearance="outline">
                    <input matInput
                           maxlength="60"
                           [formControl]="profileForm.controls.firstName" />
                    <mat-error *ngIf="profileForm.controls.firstName.errors?.required">
                        First name is required
                    </mat-error>
                    <mat-error *ngIf="profileForm.controls.firstName.errors?.pattern">
                        First name cannot include any numbers or special characters.
                    </mat-error>
                    <mat-error *ngIf="profileForm.controls.firstName.errors?.minlength">
                        First name must be at least 2 characters long.
                    </mat-error>
                    <mat-error *ngIf="profileForm.controls.firstName.errors?.maxlength">
                        First name cannot be more than 60 characters long.
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <input matInput
                           [formControl]="profileForm.controls.surname" />
                    <mat-error *ngIf="profileForm.controls.surname.errors?.required">
                        Surname is required
                    </mat-error>
                    <mat-error *ngIf="profileForm.controls.surname.errors?.pattern">
                        Surname cannot include any numbers or special characters.
                    </mat-error>
                    <mat-error *ngIf="profileForm.controls.surname.errors?.minlength">
                        Surname must be at least 2 characters long.
                    </mat-error>
                    <mat-error *ngIf="profileForm.controls.surname.errors?.maxlength">
                        Surname cannot be more than 60 characters long.
                    </mat-error>
                </mat-form-field>
            </div>
            <hr class="divider gray-4" />
            <div>
                <h4>Contact Details</h4>
                <mat-form-field appearance="outline">
                    <input matInput
                           [formControl]="profileForm.controls.email" readonly />
                </mat-form-field>
                <app-mobile-phone-entry formGroupName="mobile"
                                        [formGroup]="profileForm" />
            </div>
            <hr class="divider gray-4" />
            <div>
                <h4>Account Security</h4>
                <div *ngIf="profileForm.controls.isAuthenticatorEnabled.value"
                     class="background-gray-1 border-radius-4 margin-top-4 margin-bottom-24 padding-24">
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
                        <mat-slide-toggle formControlName="isUsingAuthenticator"
                                          [title]="profileForm.controls.isUsingAuthenticator.disabled ? 'Super user policy enforces the use of a authenticator app' : ''"
                                          (change)="onToggleAuthenticatorApp($event.checked)">
                            Two-Factor Authentication (2FA)
                        </mat-slide-toggle>
                    </div>
                    <p class="font-size-xs margin-bottom-none margin-top-16">
                        Two-Factor Authentication is an increased security feature which utilises a
                        personal Authenticator app on your phone to more securely lock your account.
                    </p>
                </div>
                <button class="btn large primary light full-width" (click)="onChangePassword()">
                    Change password
                </button>
                <button class="btn large primary light full-width margin-top-16" (click)="onChangePin()">
                    Change pin
                </button>
            </div>
        </div>
        <div *ngSwitchCase="DialogView.Password" [formGroup]="passwordForm">
            <mat-form-field appearance="outline">
                <mat-label>Current Password</mat-label>
                <input #currentPassword
                       matInput
                       placeholder="Current Password (required for password change)"
                       formControlName="currentPassword"
                       autocomplete="off"
                       [type]="currentPassword.dataset.showPassword ? 'text' : 'password'">
                <mat-icon matSuffix (click)="togglePasswordVisibility(currentPassword)">
                    {{currentPassword.dataset.showPassword ? 'visibility' : 'visibility_off'}}
                </mat-icon>
                <mat-error *ngIf="passwordForm.controls.currentPassword.errors?.required">
                    Current password is required
                </mat-error>
            </mat-form-field>

            <mat-form-field class="margin-top-4" appearance="outline">
                <mat-label>New Password</mat-label>
                <input #newPassword
                       matInput
                       formControlName="password"
                       autocomplete="off"
                       [type]="newPassword.dataset.showPassword ? 'text' : 'password'">
                <mat-icon matSuffix (click)="togglePasswordVisibility(newPassword)">
                    {{newPassword.dataset.showPassword ? 'visibility' : 'visibility_off'}}
                </mat-icon>
                <mat-error *ngIf="passwordForm.controls.password.errors?.required">
                    Password is required
                </mat-error>
                <mat-error *ngIf="passwordForm.controls.password.errors?.pattern">
                    Password must be at least 9 characters long, contain uppercase and lowercase letters and at
                    least one special character.
                </mat-error>
            </mat-form-field>

            <mat-form-field class="margin-top-4" appearance="outline">
                <mat-label>Confirm New Password</mat-label>
                <input #confirmPassword
                       matInput
                       formControlName="confirmPassword"
                       autocomplete="off"
                       [type]="confirmPassword.dataset.showPassword ? 'text' : 'password'">
                <mat-icon matSuffix (click)="togglePasswordVisibility(confirmPassword)">
                    {{confirmPassword.dataset.showPassword ? 'visibility' : 'visibility_off'}}</mat-icon>
                <mat-error *ngIf="passwordForm.controls.confirmPassword.errors?.required">
                    Password is required
                </mat-error>
                <mat-error *ngIf="passwordForm.controls.confirmPassword.errors?.pattern">
                    Password must be at least 9 characters long, contain uppercase and lowercase letters and at
                    least one special character.
                </mat-error>
                <mat-error *ngIf="passwordForm.controls.confirmPassword.errors?.matchpassword">
                    Passwords do not match
                </mat-error>
            </mat-form-field>
        </div>
        <div *ngSwitchCase="DialogView.Pin" [formGroup]="pinForm">
            <div>
                <div class="font-question-label">Current PIN (required for PIN change)</div>
                <app-authentication-code-entry #codeEntry
                                               length="4"
                                               spacerInterval="2"
                                               (valueChanged)="onCurrentPinValueChanged($event)"
                                               [numericOnly]="true"
                                               [asPassword]="false"
                                               style="max-width: 250px; display: block;" />
            </div>
            <div>
                <div class="font-question-label">New PIN</div>
                <app-authentication-code-entry #codeEntry
                                               length="4"
                                               spacerInterval="2"
                                               (valueChanged)="onNewPinValueChanged($event)"
                                               [numericOnly]="true"
                                               [asPassword]="false"
                                               style="max-width: 250px; display: block;" />
            </div>
        </div>
        <div *ngSwitchCase="DialogView.AuthenticatorApp">
            <app-authenticator-app-setup [showConfirmButton]="false" />
        </div>
    </div>
    <div footer-actions>
        <div>
            <button #confirmButton
                    class="btn large primary dark full-width"
                    [disabled]="!canSave"
                    (click)="onSave()">Save changes</button>
        </div>
        <div class="margin-top-16">
            <button *ngIf="(dialogView$ | async) === DialogView.Profile"
                    mat-dialog-close
                    class="btn large transparent full-width">
                Close
            </button>
            <button *ngIf="(dialogView$ | async) !== DialogView.Profile"
                    class="btn large transparent full-width"
                    (click)="onBack()">
                Back
            </button>
        </div>
    </div>
</app-dialog>