import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Guards
import { AuthGuard, ModuleGuard, ShortUrlGuard } from './shared/guards';

// Enums
import { Modules } from './shared/enums';

// Components
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';

export const routes: Routes = [
    // CONNECT
    { path: 'portal', loadChildren: () => import('app/portal/portal.module').then(m => m.PortalModule), canActivate: [AuthGuard, ModuleGuard], data: { module: Modules.ConnectPortal } },
    { path: 'customisation-centre', loadChildren: () => import('app/customisation-centre/customisation-centre.module').then(m => m.CustomisationCentreModule), canActivate: [AuthGuard, ModuleGuard], data: { module: Modules.CustomisationCentre } },
    { path: 'client-management', loadChildren: () => import('app/client-management/client-management.module').then(m => m.ClientManagementModule), canActivate: [AuthGuard, ModuleGuard], data: { module: Modules.ClientManagementClients } },
    { path: 'connect-management', loadChildren: () => import('app/connect-management/connect-management.module').then(m => m.ConnectManagementModule), canActivate: [AuthGuard, ModuleGuard], data: { module: [Modules.ConnectManagement, Modules.BackOffice] } },
    { path: 'branding', loadChildren: () => import('app/branding/branding.module').then(m => m.BrandingModule), canActivate: [AuthGuard, ModuleGuard], data: { module: Modules.Branding } },
    { path: 'info', loadChildren: () => import('app/info/info.module').then(m => m.InfoModule), canActivate: [AuthGuard, ModuleGuard], data: { module: Modules.ConnectInfo } },
    { path: 'back-office', loadChildren: () => import('app/back-office/back-office.module').then(m => m.BackOfficeModule), canActivate: [AuthGuard, ModuleGuard], data: { module: Modules.BackOffice } },
    { path: 'product-configuration', loadChildren: () => import('app/product-configuration/product-configuration.module').then(m => m.ProductConfigurationModule), canActivate: [AuthGuard, ModuleGuard], data: { module: Modules.ProductConfiguration } },

    // protected
    { path: '', loadChildren: () => import('app/authentication-v2/authentication-v2.module').then(m => m.AuthenticationV2Module), pathMatch: 'full' },
    { path: 'auth-v2', loadChildren: () => import('app/authentication-v2/authentication-v2.module').then(m => m.AuthenticationV2Module) },

    // Short Link Redirect
    {path: 'u/:link', pathMatch: 'full', canActivate: [ShortUrlGuard]},

    { path: '**', component: PageNotFoundComponent }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {})
    ],
    exports: [
        RouterModule
    ],
    providers: []
})
export class AppRoutingModule { }
