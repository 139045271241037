import {
    Directive,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    Renderer2,
} from '@angular/core';

@Directive({
    selector: '[appReplaceWithTextIfEmpty]',
})
export class ReplaceWithTextIfEmptyDirective implements OnInit, OnDestroy {
    @Input() replaceWithTextIfEmpty: string = '-';

    private observer: MutationObserver;

    constructor(private el: ElementRef, private renderer: Renderer2) {}

    ngOnInit() {
        this.observer = new MutationObserver((mutationsList) => {
            for (const mutation of mutationsList) {
                if (
                    mutation.type === 'childList' ||
                    mutation.type === 'characterData'
                ) {
                    this.onContentChange();
                }
            }
        });

        this.observer.observe(this.el.nativeElement, {
            characterData: true,
            childList: true,
            subtree: true,
        });
    }

    ngOnDestroy() {
        this.observer?.disconnect();
    }

    onContentChange() {
        const element = this.el.nativeElement;
        const content = element.innerHTML;

        if (!content || content.trim() === '') {
            this.renderer.setProperty(
                element,
                'textContent',
                this.replaceWithTextIfEmpty
            );
        }
    }
}
