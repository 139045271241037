import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

// ngrx | rxjs
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap, catchError, tap, filter } from 'rxjs/operators';

// Actions
import * as ProcessExportActions from 'app/portal/modules/process-list/store/actions/process-export.actions';

// Services
import { ProcessService } from 'app/portal/modules/process-list/services/process.service';
import { AlertService } from 'app/shared/components/alert/services/alert.service';
import { FileSaverService } from 'ngx-filesaver';

@Injectable()
export class ProcessExportEffects {

    exportProcess$ = createEffect(() => this.actions$.pipe(
        ofType(ProcessExportActions.ExportProcess),
        switchMap(action => this.processService.exportProcess$(action.processId, action.data).pipe(
            map((data) => ProcessExportActions.ExportProcessSuccess({ data })),
            catchError(() => of(ProcessExportActions.ExportProcessFail()))
        ))
    ));

    processExportDownloaded$ = createEffect(() => this.actions$.pipe(
        ofType(ProcessExportActions.ProcessExportDownloaded),
        switchMap(action => this.processService.processExportDownloaded$(action.processId, action.pdfId).pipe(
            map(() => ProcessExportActions.ProcessExportDownloadedSuccess()),
            catchError(() => of(ProcessExportActions.ProcessExportDownloadedFail()))
        ))
    ));

    getProcessList$ = createEffect(() => this.actions$.pipe(
        ofType(ProcessExportActions.GetExportProcessData),
        switchMap((action) => this.processService.getProcessExportData$(action.processId).pipe(
                map((data) => ProcessExportActions.GetExportProcessDataSuccess({data})),
                catchError(() => of(ProcessExportActions.GetExportProcessDataFail()))
            ))
    ));

    exportProcessPdf$ = createEffect(() => this.actions$.pipe(
        ofType(ProcessExportActions.ExportProcessPdf),
        switchMap((action) => this.processService.exportProcessPdf$(action.processId).pipe(
            map((pdf) => ProcessExportActions.ExportProcessPdfSuccess({ processId: action.processId, pdf, download: action.download })),
            catchError(() => of(ProcessExportActions.ExportProcessPdfFail()))
        ))
    ));

    exportProcessPdfSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(ProcessExportActions.ExportProcessPdfSuccess),
        filter(action => action.download),
        tap((action) => this.fileSaverService.save(action.pdf, `processes-${action.processId}-${new DatePipe('en-gb').transform(new Date(), 'yyyy-MM-dd-HH-mm-ss')}.pdf`, 'application/pdf'))
    ), { dispatch: false});

    exportProcessPdfFail$ = createEffect(() => this.actions$.pipe(
        ofType(ProcessExportActions.ExportProcessPdfFail),
        tap(() => this.alertService.error('An error occured while exporting the PDF.'))
    ), { dispatch: false});

    viewProcessPdfPack$ = createEffect(() => this.actions$.pipe(
        ofType(ProcessExportActions.ViewProcessPdfPack),
        switchMap((action) => this.processService.getProcessPdfPack$(action.processId, action.processPdfPackId).pipe(
            map((pdf) => ProcessExportActions.ViewProcessPdfPackSuccess({ pdf })),
            catchError(() => of(ProcessExportActions.ViewProcessPdfPackFail()))
        ))
    ));

    viewProcessPdfPackFail$ = createEffect(() => this.actions$.pipe(
        ofType(ProcessExportActions.ViewProcessPdfPackFail),
        tap(() => this.alertService.error('An error occured while loading the PDF.'))
    ), { dispatch: false});

    constructor(
        private actions$: Actions,
        private processService: ProcessService,
        private alertService: AlertService,
        private fileSaverService: FileSaverService) { }
}