import { Component, EventEmitter, Input, Output } from '@angular/core';

// models
import { FolderSummary } from 'app/connect/models/folder-summary.model';

@Component({
    selector: 'app-folder',
    templateUrl: 'folder.component.html',
    styleUrls: ['./folder.component.scss']
})
export class FolderComponent {

    @Input()
    selected: boolean;

    @Input()
    folder: FolderSummary;

    @Output()
    folderClick = new EventEmitter<FolderSummary>();

    @Output()
    folderEdit = new EventEmitter<FolderSummary>();

    @Output()
    folderRemove = new EventEmitter<FolderSummary>();

    @Output()
    folderClose = new EventEmitter<FolderSummary>();

}