import { AfterContentInit, Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

// ngrx | rxjs
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

// store
import * as fromConnect from 'app/connect/store';

@Component({
    selector: 'app-brand-panel',
    templateUrl: './brand-panel.component.html',
    styleUrls: ['./brand-panel.component.scss']
})
export class BrandPanelComponent implements OnInit, AfterContentInit {

    @Input()
    expandable: boolean = false;

    @Input()
    title: string;

    @Input()
    showLogo: boolean = true;

    @Input()
    showCloseButton: boolean = true;

    @Output()
    closeClicked = new EventEmitter();

    @Output()
    expandClicked = new EventEmitter<boolean>();

    expanded: boolean = false;
    visible: boolean = false;
    logoUrl$: Observable<string>;
    authenticationLogoUrl$: Observable<string>;

    constructor(private connectStore: Store<fromConnect.ConnectStoreState>) { }

    ngOnInit(): void {
        this.logoUrl$ = this.connectStore.select(fromConnect.getLogoUrl);
        this.authenticationLogoUrl$ = this.connectStore.select(fromConnect.getAuthenticationLogoUrl);
    }

    ngAfterContentInit(): void {
        this.visible = true;
    }

    handleExpandClick(): void {
        this.expanded = !this.expanded;
        this.expandClicked.emit(this.expanded);
    }

    onClose(): void {
        this.closeClicked.emit();
    }
}
