import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
    selector: '[appSkeletonLoader]',
})
export class SkeletonLoaderDirective {
    @Input('appSkeletonLoader')
    loading: boolean;

    @Input()
    theme: { [key: string]: any };

    @HostBinding('style')
    get applyStyle() {
        return this.loading ? this.theme : null;
    }

    @HostBinding('class.skeleton-loader')
    get applyClass() {
        return this.loading;
    }
}
