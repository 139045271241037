import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

// ngrx/rxjs
import { Action } from '@ngrx/store';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

// store
import * as UserInterfaceActions from 'app/store/actions/user-interface.actions';

// components
import { SessionTimeoutDialogComponent } from 'app/shared/components/session-timeout-dialog/session-timeout-dialog.component';

// services
import { AuthenticationTokenService } from 'app/shared/services/authentication-token.service';

//models
import { TimeConstants } from 'app/connect/constants/time-constants.model';

@Injectable()
export class UserInterfaceEffects {

    private static timeoutDialog: MatDialogRef<SessionTimeoutDialogComponent>;
    private static timeout: NodeJS.Timeout;

    startSessionTimeout$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(UserInterfaceActions.START_SESSION_TIMEOUT),
        tap(() => {
            if (this.authenticationTokenService.isLoggedIn() && !SessionTimeoutDialogComponent.visible) {
                UserInterfaceEffects.clearTimeout();

                UserInterfaceEffects.timeout = setTimeout(() => {
                    UserInterfaceEffects.clearTimeout();

                    UserInterfaceEffects.timeoutDialog = this.dialog.open(SessionTimeoutDialogComponent, { disableClose: true });
                }, TimeConstants.SessionTimeoutInMilliseconds);
            }
        })
    ), { dispatch: false });

    clearSessionTimeout$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(UserInterfaceActions.CLEAR_SESSION_TIMEOUT),
        tap((action: UserInterfaceActions.ClearSessionTimeout) => {
            UserInterfaceEffects.clearTimeout(action.closeSessionTimeoutDialog);
        })
    ), { dispatch: false });

    constructor(
        private actions$: Actions,
        private authenticationTokenService: AuthenticationTokenService,
        private dialog: MatDialog) { }

    private static clearTimeout(closeSessionTimeoutDialog = true): void {
        if (UserInterfaceEffects.timeout) {
            clearTimeout(UserInterfaceEffects.timeout);
            UserInterfaceEffects.timeout = null;
        }

        if (closeSessionTimeoutDialog && UserInterfaceEffects.timeoutDialog) {
            UserInterfaceEffects.timeoutDialog.close();
            UserInterfaceEffects.timeoutDialog = null;
        }
    }
}