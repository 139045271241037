import { Injectable } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

// rxjs
import { Observable } from 'rxjs';

// services
import { BlobService } from 'app/shared/services/blob.service';

@Injectable()
export class PdfViewerService {

    constructor(private sanitizer: DomSanitizer) { }

    public getPdfBase64(blob: Blob, withHeader: boolean): Observable<string> {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        return new Observable(observer => {
            reader.onloadend = () => {
                const base64data = reader.result as string;
                if (withHeader) {
                    observer.next(base64data);
                } else {
                    observer.next(base64data.substr(base64data.indexOf(',') + 1));
                }
                observer.complete();
            };
        });
    }

    public getPdfObjectUrlFromBase64(base64: string, mimeType: string): SafeResourceUrl {
        const blob = BlobService.base64toBlob(base64, mimeType);
        return this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
    }
}
