import { Component, OnInit } from '@angular/core';

// ngrx | rxjs
import { Store, ActionsSubject } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { ofType } from '@ngrx/effects';

// store
import * as fromAuth from 'app/authentication-v2/store';

// services
import { NavigationService } from 'app/shared/services/navigation.service';

// components
import { BaseComponent } from 'app/shared/base/base-component';

@Component({
    templateUrl: 'what-to-reset.component.html'
})
export class WhatToResetComponent extends BaseComponent implements OnInit {
    constructor(
        public navigation: NavigationService,
        private store: Store<fromAuth.AuthenticationState>,
        private actionsSubject: ActionsSubject
    ) {
        super();
    }

    ngOnInit(): void {
        this.actionsSubject
            .pipe(
                takeUntil(this.ngUnsubscribe),
                ofType(fromAuth.RequestPinResetSuccess)
            )
            .subscribe((action) => {
                this.navigation.gotoSetPin(true, action.request.pinResetToken, true);
            });

        this.actionsSubject
            .pipe(
                takeUntil(this.ngUnsubscribe),
                ofType(fromAuth.RequestPasswordResetSuccess)
            )
            .subscribe((action) => {
                this.navigation.gotoSetPassword(action.request.passwordResetToken, action.request.emailAddress, true);
            });
    }

    onResetPinClick(): void {
        this.store.dispatch(fromAuth.RequestPinReset({ resetPassword: false }));
    }

    onResetPasswordClick(): void {
        this.store.dispatch(fromAuth.RequestPasswordReset({ resetPin: false }));
    }

    onResetPinPasswordClick(): void {
        this.store.dispatch(fromAuth.RequestPasswordReset({ resetPin: true }));
    }
}