import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

// ngrx | rxjs
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap, catchError, tap } from 'rxjs/operators';

// Actions
import * as ProcessListActions from 'app/portal/modules/process-list/store/actions/process-list.actions';

// Services
import { ProcessService } from 'app/portal/modules/process-list/services/process.service';
import { JourneyService } from 'app/portal/modules/process-list/services/journey.service';
import { EntityService } from 'app/portal/modules/process-list/services/entity.service';
import { ProcessFolderService } from 'app/portal/modules/process-list/services/process-folder.service';
import { AlertService } from 'app/shared/components/alert/services/alert.service';
import { FileSaverService } from 'ngx-filesaver';


@Injectable()
export class ProcessListEffects {
    getProcessList$ = createEffect(() => this.actions$.pipe(
        ofType(ProcessListActions.GetProcesses),
        switchMap((action) => this.processService.getProcessList$(action.query).pipe(
                map((processes) => ProcessListActions.GetProcessesSuccess({processes})),
                catchError(() => of(ProcessListActions.GetProcessesFail()))
            ))
    ));

    getJourneys$ = createEffect(() => this.actions$.pipe(
        ofType(ProcessListActions.GetJourneys),
        switchMap(() => this.journeyService.getJourneys$().pipe(
                map((journeys) => ProcessListActions.GetJourneysSuccess({journeys})),
                catchError(() => of(ProcessListActions.GetJourneysFail()))
            ))
    ));

    getActiveJourneys$ = createEffect(() => this.actions$.pipe(
        ofType(ProcessListActions.GetActiveJourneys),
        switchMap(() => this.journeyService.getActiveJourneys$().pipe(
                map((journeys) => ProcessListActions.GetActiveJourneysSuccess({journeys})),
                catchError(() => of(ProcessListActions.GetActiveJourneysFail()))
            ))
    ));

    getEntities$ = createEffect(() => this.actions$.pipe(
        ofType(ProcessListActions.GetEntities),
        switchMap((action) => this.entityService.getEntities(action.searchData).pipe(
                map((entities) => ProcessListActions.GetEntitiesSuccess({entities})),
                catchError(() => of(ProcessListActions.GetEntitiesFail()))
            ))
    ));

    getFolders$ = createEffect(() => this.actions$.pipe(
        ofType(ProcessListActions.GetFolders),
        switchMap(() => this.processFolderService.getFolders().pipe(
                map((folders) => ProcessListActions.GetFoldersSuccess({folders})),
                catchError(() => of(ProcessListActions.GetFoldersFail()))
            ))
    ));

    createFolder$ = createEffect(() => this.actions$.pipe(
        ofType(ProcessListActions.CreateFolder),
        switchMap((action) => this.processFolderService.createFolder(action.title).pipe(
                map((folder) => ProcessListActions.CreateFolderSuccess({folder}),
                catchError(() => of(ProcessListActions.CreateFolderFail()))
            )))
    ));

    editFolder$ = createEffect(() => this.actions$.pipe(
        ofType(ProcessListActions.EditFolder),
        switchMap((action) => this.processFolderService.editFolder(action.id, action.title).pipe(
                map(() => ProcessListActions.EditFolderSuccess(),
                catchError(() => of(ProcessListActions.EditFolderFail()))
            )))
    ));

    deleteFolder$ = createEffect(() => this.actions$.pipe(
        ofType(ProcessListActions.DeleteFolder),
        switchMap((action) => this.processFolderService.deleteFolder(action.id).pipe(
                map(() => ProcessListActions.DeleteFolderSuccess(),
                catchError(() => of(ProcessListActions.DeleteFolderFail()))
            )))
    ));

    deleteFolderSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(ProcessListActions.DeleteFolderSuccess),
        map(() => ProcessListActions.GetFolders())
    ));

    addProcessesToFolders$ = createEffect(() => this.actions$.pipe(
        ofType(ProcessListActions.AddProcessesToFolders),
        switchMap((action) => this.processFolderService.addProcessesToFolders(action.processIds, action.folderIds).pipe(
                map(() => ProcessListActions.AddProcessesToFoldersSuccess(),
                catchError(() => of(ProcessListActions.AddProcessesToFoldersFail()))
            )))
    ));

    removeProcessesFromFolder$ = createEffect(() => this.actions$.pipe(
        ofType(ProcessListActions.RemoveProcessesFromFolder),
        switchMap((action) => this.processFolderService.removeProcessesFromFolder(action.processIds, action.folderId).pipe(
                map(() => ProcessListActions.RemoveProcessesFromFolderSuccess(),
                catchError(() => of(ProcessListActions.RemoveProcessesFromFolderFail()))
            )))
    ));

    deleteProcess$ = createEffect(() => this.actions$.pipe(
        ofType(ProcessListActions.DeleteProcess),
        switchMap((action) => this.processService.deleteProcess$(action.id).pipe(
                map(() => ProcessListActions.DeleteProcessSuccess(),
                catchError(() => of(ProcessListActions.DeleteProcessFail()))
            )))
    ));

    getSmsTemplateAvailability$ = createEffect(() => this.actions$.pipe(
        ofType(ProcessListActions.GetSmsTemplatesAvailable),
        switchMap(() => this.processService.getSmsTemplateAvailability$().pipe(
                map((templateAvailability) => ProcessListActions.GetSmsTemplatesAvailableSuccess({templateAvailability}),
                catchError(() => of(ProcessListActions.GetSmsTemplatesAvailableFail()))
            )))
    ));

    lockProcess$ = createEffect(() => this.actions$.pipe(
        ofType(ProcessListActions.LockProcess),
        switchMap(action => this.processService.lockProcess$(action.processId).pipe(
            map(() => ProcessListActions.LockProcessSuccess({ processId: action.processId })),
            catchError(() => of(ProcessListActions.LockProcessFail()))
        ))
    ));

    lockProcessFail$ = createEffect(() => this.actions$.pipe(
        ofType(ProcessListActions.LockProcessFail),
        tap(() => this.alertService.error('Unable to lock the process.'))
    ), { dispatch: false });

    unlockProcess$ = createEffect(() => this.actions$.pipe(
        ofType(ProcessListActions.UnlockProcess),
        switchMap(action => this.processService.unlockProcess$(action.processId).pipe(
            map(() => ProcessListActions.UnlockProcessSuccess({ processId: action.processId })),
            catchError(() => of(ProcessListActions.UnlockProcessFail()))
        ))
    ));

    unlockProcessFail$ = createEffect(() => this.actions$.pipe(
        ofType(ProcessListActions.UnlockProcessFail),
        tap(() => this.alertService.error('Unable to unlock the process.'))
    ), { dispatch: false });

    getInvitedByUsers$ = createEffect(() => this.actions$.pipe(
        ofType(ProcessListActions.GetInvitedByUsers),
        switchMap(() => this.processService.getInvitedByUsersList$().pipe(
                map((invitedByUsers) => ProcessListActions.GetInvitedByUsersSuccess({invitedByUsers})),
                catchError(() => of(ProcessListActions.GetInvitedByUsersFail()))
            ))
    ));

    getInvitedByUsersFail$ = createEffect(() => this.actions$.pipe(
        ofType(ProcessListActions.GetInvitedByUsersFail),
        tap(() => this.alertService.error('Unable to get invited by users list. Please contact support'))
    ), { dispatch: false });

    exportProcesses$ = createEffect(() => this.actions$.pipe(
        ofType(ProcessListActions.ExportProcesses),
        switchMap((action) => this.processService.exportProcesses$(action.request).pipe(
            map((csv) => ProcessListActions.ExportProcessesSuccess({ csv })),
            catchError(() => of(ProcessListActions.ExportProcessesFail()))
        ))
    ));

    exportProcessesSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(ProcessListActions.ExportProcessesSuccess),
        tap((action) => this.fileSaverService.save(action.csv, `processes-list-${new DatePipe('en-gb').transform(new Date(), 'yyyy-MM-dd-HH-mm-ss')}.csv`, 'text/csv'))
    ), { dispatch: false});

    exportProcessesFail$ = createEffect(() => this.actions$.pipe(
        ofType(ProcessListActions.ExportProcessesFail),
        tap(() => this.alertService.error('An error occured while exporting the data.'))
    ), { dispatch: false});

    constructor(
        private actions$: Actions,
        private processService: ProcessService,
        private processFolderService: ProcessFolderService,
        private journeyService: JourneyService,
        private entityService: EntityService,
        private alertService: AlertService,
        private fileSaverService: FileSaverService) { }
}