import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-carousel',
    templateUrl: 'carousel.component.html',
    styleUrls: ['carousel.component.scss'],
})
export class CarouselComponent {
    @Input()
    scrollBehavior: 'smooth' | 'auto' = 'auto';

    @Input()
    scrollIncrementInPx: number;

    moveLeft(container: HTMLElement) {
        container.scroll({
            left:
                container.scrollLeft -
                Math.max(this.getScrollLeftMax(container) / 2, this.scrollIncrementInPx),
            behavior: this.scrollBehavior,
        });
    }

    moveRight(container: HTMLElement) {
        container.scroll({
            left:
                container.scrollLeft +
                Math.max(this.getScrollLeftMax(container) / 2, this.scrollIncrementInPx),
            behavior: this.scrollBehavior,
        });
    }

    getScrollLeftMax(container: HTMLElement) {
        return container.scrollWidth - container.clientWidth;
    }
}
