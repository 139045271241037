// ngrx
import { createFeatureSelector, createSelector } from '@ngrx/store';

// store
import * as reducers from 'app/connect/store/reducers';

// models
import { StoreNames } from 'app/connect/models/store-names.model';

const storeState = createFeatureSelector<reducers.ConnectStoreState>(
    StoreNames.connectCommon
);

const reducerState = createSelector(
    storeState,
    (state: reducers.ConnectStoreState) => state.userAlert
);

export const getUserAlerts = createSelector(
    reducerState,
    (state: reducers.AlertState) => state.alerts
);

export const getUserAlertsLoading = createSelector(
    reducerState,
    (state: reducers.AlertState) => state.loading
);

export const getUnreadAlertsCount = createSelector(
    reducerState,
    (state: reducers.AlertState) => state.alerts.filter((x) => !x.read).length
);

export const getHighlightedAlert = createSelector(
    reducerState,
    (state: reducers.AlertState) => state.highlightedAlert
);
