// Store
import { Action, createReducer, on } from '@ngrx/store';
import * as actions from 'app/authentication-v2/store/actions';

// Models
import { TemporaryPasswordReset } from 'app/authentication-v2/models/temporary-password-reset.model';
import { AuthenticatorSetup } from 'app/authentication-v2/models/authenticator-setup.model';

// Enums
import { ClientUserTokenType } from 'app/shared/enums/client-user-token-type.enum';


export interface AuthenticationState {
    working: boolean;
    authenticationMessage: string;
    passwordResetOnLogin: TemporaryPasswordReset;
    acceptingTerms: boolean;
    resetPin: boolean;
    resetPassword: boolean;
    clientUserTokenType: ClientUserTokenType;
    authenticatorSetup: AuthenticatorSetup;
}

const initialState: AuthenticationState = {
    working: null,
    authenticationMessage: null,
    passwordResetOnLogin: null,
    acceptingTerms: false,
    resetPin: false,
    resetPassword: false,
    clientUserTokenType: null,
    authenticatorSetup: null,
};

const authReducer = createReducer(
    initialState,
    on(actions.Login, (state) => ({
        ...state,
        working: true,
        authenticationMessage: null,
    })),
    on(actions.LoginFail, (state, action) => ({
        ...state,
        working: false,
        authenticationMessage: action.message,
    })),
    on(actions.LoginSuccess, (state, action) => ({
        ...state,
        working: false,
        clientUserTokenType: action.user.token.clientUserTokenType,
    })),
    on(actions.RehydrateUserSuccess, (state, action) => ({
        ...state,
        working: false,
        clientUserTokenType: action.user.token.clientUserTokenType,
    })),
    on(actions.RefreshTokenSuccess, (state, action) => ({
        ...state,
        clientUserTokenType: action.token.clientUserTokenType,
    })),
    on(actions.TokenLoginSuccess, (state, action) => ({
        ...state,
        clientUserTokenType: action.user.token.clientUserTokenType,
    })),
    on(actions.Logout, (state) => ({
        ...state,
        working: false,
    })),
    on(actions.PasswordResetRequired, (state, action) => ({
        ...state,
        passwordResetOnLogin: action.request,
        working: false,
    })),
    on(actions.ClearPasswordResetState, (state) => ({
        ...state,
        passwordResetOnLogin: null,
        working: false,
    })),
    on(actions.TermsAccepted, (state) => ({
        ...state,
        acceptingTerms: true,
    })),
    on(actions.TermsAcceptedSuccess, (state) => ({
        ...state,
        acceptingTerms: false,
    })),
    on(actions.TermsAcceptedFail, (state) => ({
        ...state,
        acceptingTerms: false,
    })),

    on(actions.SetPin, (state) => ({
        ...state,
        working: true,
    })),
    on(actions.SetPinSuccess, (state) => ({
        ...state,
        working: false,
    })),
    on(actions.SetPinFail, (state) => ({
        ...state,
        working: false,
    })),

    on(actions.SetMobileNumber, (state) => ({
        ...state,
        working: true,
    })),
    on(actions.SetMobileNumberSuccess, (state) => ({
        ...state,
        working: false,
    })),
    on(actions.SetMobileNumberFail, (state) => ({
        ...state,
        working: false,
    })),

    on(actions.ConfirmOtp, (state) => ({
        ...state,
        working: true,
    })),
    on(actions.ConfirmOtpSuccess, (state) => ({
        ...state,
        working: false,
    })),
    on(actions.ConfirmOtpFail, (state) => ({
        ...state,
        working: false,
    })),
    on(actions.ConfirmOtpExpired, (state) => ({
        ...state,
        working: false,
    })),

    on(actions.ActionRequired, (state) => ({
        ...state,
        working: false,
    })),

    on(actions.ConfirmPin, (state) => ({
        ...state,
        working: true,
    })),
    on(actions.ConfirmPinSuccess, (state) => ({
        ...state,
        working: false,
    })),
    on(actions.ConfirmPinFail, (state) => ({
        ...state,
        working: false,
    })),

    on(actions.TriggerSecurityReset, (state) => ({
        ...state,
        working: true,
    })),
    on(actions.TriggerSecurityResetSuccess, (state) => ({
        ...state,
        working: false,
    })),
    on(actions.TriggerSecurityResetFail, (state) => ({
        ...state,
        working: false,
    })),

    on(actions.RequestPinReset, (state, action) => ({
        ...state,
        working: true,
        resetPin: true,
        resetPassword: action.resetPassword,
    })),
    on(actions.RequestPinResetSuccess, (state) => ({
        ...state,
        working: false,
        resetPin: false,
    })),
    on(actions.RequestPinResetFail, (state) => ({
        ...state,
        working: false,
    })),

    on(actions.RequestPasswordReset, (state, action) => ({
        ...state,
        working: true,
        resetPassword: true,
        resetPin: action.resetPin,
    })),
    on(actions.RequestPasswordResetSuccess, (state) => ({
        ...state,
        working: false,
        resetPassword: false,
    })),
    on(actions.RequestPasswordResetFail, (state) => ({
        ...state,
        working: false,
    })),

    on(actions.SetTemporaryPin, (state) => ({
        ...state,
        working: true,
    })),
    on(actions.SetTemporaryPinSuccess, (state) => ({
        ...state,
        working: false,
    })),
    on(actions.SetTemporaryPinFail, (state) => ({
        ...state,
        working: false,
    })),

    on(actions.GenerateTemporaryPassword, (state) => ({
        ...state,
        working: true,
    })),
    on(actions.GenerateTemporaryPasswordSuccess, (state) => ({
        ...state,
        working: false,
    })),
    on(actions.GenerateTemporaryPasswordFail, (state) => ({
        ...state,
        working: false,
    })),

    on(actions.GenerateAuthenticatorSetup, (state) => ({
        ...state,
        working: true,
    })),
    on(actions.GenerateAuthenticatorSetupSuccess, (state, action) => ({
        ...state,
        working: false,
        authenticatorSetup: action.authenticatorSetup,
    })),
    on(actions.GenerateAuthenticatorSetupFail, (state) => ({
        ...state,
        working: false,
    })),

    on(actions.ConfirmAuthenticatorSetup, (state) => ({
        ...state,
        working: true,
    })),
    on(actions.ConfirmAuthenticatorSetupSuccess, (state) => ({
        ...state,
        working: false,
    })),
    on(actions.ConfirmAuthenticatorSetupFail, (state) => ({
        ...state,
        working: false,
    })),

    on(actions.ValidateAuthenticatorCode, (state) => ({
        ...state,
        working: true,
    })),
    on(actions.ValidateAuthenticatorCodeSuccess, (state) => ({
        ...state,
        working: false,
    })),
    on(actions.ValidateAuthenticatorCodeFail, (state, action) => {
        if (action.response == null) {
            return {
                ...state,
                working: false,
                authenticationMessage:
                    'There was an error signing you in, please check and try again.',
            };
        }

        let authenticationMessage = '';

        if (action.response.isLockedout) {
            const minutesRemaining = Math.max(
                0,
                Math.ceil(action.response.lockoutSecondsRemaining / 60)
            );

            authenticationMessage = `Your account has been locked, please wait ${minutesRemaining} minute${
                minutesRemaining === 1 ? '' : 's'
            } and then try again.`;
        } else {
            authenticationMessage = `Incorrect PIN entered. You have ${
                action.response.attemptsRemaining
            } more attempt${
                action.response.attemptsRemaining === 1 ? '' : 's'
            } to log in before your account is locked for 15 minutes.`;
        }

        return {
            ...state,
            working: false,
            authenticationMessage
        };
    }),

    on(actions.ConfirmAuthenticationMethod, (state) => ({
        ...state,
        working: true,
    })),
    on(actions.ConfirmAuthenticationMethodSuccess, (state) => ({
        ...state,
        working: false,
    })),
    on(actions.ConfirmAuthenticationMethodFail, (state) => ({
        ...state,
        working: false,
    })),
);

export function reducer(state: AuthenticationState, action: Action) {
    return authReducer(state, action);
}