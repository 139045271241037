import { BritishTerritory } from 'app/shared/enums/british-territory.enum';

const PostcodeLookup = new Map<BritishTerritory, string[]>();

PostcodeLookup.set(BritishTerritory.England, ['AL', 'CB', 'CM', 'CO', 'EN', 'IG', 'IP', 'LU', 'MK', 'NR', 'PE', 'RM', 'SG', 'SS', 'WD', 'B', 'CV', 'DE', 'DY', 'LE', 'NG', 'NN', 'ST', 'WS', 'WV', 'BD', 'DH', 'DL', 'DN', 'HD', 'HG', 'HU', 'HX', 'LN', 'LS', 'NE', 'S', 'SR', 'TS', 'WF', 'YO', 'BB', 'BL', 'CA', 'CW', 'FY', 'L', 'LA', 'M', 'OL', 'PR', 'SK', 'SY', 'TF', 'WA', 'WN', 'CH', 'E', 'EC', 'N', 'NW', 'SE', 'SW', 'W', 'WC', 'GU', 'HA', 'HP', 'OX', 'PO', 'RG', 'SL', 'SN', 'SO', 'SP', 'UB', 'BN', 'BR', 'CR', 'CT', 'DA', 'KT', 'ME', 'RH', 'SM', 'TN', 'TW', 'BA', 'BH', 'BS', 'DT', 'EX', 'GL', 'HR', 'PL', 'TA', 'TQ', 'TR', 'WR']);
PostcodeLookup.set(BritishTerritory.Scotland, ['AB', 'DD', 'DG', 'EH', 'FK', 'G', 'HS', 'IV', 'KA', 'KW', 'KY', 'ML', 'PA', 'PH', 'TD', 'ZE']);
PostcodeLookup.set(BritishTerritory.Wales, ['CF', 'LD', 'LL', 'NP', 'SA']);
PostcodeLookup.set(BritishTerritory.NorthernIreland, ['BT']);
PostcodeLookup.set(BritishTerritory.Guernsey, ['GY']);
PostcodeLookup.set(BritishTerritory.Jersey, ['JE']);
PostcodeLookup.set(BritishTerritory.IsleOfMan, ['IM']);

const UkPostcodePattern = /^([a-zA-Z]{1,2})\d[a-zA-Z]?\d{1,2}[a-zA-Z]{1,2}$/;

export class PostcodeService {
    getBritishTerritory(postcode: string): BritishTerritory | null {
        postcode = (postcode || '').toUpperCase().replace(' ', '');

        const match = UkPostcodePattern.exec(postcode);

        if (!match || match.length < 2) {
            return null;
        }

        const postcodePart = match[1];

        for (const [key, value] of PostcodeLookup.entries()) {
            if (value.includes(postcodePart)) {
                return key;
            }
        }

        return null;
    }
}