import { FilterOptionValue } from './filter-option-value.model';

export class FilterOption {
    name: string;
    values: FilterOptionValue[];
    sort: boolean;
    multiSelect: boolean;
    groupWithStandaloneFilters: boolean;
    open: boolean;

    constructor(name: string, sort: boolean, multiSelect: boolean, groupWithStandaloneFilters: boolean = false) {
        this.name = name;
        this.values = [];
        this.sort = sort;
        this.multiSelect = multiSelect;
        this.groupWithStandaloneFilters = groupWithStandaloneFilters;
    }

    addValue(id: string, value: string): FilterOptionValue {
        if (this.values.filter(v => v.id === id).length) {
            return null;
        }

        const optionValue = new FilterOptionValue(this.name, id, value);
        this.values.push(optionValue);
        return optionValue;
    }

    clear() {
        this.values = [];
    }
}