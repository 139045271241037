import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

// rxjs
import { Observable } from 'rxjs';

// models
import { UserNotification } from 'app/connect/models/user-notification.model';

@Injectable()
export class NotificationService {
    baseUrl = 'api/v2/notifications';

    constructor(private http: HttpClient) {}

    getNotifications$(): Observable<UserNotification[]> {
        return this.http.get<UserNotification[]>(this.baseUrl);
    }

    readNotifications$(id: number): Observable<UserNotification> {
        return this.http.put<UserNotification>(
            `${this.baseUrl}/${id}/read`,
            {}
        );
    }

    dismissNotifications$(id: number): Observable<UserNotification> {
        return this.http.put<UserNotification>(
            `${this.baseUrl}/${id}/dismiss`,
            {}
        );
    }
}
