import { Action } from '@ngrx/store';
import { DisplayScreen, MobileDevice } from 'app/shared/enums';

export const SET_DISPLAY_SCREEN_TYPE = '[Global UI] Set Display Type (Desktop, Tablet, Mobile)';
export const SET_MOBILE_DEVICE_TYPE = '[Global UI] Set Mobile Device Type (iOS, Android, Unknown)';
export const SET_MODULE = '[Global UI] Set the current module';
export const EMPTY_STATE = '[Global UI] Resets the state of the UI store';

export const START_SESSION_TIMEOUT = '[Global UI] Start session timeout';
export const CLEAR_SESSION_TIMEOUT = '[Global UI] Clear session timeout';

export class SetDisplayScreenType implements Action {
    readonly type = SET_DISPLAY_SCREEN_TYPE;
    constructor(public payload: DisplayScreen) { }
}

export class SetMobileDeviceType implements Action {
    readonly type = SET_MOBILE_DEVICE_TYPE;
    constructor(public payload: MobileDevice) { }
}

export class EmptyState implements Action {
    readonly type = EMPTY_STATE;
}

export class StartSessionTimeout implements Action {
    readonly type = START_SESSION_TIMEOUT;
}

export class ClearSessionTimeout implements Action {
    readonly type = CLEAR_SESSION_TIMEOUT;
    constructor(public closeSessionTimeoutDialog: boolean = true) { }
}

export type AllUserInterfaceActions
    = SetDisplayScreenType
    | SetMobileDeviceType
    | EmptyState
    | StartSessionTimeout
    | ClearSessionTimeout;