import { Component, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

// rxjs |ngrx
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// store
import * as fromConnectStore from 'app/connect/store';
import { Store } from '@ngrx/store';

// components
import { BaseComponent } from 'app/shared/base/base-component';

// models
import { Address } from 'app/portal/modules/inputs/models/address.model';

// utilities
import { isNullOrWhitespace } from 'app/shared/utilities/string-utilities';


@Component({
    selector: 'app-postcode-lookup',
    templateUrl: './postcode-lookup.component.html',
    styleUrls: ['./postcode-lookup.component.scss']
})
export class PostcodeLookupComponent extends BaseComponent implements OnInit {
    constructor(
        private formBuilder: FormBuilder,
        private connectStore: Store<fromConnectStore.ConnectStoreState>
    ) {
        super();
    }

    private addressSelectedSubject = new Subject<Address>();
    private manualEntrySubject = new Subject<void>();

    postcodeForm: FormGroup;
    addresses: Address[];
    lookupError = '';
    hasAddresses = false;
    searching$: Observable<boolean>;

    @Input()
    postcode: string;

    @Output()
    addressSelected = this.addressSelectedSubject.asObservable();

    @Output()
    manualEntrySelected = this.manualEntrySubject.asObservable();

    ngOnInit(): void {
        this.connectStore.dispatch(fromConnectStore.ClearPostcodeLookup());

        this.postcodeForm = this.formBuilder.group({
            postcode: [this.postcode, [
                Validators.required,
                Validators.pattern(`^(([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))\\s?[0-9][A-Za-z]{2}))$`)
            ]],
        });

        this.connectStore
            .select(fromConnectStore.getAddresses)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((addresses) => {
                this.addresses = addresses ?? [];
                this.hasAddresses = !!addresses; //this.addresses.length > 0;
            });

        this.searching$ = this.connectStore.select(fromConnectStore.getSearching);
    }

    onManualEntryClick(): void {
        this.manualEntrySubject.next();
    }

    onSearchClicked(): void {
        if (this.postcodeForm.invalid) {
            return;
        }

        const postcode = this.postcodeForm.get('postcode').value.trim();

        this.connectStore.dispatch(fromConnectStore.LookupPostcode({ postcode }));
    }

    onChangePostcodeClick(): void {
        this.postcodeForm.patchValue({ postcode: '' });
        this.addresses = [];
        this.hasAddresses = false;
        this.postcodeForm.get('postcode').setErrors(null);
    }

    formatAddress(address: Address): string {
        let formattedAddress = address.addressLine1;

        if (!isNullOrWhitespace(address.addressLine2)) {
            formattedAddress = formattedAddress + ', ' + address.addressLine2;
        }

        if (!isNullOrWhitespace(address.town)) {
            formattedAddress = formattedAddress + ', ' + address.town;
        }

        if (!isNullOrWhitespace(address.county)) {
            formattedAddress = formattedAddress + ', ' + address.county;
        }

        formattedAddress = formattedAddress + ', ' + address.postcode;

        return formattedAddress;
    }

    onAddressSelected(address: Address) {
        this.addressSelectedSubject.next(address);
    }
}