import { createAction, props } from '@ngrx/store';

// models
import { Address } from 'app/portal/modules/inputs/models/address.model';

export const LookupPostcode = createAction(
    '[PostcodeLookup] Lookup postcode',
    props<{ postcode: string }>()
);

export const LookupPostcodeSuccess = createAction(
    '[PostcodeLookup] Lookup postcode success',
    props<{ addresses: Address[] }>()
);

export const LookupPostcodeFail = createAction(
    '[PostcodeLookup] Lookup postcode fail'
);

export const ClearPostcodeLookup = createAction(
    '[PostcodeLookup] Clear postcode lookup'
);
