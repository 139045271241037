import {
    Component,
    ContentChildren,
    forwardRef,
    Input,
    QueryList,
    EventEmitter,
    Output,
    ElementRef,
    AfterViewInit,
    OnDestroy,
    HostListener,
} from '@angular/core';

// rxjs
import { BehaviorSubject, takeUntil } from 'rxjs';

// components
import { BaseComponent } from 'app/shared/base/base-component';
import { SegmentedButtonComponent } from 'app/shared/components/segmented-button/segmented-button.component';

@Component({
    selector: 'app-segmented-button-group',
    templateUrl: 'segmented-button-group.component.html',
    styleUrls: ['segmented-button-group.component.scss'],
})
export class SegmentedButtonGroupComponent
    extends BaseComponent
    implements AfterViewInit, OnDestroy {
    @Input()
    get value() {
        return this.value$.value;
    }
    set value(val: any) {
        this.value$.next(val);
    }

    @Output()
    valueChange = new EventEmitter<any>();

    @ContentChildren(forwardRef(() => SegmentedButtonComponent))
    segmentedButtons: QueryList<SegmentedButtonComponent>;

    private value$ = new BehaviorSubject<any>(null);

    constructor(private elementRef: ElementRef) {
        super();
    }

    @HostListener('window:resize')
    onResize() {
        this.highlight(this.segmentedButtons.find((x) => x.checked));
    }

    ngAfterViewInit() {
        this.value$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((val) => {
            this.segmentedButtons.forEach((button) => {
                button.checked = button.value === val;

                if (button.checked) {
                    this.highlight(button);
                }
            });
        });
    }

    syncValue(value: any) {
        this.value$.next(value);
        this.valueChange.emit(value);
    }

    highlight(button: SegmentedButtonComponent) {
        this.elementRef.nativeElement.style.setProperty(
            '--highlight-width',
            `${button.buttonElement.nativeElement.offsetWidth}px`
        );
        this.elementRef.nativeElement.style.setProperty(
            '--highlight-x-pos',
            `${button.buttonElement.nativeElement.offsetLeft}px`
        );
    }
}
