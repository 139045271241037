import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { LightenDarkenColor } from 'lighten-darken-color';

// ngrx
import { ActionsSubject, select, Store } from '@ngrx/store';
import { ofType } from '@ngrx/effects';

// store
import * as fromStore from 'app/connect/store';

// components
import { BaseComponent } from 'app/shared/base/base-component';

// models
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-connect-branding',
    template: `<div [innerHTML]="styles"></div>`
})
export class BrandingComponent extends BaseComponent implements OnInit {

    styles: SafeHtml;
    headerColor: string;
    primaryColor: string;
    primaryReverseColor: string;
    textColor: string;
    secondaryColor: string;
    secondaryColorDark: string;
    accentColor: string;
    credasLogoColor: string;
    favIcon: string;
    tableAndTabColor: string;
    tableAndTabTextColor: string;

    constructor(
        private sanitizer: DomSanitizer,
        private store: Store<fromStore.ConnectStoreState>,
        private actionsSubject: ActionsSubject) {
        super();
     }

    ngOnInit(): void {
        this.store.pipe(takeUntil(this.ngUnsubscribe), select(fromStore.getHeaderColor)).subscribe((color: string) => this.headerColor = color);
        this.store.pipe(takeUntil(this.ngUnsubscribe), select(fromStore.getPrimaryColor)).subscribe((color: string) => this.primaryColor = color);
        this.store.pipe(takeUntil(this.ngUnsubscribe), select(fromStore.getPrimaryReverseColor)).subscribe((color: string) => this.primaryReverseColor = color);
        this.store.pipe(takeUntil(this.ngUnsubscribe), select(fromStore.getTextColor)).subscribe((color: string) => this.textColor = color);
        this.store.pipe(takeUntil(this.ngUnsubscribe), select(fromStore.getSecondaryColor)).subscribe((color: string) => this.secondaryColor = color);
        this.store.pipe(takeUntil(this.ngUnsubscribe), select(fromStore.getSecondaryColorDark)).subscribe((color: string) => this.secondaryColorDark = color);
        this.store.pipe(takeUntil(this.ngUnsubscribe), select(fromStore.getAccentColor)).subscribe((color: string) => this.accentColor = color);
        this.store.pipe(takeUntil(this.ngUnsubscribe), select(fromStore.getCredasLogoColor)).subscribe((color: string) => this.credasLogoColor = color);
        this.store.pipe(takeUntil(this.ngUnsubscribe), select(fromStore.getFavIcon)).subscribe((favIcon: string) => this.favIcon = favIcon);
        this.store.pipe(takeUntil(this.ngUnsubscribe), select(fromStore.getTableAndTabColor)).subscribe((color: string) => this.tableAndTabColor = color);
        this.store.pipe(takeUntil(this.ngUnsubscribe), select(fromStore.getTableAndTabTextColor)).subscribe((color: string) => this.tableAndTabTextColor = color);

        this.buildStyles();

        this.actionsSubject
            .pipe(
                ofType(fromStore.ClearBranding, fromStore.SetBranding),
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe(() => this.buildStyles());
    }

    private buildStyles(): void {
        setTimeout(() => {

            const favIcon: HTMLLinkElement = document.querySelector('#site-icon');
            if (favIcon) {
                favIcon.href = this.favIcon;
            }

            this.styles = this.sanitizer.bypassSecurityTrustHtml(`
            <style>
                ${this.getBaseClasses()}
                ${this.getMaterialColors()}
                ${this.getButtonColors()}
                ${this.getIconColors()}
                ${this.getBrandPanelColors()}
                </style>
                `);
        }, 1);

    }

    private getBaseClasses(): string {
        return `
            body .connect-header { background-color: ${this.headerColor ?? this.primaryColor}; }
            body .connect-header .user-menu * { color: ${this.primaryColor}; }
            body .connect-header .menu-button { border-color: ${this.textColor}!important; color: ${this.textColor}!important; }
            body .connect-header .menu-button:hover { border-color: ${this.secondaryColor}!important; color: ${this.secondaryColor}!important; }
            body .connect-header i { color: ${this.textColor}; }
            body .connect-header nav a { color: ${this.textColor}; }
            body .connect-header nav a.active { color: ${this.secondaryColor}; }
            body .connect-header nav a::after { background-color: ${this.secondaryColor}; }
            body .connect-header .separator { border-color: ${this.textColor}; }
            
            body .connect-banner { background-color: ${this.headerColor ?? this.primaryColor}; }
            body .connect-banner * { color: ${this.textColor}; }
            body .connect-banner .custom-banner-content * { color: ${this.primaryColor}; }
            body .connect-banner nav a::after { background-color: ${this.primaryColor}; }
            body .connect-banner nav a:hover::after, body .connect-banner nav a.active::after { color: ${this.primaryColor}; }

            .header-bg { background-color: ${this.headerColor ?? this.primaryColor} !important; }
            .header-text { color: ${this.textColor} !important; }
            .primary-text { color: ${this.primaryColor}; }
            .primary-bg { background-color: ${this.primaryColor} !important; }
            .primary-bg::-webkit-scrollbar-thumb { background-color: ${this.primaryReverseColor}; }
            .primary-border { border-color: ${this.primaryColor}; }
            .primary-reverse-text, .primary-reverse-text * { color: ${this.primaryReverseColor} !important; }
            .primary-reverse-bg { background-color: ${this.primaryReverseColor}; }
            .secondary-text { color: ${this.secondaryColor}; }
            .secondary-bg { background-color: ${this.secondaryColor}; }
            a { color: ${this.primaryColor}; }
            *::-webkit-scrollbar-thumb { background-color: ${this.primaryColor}; }
            app-loading-card .spinner { background: linear-gradient(to right, ${this.primaryColor} 0, transparent 25%); }
        `;
    }

    private getBrandPanelColors(): string {
        return `
            #brand-panel.expandable { border-left: 1px solid ${this.primaryColor}; }
            #brand-panel .expand-button {
                border-left: 1px solid ${this.primaryColor};
                border-bottom: 1px solid ${this.primaryColor};
                border-top: 1px solid ${this.primaryColor};
            }
        `;
    }

    private getButtonColors(): string {
        return `
            .btn.primary.dark:not(:disabled) {
                background-color: ${this.primaryColor};
                border-color: ${this.primaryColor};
                color: ${this.primaryReverseColor};
            }

            .btn.primary.dark:active:not(:disabled) {
                background-color: ${this.darken(this.primaryColor)};
                border-color: ${this.darken(this.primaryColor)};
            }

            .btn.primary.dark:hover:not(:disabled) {
                background-color: ${this.lighten(this.primaryColor)};
                border-color: ${this.lighten(this.primaryColor)};
            }

            .btn.primary.dark > i, mat-icon {
                color: ${this.secondaryColor};
            }

            .branded-dialog-close-button {
                background-color: ${this.textColor} !important;
                border-color: ${this.textColor} !important;
                outline-color: ${this.textColor} !important;
                color: ${this.headerColor} !important;
            }

            #help-faq-search .content .search_items .search_item:hover,
            #help-faq-search .content .search_items .search_item.active {
                background-color: ${this.secondaryColor};
                color: ${this.textColor};
            }
        `;
    }

    private getIconColors(): string {
        return `
            .mat-icon.primary {
                color: ${this.textColor};
            }

            .mat-icon.secondary {
                color: ${this.secondaryColor};
            }
        `;
    }

    private getMaterialColors(): string {
        return `
            .mat-tab-group .mat-tab-label, .mat-tab-group .mat-tab-label * { color: ${this.primaryReverseColor}!important; }
            .mat-tab-group .mat-ink-bar { background-color: ${this.secondaryColor}!important; }
            .mat-tab-group .mat-tab-list { background-color: ${this.primaryColor}; }
            .mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent { background-color: ${this.accentColor}; }
            .mat-progress-spinner circle, .mat-spinner circle { stroke: ${this.secondaryColor}; }
            .mat-checkbox.mat-checkbox-checked .mat-checkbox-frame { border-color: ${this.primaryColor}; }
            .mat-checkbox.mat-checkbox-checked .mat-checkbox-background { background-color: ${this.primaryColor}!important; }
            .mat-checkbox.mat-checkbox-checked:not(.mat-checkbox-disabled) .mat-checkbox-checkmark-path { stroke: ${this.primaryReverseColor}!important; }
        `;
    }

    private darken(color: string): string {
        return this.lightenDarken(color, -25);
    }

    private lighten(color: string): string {
        return this.lightenDarken(color, 25);
    }

    private lightenDarken(color: string, amount: number): string {
        if (color) {
            const hasHash = color.startsWith('#');
            if (hasHash) {
                color = color.substring(1);
            }

            let newColor = <string>LightenDarkenColor(color, amount);
            while(newColor.length < 6) {
                newColor = '0' + newColor;
            }
            return (hasHash ? '#' : '') + newColor;
        }
        return color;
    }
}
