<div *ngIf="item" class="group-node-status" [ngClass]="setTextColor ? statusClass : null">
    <div *ngIf="showBouncedEmailLink" class="status pointer">
        <div (click)="handleBouncedInviteClicked()" fxLayout="row" fxLayoutAlign="start center">
            <mat-icon>warning</mat-icon>
            <div class="font-size-s">{{statusLabel}}</div>
        </div>
    </div>
    <div *ngIf="!showBouncedEmailLink" class="status" fxLayout="row" fxLayoutAlign="start center">
        <mat-icon *ngIf="item.hasAlerts">warning</mat-icon>
        <i *ngIf="showIcon && !item.hasAlerts && statusClass === 'invited'" class="fa fa-solid fa-paper-plane grey-250-text"></i>
        <i *ngIf="showIcon && !item.hasAlerts && statusClass === 'active'" class="fa fa-solid fa-circle-check rag-green-text"></i>
        <i *ngIf="showIcon && !item.hasAlerts && statusClass === 'new'" class="fa fa-solid fa-circle-check rag-green-text"></i>
        <i *ngIf="showIcon && !item.hasAlerts && statusClass === 'expired'" class="fa fa-solid fa-triangle-exclamation rag-red-text"></i>
        <i *ngIf="showIcon && !item.hasAlerts && statusClass === 'bounced'" class="fa fa-solid fa-triangle-exclamation rag-red-text"></i>
        <div class="font-size-s">{{statusLabel}}</div>
    </div>
</div>