import {
    Component,
    Input,
    Output,
    EventEmitter,
    OnChanges,
} from '@angular/core';

// models
import { EntitySummary } from 'app/portal/modules/entity-list/models/entity-summary.model';

// enums
import { getEntityStatusClassName, getEntityStatusText } from 'app/portal/modules/entity-list/enumerations/entity-status.enum';

@Component({
    selector: 'app-entity-status',
    templateUrl: './entity-status.component.html',
    styleUrls: ['./entity-status.component.scss'],
})
export class EntityStatusComponent implements OnChanges {
    @Input()
    item: EntitySummary;

    @Input()
    label: string = undefined;

    @Input()
    showIcon: boolean;

    @Input()
    setTextColor: boolean = true;

    @Output()
    bouncedInviteClicked: EventEmitter<void> = new EventEmitter();

    statusLabel: string;
    statusClass: string;
    showBouncedEmailLink = false;

    ngOnChanges() {
        this.setStatusLabel();
    }

    setStatusLabel() {
        if (!this.item) {
            return;
        }

        this.statusClass = getEntityStatusClassName(this.item.status);
        this.statusLabel = getEntityStatusText(this.item.status);
    }



    handleBouncedInviteClicked(): void {
        this.bouncedInviteClicked.emit();
    }
}
