<div id="notification-card" [class.read]="notification.read">
    <div class="notification-wrapper">
        <div class="notification-header-wrapper">
            <div class="notification-header">
                <span class="notification-date">{{notification.date | date:'dd/MM/yyyy @ HH:mm'}}</span>
                <app-notification-priority size="small" [priority]="notification.priority"></app-notification-priority>
            </div>
            <div>
                <button class="btn dismiss-button" title="Dismiss notification" (click)="dismiss.emit()">
                    <i class="fa fa-solid fa-times"></i>
                </button>
            </div>
        </div>
        <div class="notification-body">
            <h2 class="notification-title">{{notification.title}}</h2>
            <div class="notification-content" >
                {{textContent}}
            </div>
        </div>
        <div class="notification-footer">
            <button class="btn empty icon-r read-more-button" (click)="read.emit()">
                Read more
                <i class="fa-solid fa-arrow-right"></i>
            </button>
        </div>
    </div>
</div>