<div id="color-input" [class.custom-content]="content.children.length">
    <div *ngIf="title || description" [class.disabled]="disabled">
        <h4 [class]="titleClassList">{{title}}</h4>
        <p *ngIf="subtitle" class="font-weight-medium font-size-s margin-bottom-8" [innerHtml]="subtitle"></p>
        <p *ngIf="description" class="font-size-xs" [innerHtml]="description"></p>
    </div>
    <div>
        <div class="color-wrapper"
             cpAlphaChannel="disabled"
             [class.disabled]="disabled"
             [style.background]="!content.children.length ? (pendingColor$ | async) ?? (color$ | async) : 'transparent'"
             [class.dark]="isLightColor$ | async"
             [colorPicker]="color$ | async"
             [cpOKButton]="true"
             [cpOKButtonText]="'Select colour'"
             [cpOKButtonClass]="'btn small primary dark full-width margin-top-8 margin-left-none'"
             [cpSaveClickOutside]="false"
             [cpDisabled]="disabled"
             (colorPickerChange)="onColorChange($event)"
             (colorPickerSelect)="onColorSelect($event)">
            <span *ngIf="content.children.length === 0">{{(pendingColor$ | async) ?? (color$ | async)}}</span>
            <div #content class="full-width" [class.hide]="content.children.length === 0" >
                <ng-content></ng-content>
            </div>
        </div>
        <button class="btn medium transparent full-width" [disabled]="disabled" (click)="onColorReset()">
            Reset colour
        </button>
    </div>
</div>