<app-dialog id="terms-dialog"
            defaultWidth="706px"
            defaultHeight="80vh"
            [showClose]="true">
    <div header-content>
        <img src="/assets/connect/connect-logo-black.svg" class="connect-logo-image" />
    </div>

    <div body fxLayout="column">
        <div fxFlex class="overflow-scroll" id="terms-container" (scroll)="onTermsScroll()">
            <div *ngIf="terms" [innerHtml]="terms.content | safe: 'html'" class="padding-bottom-8"></div>
        </div>

        <app-loading-card *ngIf="loading$ | async" message="Loading terms..."></app-loading-card>
    </div>

    <div *ngIf="acceptanceNeeded && form"
         [form]="form"
         class="padding-top-8"
         fxLayout="row wrap"
         fxLayoutAlign="space-between center"
         footer-actions>
        <ng-container *ngIf="terms?.confirmationMessages?.length else defaultConfirmation">
            <div fxLayout="column"
                 fxLayoutGap="8px">
                <div *ngFor="let confirmationMessage of terms.confirmationMessages; let index = index;">
                    <mat-checkbox [formControl]="form.get('terms').controls[index]"
                                  [disabled]="!hasScrolledToTheBottom || (accepting$ | async)"
                                  class="small-container">
                        {{confirmationMessage.message}}
                    </mat-checkbox>
                </div>
            </div>
        </ng-container>
        <ng-template #defaultConfirmation>
            <div>
                <mat-checkbox [formControl]="form.get('terms').controls[0]"
                              [disabled]="!hasScrolledToTheBottom || (accepting$ | async)"
                              class="small-container">
                    I accept the Terms &amp; Conditions
                </mat-checkbox>
            </div>
        </ng-template>
        <div>
            <button class="btn large primary dark" [disabled]="form.invalid || (accepting$ | async)"
                    (click)="onConfirmClick()">
                Confirm
            </button>
        </div>
    </div>
</app-dialog>