import { Component, Input, OnInit } from '@angular/core';

// models
import { Client } from 'app/models/client.model';
import { environment } from 'environments/environment';

const DEFAULT_BACKGROUND_COLOR = '#00142D';
const DISABLED_BACKGROUND_COLOR = '#D3D3D3';

@Component({
    selector: 'app-client-selector-option',
    templateUrl: './client-selector-option.component.html',
    styleUrls: ['./client-selector-option.component.scss']
})
export class ClientSelectorOptionComponent implements OnInit {
    @Input()
    client: Client;

    logoUrl: string;
    initials: string;
    name: string;
    backgroundColor: string;
    textColor: string;
    disabled: boolean;

    ngOnInit(): void {
        const clientName = this.client?.name ?? '';

        const split = clientName.split(' ');
        let initials = '';
        for (const word of split) {
            if (word.length > 0) {
                initials += word[0];
            }

            if (initials.length === 3) {
                break;
            }
        }

        this.initials = initials.toLocaleUpperCase();

        const branding = this.client?.branding;
        this.logoUrl = branding.logoUrl;

        this.disabled = this.client ? this.client.locked : false;
        this.backgroundColor = this.getBackgroundColor();
        this.textColor = this.client?.branding?.primaryReverseColor ?? environment.primaryReverseColor ?? '#FFFFFF';
        this.name = clientName;
    }

    getBackgroundColor(): string {
        if(this.disabled) {
            return DISABLED_BACKGROUND_COLOR;
        }

        if(this.client?.branding?.headerColor) {
            return this.client?.branding?.headerColor;
        }

        if(environment.headerColor){
            return environment.headerColor;
        }

        return DEFAULT_BACKGROUND_COLOR;
    }
}