<div class="postcode-lookup-container" [formGroup]="postcodeForm">
    <p *ngIf="!hasAddresses" class="font-weight-medium m-bot-xxs m-top-xxs" ngClass.xs="font-size-xs" ngClass.gt-xs="font-size-s">
        <span>Search using a UK postcode, or</span>
        <button class="btn text" (click)="onManualEntryClick()">enter manually</button>
    </p>
    <div *ngIf="!hasAddresses" fxLayout="row" fxLayoutGap="16px">
        <mat-form-field appearance="outline" class="postcode-field">
            <input data-testid="search-using-a-postcode" matInput class="postcode" autocomplete="off" formControlName="postcode" placeholder="Search using a postcode" (keydown.enter)="onSearchClicked()" data-private />
            <mat-error *ngIf="postcodeForm.get('postcode').hasError('pattern')">Postcode does not match required UK format</mat-error>
            <mat-error *ngIf="postcodeForm.get('postcode').hasError('required')">Postcode is required</mat-error>

            <mat-error *ngIf="lookupError.length > 0">
                {{lookupError}}
            </mat-error>
        </mat-form-field>
        <button class="btn medium primary light search-button" [disabled]="postcodeForm?.invalid" (click)="onSearchClicked()">Search</button>
    </div>
    <div *ngIf="hasAddresses" fxLayout="row" fxLayoutAlign="start" fxLayoutGap="16px">
        <p class="m-top-none font-weight-medium m-bot-xxs m-top-xxs" ngClass.xs="font-size-xs" ngClass.gt-xs="font-size-s" fxLayout="row" fxLayoutAlign="start center">
            <span>{{addresses.length}} address{{addresses.length !== 1 ? 'es' : ''}} found for <strong data-private>{{postcodeForm.value.postcode | uppercase}}</strong>&nbsp;</span>
            <button (click)="onChangePostcodeClick();" class="btn text">change</button>
        </p>
    </div>
    <div *ngIf="hasAddresses">
        <ng-container *ngIf="addresses?.length > 0">
            <div class="text-gray-8 question-title">Select the address</div>
            <mat-form-field appearance="outline">
                <mat-select #address (selectionChange)="onAddressSelected(address.value)" data-private data-testid="select-the-address">
                    <mat-option *ngFor="let address of addresses" [value]="address" data-private>{{formatAddress(address)}}</mat-option>
                </mat-select>
            </mat-form-field>
        </ng-container>
        <p class="font-weight-medium m-bot-xxs m-top-xxs" ngClass.xs="font-size-xs" ngClass.gt-xs="font-size-s">
            <span *ngIf="addresses?.length > 0">I can't find my address in the list,&nbsp;</span>
            <span *ngIf="!addresses?.length">Or&nbsp;</span>
            <button class="btn text" (click)="onManualEntryClick()">enter manually</button>
        </p>
    </div>
</div>