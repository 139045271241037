import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isTemplateValid } from 'app/shared/services/handlebars.service';
import { valueToString } from 'app/shared/utilities/string-utilities';

function handlebarsValidator(): ValidatorFn {
    return function(control: AbstractControl): ValidationErrors | null {
        const value = ` ${valueToString(control?.value, '')} `;

        const incomplete = /[^{]{{[a-zA-Z][a-zA-Z0-9]*}}}/.test(value) ||
            /[^{]{[a-zA-Z][a-zA-Z0-9]*}}/.test(value) ||
            /{{{[a-zA-Z][a-zA-Z0-9]*}}[^}]/.test(value) ||
            /{{[a-zA-Z][a-zA-Z0-9]*}[^}]/.test(value) ||
            /[^{]{[a-zA-Z][a-zA-Z0-9]*}[^}]/.test(value);

        if (incomplete) {
            return { pattern: true };
        }

        if (!isTemplateValid(value)) {
            return { pattern: true };
        }

        return null;
    };
}

function patternValidator(pattern: string | RegExp, message?: string, name?: string): ValidatorFn {
    return function(control: AbstractControl): ValidationErrors | null {
        const value = valueToString(control?.value, '');

        if (value === '' || new RegExp(pattern).test(value)) {
            return null;
        }

        name = name || 'pattern';
        message = message || 'Invalid format';

        const result: { [key: string]: string } = {};

        result[name] = message;

        return result;
    };
}

function customValidator(isValidPredicate: (value: any) => boolean, message?: string, name?: string): ValidatorFn {
    return function(control: AbstractControl): ValidationErrors | null {
        const value = control?.value;

        const isValid = isValidPredicate(value);
        if (isValid) {
            return null;
        }

        name = name || 'custom';
        message = message || 'Invalid value';

        const result: { [key: string]: string } = {};

        result[name] = message;

        return result;
    };
}

function specificValueValidator(allowedValues: any[], message?: string, name?: string): ValidatorFn {
    return function(control: AbstractControl): ValidationErrors | null {
        const value = control?.value;

        const isValid = allowedValues.includes(value);
        if (isValid) {
            return null;
        }

        name = name || 'specificValue';
        message = message || 'Invalid value';

        const result: { [key: string]: string } = {};

        result[name] = message;

        return result;
    };
}

function notEqualValidator(restrictedValues: any[], message?: string, name?: string): ValidatorFn {
    return function(control: AbstractControl): ValidationErrors | null {
        const value = control?.value;

        const isValid = !restrictedValues.includes(value);
        if (isValid) {
            return null;
        }

        name = name || 'notEqualValue';
        message = message || 'Invalid value';

        const result: { [key: string]: string } = {};

        result[name] = message;

        return result;
    };
}

export const WEB_URL_PATTERN_HTTPS = '^https?://[^\\.]+\\..+';
export const WEB_URL_PATTERN = '^http[s]?://[^\\.]+\\..+';
export const TRUE_FALSE_PATTERN = '^(true|false)$';
export const IMAGE_URL_PATTERN = '^https?://[^\\.]+\\.[^/]+/.+\\.(png|jpe?g|gif)(\\?.*)?$';
export const DATE_FORMAT_PATTERN = '^(0[1-9]|[12][0-9]|3[01])/(0[1-9]|1[0-2])/\\d{4}$';

export class ConnectValidators {
    static handlebars(): ValidatorFn {
        return handlebarsValidator();
    }

    static webUrl(includeHttps: boolean = true): ValidatorFn {
        const invalidMessage = 'Has to be web URL';
        if(includeHttps){
            return patternValidator(WEB_URL_PATTERN_HTTPS, invalidMessage);
        }

        return patternValidator(WEB_URL_PATTERN, invalidMessage);
    }

    static trueFalse(): ValidatorFn {
        return patternValidator(TRUE_FALSE_PATTERN, `Has to be either 'true' or 'false'`);
    }

    static imageUrl(): ValidatorFn {
        return patternValidator(IMAGE_URL_PATTERN, `Has to be URL to an image`);
    }

    static dateFormat(): ValidatorFn {
        return patternValidator(DATE_FORMAT_PATTERN, `Please enter the date in the format DD/MM/YYYY`);
    }

    static custom(isValidPredicate: (value: any) => boolean, message?: string, name?: string): ValidatorFn {
        return customValidator(isValidPredicate, message, name);
    }

    static specificValue(allowedValues: any[], message?: string, name?: string): ValidatorFn {
        return specificValueValidator(allowedValues, message, name);
    }

    static notEqualValue(allowedValues: any[], message?: string, name?: string): ValidatorFn {
        return notEqualValidator(allowedValues, message, name);
    }
}