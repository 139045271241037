import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

// ngrx | rxjs
import { Store, select, ActionsSubject } from '@ngrx/store';
import { map, takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ofType } from '@ngrx/effects';

// store
import * as fromAuth from 'app/authentication-v2/store';

// components
import { BaseComponent } from 'app/shared/base/base-component';
import { ValueChangedEvent } from 'app/connect/components/code-entry/code-entry.component';

// services
import { NavigationService } from 'app/shared/services/navigation.service';

// models
import { RegExRules } from 'app/shared/models';

// utils
import { getBoolean } from 'app/shared/utilities/boolean-utilities';

// enums
import { ClientUserRequiredAction } from 'app/shared/enums/client-user-required-action.enum';

@Component({
    templateUrl: 'set-pin.component.html'
})
export class SetPinComponent extends BaseComponent implements OnInit {
    RegExRules = new RegExRules();

    working$: Observable<boolean>;
    form: FormGroup;
    showLoginLink$: Observable<boolean>;
    resetPassword = false;
    isReset = false;

    constructor(
        private store: Store<fromAuth.AuthenticationState>,
        private fb: FormBuilder,
        public navigation: NavigationService,
        private route: ActivatedRoute,
        private actionsSubject: ActionsSubject) {
        super();
    }

    get title(): string {
        return this.isReset ? 'Enter your PIN' : 'Set a PIN';
    }

    ngOnInit(): void {
        this.showLoginLink$ = this.route.queryParams
            .pipe(
                takeUntil(this.ngUnsubscribe),
                map(query => !getBoolean(query?.existingUser, false)));

        this.form = this.fb.group({
            token: [null, []],
            pin: [null, [Validators.required, Validators.pattern(this.RegExRules.pin)]]
        });

        this.route.queryParams
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((query) => {
                if (query.token) {
                    this.form.get('token').setValue(query.token);
                }

                if (query.reset?.toLowerCase() === 'true') {
                    this.isReset = true;
                }
            });

        this.store
            .pipe(
                takeUntil(this.ngUnsubscribe),
                select(fromAuth.getResetPassword)
            )
            .subscribe(resetPassword => this.resetPassword = resetPassword);

        this.working$ = this.store.pipe(
            takeUntil(this.ngUnsubscribe),
            select(fromAuth.getAuthenticationWorking));

        this.actionsSubject
            .pipe(
                takeUntil(this.ngUnsubscribe),
                ofType(fromAuth.SetPinSuccess)
            )
            .subscribe((action) => {
                if (this.resetPassword) {
                    this.store.dispatch(fromAuth.RequestPasswordReset({ resetPin: false }));
                } else {
                    if (action.user.requiredAction !== ClientUserRequiredAction.None) {
                        this.navigation.gotoRequiredAction(action.user.requiredAction);
                    }

                    if (!action.user.isRestrictedAccess) {
                        this.store.dispatch(fromAuth.LoginSuccess({ user: action.user }));
                    }
                }
            });

        this.actionsSubject
            .pipe(
                takeUntil(this.ngUnsubscribe),
                ofType(fromAuth.RequestPasswordResetSuccess)
            )
            .subscribe((action) => this.navigation.gotoSetPassword(action.request.passwordResetToken, action.request.emailAddress, true));
    }

    setPinClick() {
        if (this.form.invalid) {
            return;
        }

        const form = this.form.value;

        const pin = form.pin?.trim();
        const token = form.token?.trim();

        this.store.dispatch(fromAuth.SetPin({ pin, token }));
    }

    onValueChanged(event: ValueChangedEvent): void {
        this.form.get('pin').setValue(event.code);
    }
}