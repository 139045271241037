<app-dialog dialogTitle="Confirmation Required" [defaultWidth]="defaultWidth" [defaultHeight]="defaultHeight">
    <div body>
        <p *ngIf="confirmationMessage" [innerHTML]="confirmationMessage"></p>
        <p *ngIf="!confirmationMessage">Are you sure?</p>
    </div>

    <div footer-actions>
        <button class="btn large primary light" (click)="cancelled()">{{cancelText}}</button>
        <button class="btn large primary dark"  (click)="confirmed()">{{confirmText}}</button>
    </div>
</app-dialog>
