export enum ModuleStatus {
    /// <summary>
    /// The module is disabled and cannot be seen or added by the
    /// organisation.
    /// </summary>
    Disabled = 0,

    /// <summary>
    /// The module request has been approved and the module is now active.
    /// </summary>
    Enabled = 2,
}

export function getModuleStatusLabel(moduleStatus: ModuleStatus): string {

    switch (moduleStatus) {
        case ModuleStatus.Disabled:
            return 'Disabled';
        case ModuleStatus.Enabled:
            return 'Enabled';
        default: return '';
    }
}
