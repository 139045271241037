import { Action, createReducer, on } from '@ngrx/store';

// store
import * as actions from 'app/connect/store/actions';

// models
import { UserNotification } from 'app/connect/models/user-notification.model';

export interface NotificationState {
    notifications: UserNotification[];
    highlightedNotification: UserNotification;
    loading: boolean;
}

const initialState: NotificationState = {
    notifications: [],
    highlightedNotification: null,
    loading: false,
};

const notificationReducer = createReducer(
    initialState,
    on(actions.GetNotifications, (state) => ({
        ...state,
        notifications: [],
        loading: true,
    })),
    on(actions.GetNotificationsSuccess, (state, action) => ({
        ...state,
        loading: false,
        notifications: action.notifications,
    })),
    on(actions.GetNotificationsFail, (state) => ({
        ...state,
        loading: false,
    })),
    on(actions.ReadNotificationSuccess, (state, action) => ({
        ...state,
        notifications: state.notifications.map((notification) =>
            notification.id === action.notification.id
                ? { ...notification, read: true }
                : notification
        ),
    })),
    on(actions.DismissNotificationSuccess, (state, action) => ({
        ...state,
        notifications: state.notifications.filter(
            (notification) => notification.id !== action.notification.id
        ),
    })),
    on(actions.ReceiveNotification, (state, action) => ({
        ...state,
        notifications: [action.notification, ...state.notifications],
    })),
    on(actions.SetHighlightedNotification, (state, action) => ({
        ...state,
        highlightedNotification: action.notification
    })),
    on(actions.ClearHighlightedNotification, (state) => ({
        ...state,
        highlightedNotification: null
    }))
);

export function reducer(state: NotificationState, action: Action) {
    return notificationReducer(state, action);
}
