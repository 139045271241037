import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControlOptions } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

// ngrx/rxjs
import { Store, select, ActionsSubject } from '@ngrx/store';
import { ofType } from '@ngrx/effects';
import { takeUntil, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

// Store
import * as fromAuth from 'app/authentication-v2/store';
import * as fromConnect from 'app/connect/store';

// Components
import { BaseComponent } from 'app/shared/base/base-component';

// Services
import { ValidationService } from 'app/shared/services';
import { NavigationService } from 'app/shared/services/navigation.service';

// Models
import { PasswordValidation } from 'app/authentication-v2/models';
import { User } from 'app/shared/models/user.model';

// utils
import { RegexPatterns } from 'app/shared/utilities/regex-utilities';

@Component({
    templateUrl: 'reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent extends BaseComponent implements OnInit {
    working$: Observable<boolean>;

    form: FormGroup;

    email: string;
    token: string;
    name: string;
    firstLogin: boolean = false;
    invitation: boolean = false;

    hidePassword: boolean = true;
    hideConfirmPassword: boolean = true;
    loggedIn: boolean = false;
    title = 'Set Your Password';
    tempPasswordReset: boolean = false;
    resetPin = false;
    isReset = false;
    showForm = false;

    constructor(
        private store: Store<fromAuth.AuthenticationState>,
        private route: ActivatedRoute,
        private fb: FormBuilder,
        public validationService: ValidationService,
        private connectStore: Store<fromConnect.ConnectStoreState>,
        private actionsSubject: ActionsSubject,
        public navigation: NavigationService
    ) {
        super();
    }

    ngOnInit(): void {
        this.working$ = this.store.pipe(select(fromAuth.getAuthenticationWorking));

        this.route.url.pipe(
            takeUntil(this.ngUnsubscribe),
            map(urlSegments => {
                if (urlSegments.length === 1 && urlSegments[0].path === 'set-password') {
                    this.invitation = true;
                }

                this.buildForm();

                this.checkParameters();

            }))
            .subscribe();

        this.store.pipe(
            takeUntil(this.ngUnsubscribe),
            select(fromAuth.getResetPasswordOnLogin))
            .subscribe(resetPasswordDetails => {
                if (resetPasswordDetails) {
                    this.email = resetPasswordDetails.email;
                    this.token = resetPasswordDetails.token;
                    this.setEmail(this.email);
                    this.setToken(this.token);
                    this.tempPasswordReset = true;
                } else {
                    this.tempPasswordReset = false;
                }
            });

        this.connectStore.pipe(
            takeUntil(this.ngUnsubscribe),
            select(fromConnect.getUser))
            .subscribe((user: User) => this.loggedIn = user !== null);


        this.actionsSubject.pipe(
            takeUntil(this.ngUnsubscribe),
            ofType(fromAuth.SetPasswordSuccess))
            .subscribe((action) => {
                this.title = 'Password reset successfully';

                if (this.resetPin) {
                    this.store.dispatch(fromAuth.RequestPinReset({ resetPassword: false }));
                } else {
                    if (this.invitation || action.user.termsAcceptanceNeeded) {
                        this.store.dispatch(fromAuth.TermsAcceptanceNeeded({ user: action.user }));
                    } else {
                        this.store.dispatch(fromAuth.LoginSuccess({ user: action.user }));
                    }
                }
            });

        this.store
            .pipe(
                takeUntil(this.ngUnsubscribe),
                select(fromAuth.getResetPin)
            )
            .subscribe(resetPin => this.resetPin = resetPin);

        this.actionsSubject
            .pipe(
                takeUntil(this.ngUnsubscribe),
                ofType(fromAuth.RequestPinResetSuccess)
            )
            .subscribe((action) => this.navigation.gotoSetPin(true, action.request.pinResetToken));
    }

    setToken(token: string) {
        this.form.get('token').setValue(token);
    }

    setEmail(email: string) {
        this.form.get('email').setValue(email);
    }

    resetPassword() {
        this.store.dispatch(fromAuth.SetPassword({request: this.form.value}));
    }

    private checkParameters(): void {
        this.route.queryParams.pipe(
            takeUntil(this.ngUnsubscribe),
            map(params => {
                if (params.token) {
                    this.token = decodeURIComponent(params.token);
                }

                if (params.email) {
                    this.email = decodeURIComponent(params.email);
                }

                if (params.name) {
                    this.name = decodeURIComponent(params.name);
                }

                if (params.invitation) {
                    this.invitation = true;
                }

                if (params.showForm) {
                    this.showForm = true;
                }

                if (params.reset?.toLowerCase() === 'true') {
                    this.isReset = true;
                }

                if (this.email) {
                    this.setEmail(this.email);
                }

                if (this.token) {
                    this.setToken(params.token);
                }

                this.title = this.invitation ? `Welcome ${this.name ?? ''}` :
                    (this.isReset ? 'Reset my password' : 'Set Your Password');
            }))
            .subscribe();
    }

    private buildForm() {
        this.form = this.fb.group({
            token: [''],
            email: ['', [Validators.required]],
            password: ['', [Validators.required, Validators.pattern(RegexPatterns.Password), Validators.minLength(9)]],
            confirmPassword: ['', [Validators.required, Validators.pattern(RegexPatterns.Password), Validators.minLength(9)]],
        },
        <AbstractControlOptions>{
                validators: PasswordValidation.matchPassword
            });
    }
}