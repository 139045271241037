import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// rxjs
import { Observable } from 'rxjs';

// models
import { TermsDetails } from 'app/authentication-v2/models/terms-details.model';

@Injectable()
export class TermsService {

    private baseEndpoint = 'api/v2/terms';

    constructor(private http: HttpClient) { }

    getCurrentTerms$(): Observable<TermsDetails> {
        const url = `${this.baseEndpoint}/current-client`;
        return this.http.get<TermsDetails>(url);
    }
}
