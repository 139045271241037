import { Component, Input, OnInit } from '@angular/core';

import { BaseComponent } from 'app/shared/base/base-component';

@Component({
    selector: 'app-accordion',
    templateUrl: './accordion.component.html',
    styleUrls: ['./accordion.component.scss']
})

export class AccordionComponent extends BaseComponent implements OnInit {
    @Input() title: string;

    @Input() collapsed: boolean;

    constructor() {
        super();
    }

    ngOnInit() {}
}