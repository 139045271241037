import {
    Directive,
    HostBinding,
    HostListener,
} from '@angular/core';

@Directive({
    selector: '[appTrackTransition]',
})
export class TrackTransitionDirective {
    transitioning: boolean;

    @HostBinding('attr.data-transitioning') get applyTransitioning() {
        return this.transitioning ? true : null;
    }

    @HostListener('transitionstart') onTransitionStart() {
        this.transitioning = true;
    }

    @HostListener('transitionend') onTransitionEnd() {
        this.transitioning = false;
    }
}
