import { Pipe, PipeTransform } from '@angular/core';

// models
import { EnumValueTitle } from 'app/shared/models/enum-value-title.model';

@Pipe({
    name: 'enumTransformer',
})
export class EnumTransformerPipe implements PipeTransform {
    transform(
        value: any,
        transformer: ((value, ...args) => string) | EnumValueTitle<any>[],
        ...args: any[]
    ): string {
        if (!value || !transformer) {
            return '';
        }

        if (transformer instanceof Array) {
            return (
                transformer.find(
                    (enumValueTitle) => enumValueTitle.value === value
                )?.title ?? ''
            );
        }

        return transformer(value, ...args);
    }
}
