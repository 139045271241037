<app-authentication-layout id="forgot-password-container" fxFlex pageTitle="Recover your password">
    <form name="form" [formGroup]="form" novalidate *ngIf="!emailSent" fxLayout="column">
        <div>
            <mat-form-field appearance="outline">
                <input placeholder="Email address"  matInput formControlName="email" type="email">
                <mat-error *ngIf="form.get('email').hasError('required')">
                    Email is required
                </mat-error>
                <mat-error *ngIf="!form.get('email').hasError('required') &&
                                form.get('email').hasError('email')">
                    Please enter a valid email address
                </mat-error>
            </mat-form-field>
        </div>
        <button *ngIf="!emailSent" class="btn large primary dark" (click)="sendEmail(form)"
            aria-label="Send Reset Link" [disabled]="form.invalid">
            Send reset link
        </button>

        <div [routerLink]="['/auth-v2', 'login']" class="margin-top-24 link">
            <mat-icon>chevron_left</mat-icon>
            <div>Back to login</div>
        </div>

    </form>

    <div *ngIf="emailSent" id="email-sent" class="help-container" fxLayout="column" fxLayoutGap="24px" fxLayout="center start">
        <h3>An email has been sent with instructions on how to reset your password.</h3>
        <div [routerLink]="['/auth-v2', 'login']" class="link">
            <mat-icon>chevron_left</mat-icon>
            <div>Back to login</div>
        </div>
    </div>
</app-authentication-layout>