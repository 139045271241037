import { createSelector, createFeatureSelector } from '@ngrx/store';

import * as fromFeature from 'app/portal/modules/process-list/store/reducers';
import * as fromProcessExport from 'app/portal/modules/process-list/store/reducers/process-export.reducers';

// models
import { StoreNames } from 'app/connect/models/store-names.model';

const getProcessesState = createFeatureSelector<fromFeature.ProcessListState>(StoreNames.processList);
const getProcessExportState = createSelector(getProcessesState, (state: fromFeature.ProcessListState) => state.ProcessExportState);

export const getProcessExportDataLoading = createSelector(getProcessExportState, (state: fromProcessExport.ProcessExportState) => state.loadingData);
export const getProcessExportData = createSelector(getProcessExportState, (state: fromProcessExport.ProcessExportState) => state.processExportData);
export const getCreatingExport = createSelector(getProcessExportState, (state: fromProcessExport.ProcessExportState) => state.creatingExport);
export const getMarkingExportAsDownloaded = createSelector(getProcessExportState, (state: fromProcessExport.ProcessExportState) => state.markingAsDownloaded);
export const getExportingProcessPdf = createSelector(getProcessExportState, (state: fromProcessExport.ProcessExportState) => state.exportingProcessPdf);
