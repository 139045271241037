import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

// rxjs
import { Observable } from 'rxjs';

// models
import { JourneySummary } from 'app/portal/modules/process-list/models/journey-summary.model';

@Injectable()
export class JourneyService {

    private baseUrl = 'api/v2/journeys';

    constructor(private http: HttpClient) { }

    getJourneys$(): Observable<JourneySummary[]> {
        return this.http.get<JourneySummary[]>(this.baseUrl);
    }

    getActiveJourneys$(): Observable<JourneySummary[]> {
        return this.http.get<JourneySummary[]>(`${this.baseUrl}?activeOnly=true`);
    }
}
