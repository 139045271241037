export class ValidationMessage {
    key: string;
    required: string;
    minlength: string;
    maxlength: string;
    pattern: string;
}

export class ValidationMessages {

    public validationMessages: ValidationMessage[] = [
        {
            key: 'title',
            required: 'Title is required.',
            minlength: '',
            maxlength: '',
            pattern: ''
        }, {
            key: 'firstname',
            required: 'First name is required.',
            minlength: 'First name must be at least 2 characters long.',
            maxlength: 'First name cannot be more than 60 characters long.',
            pattern: 'First name cannot include any numbers or special characters.'
        }, {
            key: 'surname',
            required: 'Surname is required.',
            minlength: 'Surname must be at least 2 characters long.',
            maxlength: 'Surname cannot be more than 60 characters long.',
            pattern: 'Surname cannot include any numbers or special characters.'
        }, {
            key: 'name',
            required: 'Name is required.',
            minlength: 'Name must be at least 2 characters long.',
            maxlength: '',
            pattern: 'Name cannot include any numbers or special characters.'
        }, {
            key: 'email',
            required: 'Email is required.',
            minlength: 'Email must be at least 3 characters long.',
            maxlength: 'Email cannot be more than 255 characters long.',
            pattern: 'Please enter a valid email.'
        }, {
            key: 'password',
            required: 'Password is required.',
            minlength: 'Password must be at least 9 characters long.',
            maxlength: '',
            pattern: 'Password must be at least 9 characters long contain uppercase and lowercase letters and at least one special character.'
        }, {
            key: 'confirmPassword',
            required: 'Password is required.',
            minlength: 'Password must be at least 9 characters long.',
            maxlength: '',
            pattern: 'Password must contain uppercase and lowercase letters and at least one special character.'
        }, {
            key: 'phoneNumber',
            required: 'Phone number is required.',
            minlength: 'Phone number must be at least 10 digits with no spaces.',
            maxlength: 'Phone number must be at most 11 digits with no spaces.',
            pattern: 'Phone number can only include numbers and must be either 10 or 11 digits long.',
        }, {
            key: 'mobile',
            required: 'Mobile number is required.',
            minlength: 'Mobile number must be at least 11 digits.',
            maxlength: 'Mobile number must be no greater than 11 digits with no spaces.',
            pattern: 'Please enter a valid mobile number',
        }, {
            key: 'emergencyContactNumber',
            required: 'Emergency contact number is required.',
            minlength: 'Emergency contact number must be at least 10 digits.',
            maxlength: '',
            pattern: 'Please enter a valid phone number',
        }, {
            key: 'registrationType',
            required: 'Registration type is required.',
            minlength: '',
            maxlength: '',
            pattern: ''
        }, {
            key: 'reference',
            required: 'Reference is required.',
            minlength: '',
            maxlength: '',
            pattern: ''
        }
    ];
}
