export function deserializeJson<T>(jsonText: string): T {
    let value: T = null;

    if (!jsonText) {
        return value;
    }

    try {
        value = JSON.parse(jsonText) as T;
    } catch (e) {
        // ignore
    }

    return value;
}
