import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

// rxjs | ngrx
import { ActionsSubject, select, Store } from '@ngrx/store';
import { Observable, takeUntil } from 'rxjs';
import { ofType } from '@ngrx/effects';

// store
import * as fromAuth from 'app/authentication-v2/store';


// Components
import { BaseComponent } from 'app/shared/base/base-component';

// Services
import { NavigationService } from 'app/shared/services/navigation.service';
import { AlertService } from 'app/shared/components/alert/services/alert.service';

// enums
import { ContactType } from 'app/authentication-v2/enumerations/contact-type.enum';
import { OtpReason } from 'app/shared/enums/otp-reason.enum';

@Component({
    templateUrl: 'reset-security.component.html'
})
export class ResetSecurityComponent extends BaseComponent implements OnInit {
    form: FormGroup;

    working$: Observable<boolean>;
    hidePassword: boolean = true;

    constructor(
        private store: Store<fromAuth.AuthenticationState>,
        private fb: FormBuilder,
        public navigation: NavigationService,
        private actionsSubject: ActionsSubject,
        private alertService: AlertService) {
        super();
    }

    ngOnInit(): void {
        this.working$ = this.store.pipe(
            takeUntil(this.ngUnsubscribe),
            select(fromAuth.getAuthenticationWorking));

        this.actionsSubject
            .pipe(
                takeUntil(this.ngUnsubscribe),
                ofType(fromAuth.TriggerSecurityResetSuccess)
            )
            .subscribe((action) => {
                if (action.notFound) {
                    this.alertService.warning('We could not find account with that email address.');
                } else {
                    this.navigation.gotoOtp(action.confirmationId, ContactType.Email, OtpReason.SecurityResetEmailAddressConfirmation);
                }
            });

        this.form = this.fb.group({
            email: ['', [Validators.required, Validators.email]],
        });
    }

    onSubmitClick() {
        if (this.form.invalid) {
            return;
        }

        this.store.dispatch(fromAuth.TriggerSecurityReset({
            credential: this.form.get('email').value
        }));
    }
}