import { Component, Input, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";

// ngrx | rxjs
import { Store, select } from "@ngrx/store";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";

// store
import * as fromStore from "app/authentication-v2/store";

@Component({
    selector: "app-authenticator-app-setup",
    templateUrl: "authenticator-app-setup.component.html",
    styleUrls: ["authenticator-app-setup.component.scss"],
})
export class AuthenticatorAppSetupComponent
    implements OnInit {
    @Input({ required: true })
    showConfirmButton: boolean;

    form: FormGroup;
    showManualConnection: boolean;
    working$: Observable<boolean>;
    manualEntryKey$: Observable<string>;
    qrCodeBase64$: Observable<string>;

    constructor(
        private formBuilder: FormBuilder,
        private store: Store<fromStore.AuthenticationState>
    ) {
    }

    ngOnInit() {
        this.store.dispatch(fromStore.GenerateAuthenticatorSetup());

        this.form = this.formBuilder.group({
            code: ["", Validators.required],
        });

        this.working$ = this.store.pipe(
            select(fromStore.getAuthenticationWorking)
        );

        this.manualEntryKey$ = this.store
            .select(fromStore.getAuthenticatorSetup)
            .pipe(map((data) => data?.manualEntryKey));

        this.qrCodeBase64$ = this.store
            .select(fromStore.getAuthenticatorSetup)
            .pipe(map((data) => data?.qrCodeBase64));
    }

    toggleManualConnection() {
        this.showManualConnection = !this.showManualConnection;
    }

    onConfirm() {
        if (this.form.invalid) {
            return;
        }

        this.store.dispatch(
            fromStore.ConfirmAuthenticatorSetup({
                code: this.form.get("code").value,
            })
        );
    }
}
