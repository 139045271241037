<div [formGroup]="form" fxLayout="row" fxLayoutAlign="space-between center" class="code-entry-container">
    <ng-container *ngFor="let field of fields">
        <span *ngIf="showSpacer(field.index)" class="spacer"></span>
        <mat-form-field class="code-field" appearance="outline" [attr.data-codeindex]="field.index">
            <input matInput [formControl]="form.get('code' + field.index)"
                   autocomplete="nope"
                   type="{{ asPassword ? 'password' : 'text' }}"
                   (focus)="onFocus($event, field)"
                   (keydown)="onKeyPressed($event, field)"
                   (input)="onTextInput($event, field)"
                   maxlength="1"
                   (paste)="onPaste($event)"
                   (drop)="onDrop($event)"
                   [attr.data-testid]="'code' + field.index">
        </mat-form-field>
    </ng-container>
</div>
<div *ngIf="showError" class="mat-error font-size-s">The code must contain only <ng-container *ngIf="!numericOnly">letters and</ng-container> digits</div>
