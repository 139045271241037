<app-authentication-layout id="mobile-number-entry" fxFlex pageTitle="Enter mobile number" data-auto="mobile-number-entry">
    <app-loading-card *ngIf="(working$ | async)"></app-loading-card>
    <div [formGroup]="form" fxLayout="column" (keydown.enter)="onEnterPressed()">
        <p class="font-size-s">
            Please enter your mobile number below.<br />
            <br />
            We use this in case you ever need to recover your account.
        </p>
        <div fxLayout="column">
            <div class="margin-bottom-10">
                <app-mobile-phone-entry [formGroup]="form" formGroupName="mobile" [isRequired]="true"></app-mobile-phone-entry>
            </div>

            <button class="btn large primary dark"
                    [disabled]="(working$ | async) || form.invalid"
                    (click)="onEnterPressed()" data-auto="submit-button">
                Submit contact details
            </button>
            <div *ngIf="(showLoginLink$ | async)" class="margin-top-24 font-size-xs">
                Already have an account?
                <a (click)="navigation.gotoLogin()" class="text-link font-size-inherit" data-auto="have-account-button">
                    Click here
                </a>
            </div>
        </div>
    </div>
</app-authentication-layout> 