import { Component, Input, EventEmitter, Output, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { HostListener } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

// types
import { DialogState } from 'app/shared/types/dialog-state.type';

@Component({
    selector: 'app-dialog',
    templateUrl: './dialog.component.html',
})
export class DialogComponent implements OnChanges, OnInit {

    @Input()
    dialogTitle: string;

    @Input()
    dialogTitleIcon: string;

    @Input()
    dialogSubTitle: string;

    @Input()
    showFooterActions: boolean = true;

    @Input()
    showClose: boolean = false;

    @Input()
    showBack: boolean = false;

    @Input()
    showHeader: boolean = true;

    @Input()
    showHeaderShadow: boolean = true;

    @Input()
    handleCloseEvent: boolean = false;

    @Input()
    heading: string;

    @Input()
    caption: string;

    @Input()
    description: string;

    @Input()
    defaultWidth: string = 'auto';

    @Input()
    defaultHeight: string = 'auto';

    @Input()
    centerAlign: boolean = false;

    @Input()
    position: string;

    @Input()
    colorClass: string = null;

    @Input()
    contentPadding: string = '16px 32px;';

    @Input()
    footerPadding: string = '16px 32px;';

    @Input()
    noSubHeaderPadding: boolean = false;

    @Input()
    darkTheme: boolean = false;

    @Input()
    fullScreen: boolean = false;

    @Input()
    useClientBranding: boolean = false;

    @Input()
    state: DialogState = 'default';

    @Input() 
    infiniteScrollDisabled: boolean = true;

    @Output()
    Close: EventEmitter<void> = new EventEmitter();

    @Output()
    Back: EventEmitter<void> = new EventEmitter();

    @Output()
    contentScroll = new EventEmitter();

    private heightToUse: string;
    private widthToUse: string;

    screenHeight: string;
    screenWidth: string;

    constructor(private dialogRef: MatDialogRef<any>) {
        setTimeout(() => this.onResize(), 1);
    }

    /**
     * Creates a list of classes and sets against the class attribute.
     * Previously used a combination of class and ngClass to set classes directly in template
     * however some classes were not being honoured during view refreshes
     */
    get dialogClassList() {
        let classList = `dialog-container text `;

        const addClass = (className: string) => (classList += `${className} `);

        if(this.position) {
            addClass(this.position);
        }

        if(this.colorClass) {
            addClass(this.colorClass);
        }

        if(this.fullScreen) {
            addClass('dialog-border-none');
        }

        if(this.darkTheme && this.state === 'default') {
            addClass('dialog-dark-theme');
        }

        if(this.centerAlign) {
            addClass('center-align');
        }

        return classList;
    }

    ngOnChanges(changes: SimpleChanges) {
        if(changes.fullScreen?.currentValue) {
            this.toggleFullScreen(changes.fullScreen.currentValue);
        }

        if (changes?.defaultHeight?.currentValue) {
            this.onResize();
        }
    }

    toggleFullScreen(fullScreen: boolean) {
        const dialogFullScreenClassName = 'dialog-full-screen';
        if(fullScreen) {
            this.dialogRef.addPanelClass(dialogFullScreenClassName);
        } else {
            this.dialogRef.removePanelClass(dialogFullScreenClassName);
        }
    }

    ngOnInit(): void {
        setTimeout(() => this.onResize(), 1);
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        if(this.fullScreen) {
            return;
        }

        this.screenHeight = `${window.innerHeight}px`;
        this.screenWidth = `${window.innerWidth}px`;

        if (this.defaultWidth.indexOf('px') && this.defaultHeight.indexOf('px')) {
            if (window.innerHeight <= parseInt(this.defaultHeight, 10)) {
                this.heightToUse = this.screenHeight;
            } else {
                this.heightToUse = this.defaultHeight;
            }

            if (window.innerWidth <= parseInt(this.defaultWidth, 10)) {
                this.widthToUse = this.screenWidth;
            } else {
                this.widthToUse = this.defaultWidth;
            }
        } else {
            this.heightToUse = this.defaultHeight;
            this.widthToUse = this.defaultWidth;
        }

        this.dialogRef.updateSize(this.widthToUse, this.heightToUse);
    }

    onCloseClick(): void {
        if (this.handleCloseEvent) {
            this.Close.emit();
        } else {
            this.dialogRef.close();
        }
    }

    onContentScroll(){
        this.contentScroll.emit();
    }
}