import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

// Models
import { Alert } from 'app/shared/components/alert/models/alert.model';

// Shared Components
import { AlertComponent } from 'app/shared/components/alert/alert.component';
import { AlertType } from 'app/shared/components/alert/enums/alert-type.enum';

@Injectable()
export class AlertService {

    constructor(private dialog: MatDialog) { }

    // Display 'Success' dialog notification
    public success(message: string): MatDialogRef<AlertComponent> {
        const notification = new Alert(message, AlertType.Success);

        return this.displayAlert(notification);
    }

    // Display 'Information' dialog notification
    public info(message: string): void {
        const notification = new Alert(message, AlertType.Info);

        this.displayAlert(notification);
    }

    // Display 'Error' dialog notification
    public error(message: string): void {
        const notification = new Alert(message, AlertType.Error);

        this.displayAlert(notification);
    }

    // Display 'Warning' dialog notification
    public warning(message: string): void {
        const notification = new Alert(message, AlertType.Warning);

        this.displayAlert(notification);
    }

    private displayAlert(notification: Alert): MatDialogRef<AlertComponent> {
        if (notification &&
            notification.message &&
            notification.message.length > 0 &&
            notification.type !== undefined) {

            // if we already alert dialogs open, close them
            if (this.dialog.openDialogs && this.dialog.openDialogs.length) {
                const instances = this.dialog.openDialogs.filter(op => op.componentInstance instanceof AlertComponent);

                for (const alertDlg of instances) {
                    alertDlg.close();
                }
            }

            return this.dialog.open(AlertComponent, {
                data: notification,
                disableClose: false,
            });
        }
    }
}