// ngrx
import { createFeatureSelector, createSelector } from '@ngrx/store';

// store
import * as reducers from 'app/connect/store/reducers';

// models
import { StoreNames } from 'app/connect/models/store-names.model';

const storeState = createFeatureSelector<reducers.ConnectStoreState>(
    StoreNames.connectCommon
);

const reducerState = createSelector(
    storeState,
    (state: reducers.ConnectStoreState) => state.notification
);

export const getNotifications = createSelector(
    reducerState,
    (state: reducers.NotificationState) => state.notifications
);

export const getHighlightedNotification = createSelector(
    reducerState,
    (state: reducers.NotificationState) => state.highlightedNotification
);

export const getNotificationsLoading = createSelector(
    reducerState,
    (state: reducers.NotificationState) => state.loading
);

export const getUnreadNotificationsCount = createSelector(
    reducerState,
    (state: reducers.NotificationState) =>
        state.notifications.filter((x) => !x.read).length
);
