export enum OtpReason {
    Unknown = 0,

    MobileNumberConfirmation = 1,

    EmailAddressConfirmation = 2,

    SecurityResetEmailAddressConfirmation = 3,

    SecurityResetPhoneNumberConfirmation = 4,
}
