import { Pipe, PipeTransform } from '@angular/core';
import { joinToString } from 'app/shared/utilities/string-utilities';

@Pipe({
    name: 'joinToString',
})
export class JoinToStringPipe implements PipeTransform {
    transform(
        arr: string[],
        separator: string,
        finalSeparator: string
    ): string {
        return joinToString(arr, separator, finalSeparator);
    }
}
