<div id="carousel">
    <button *ngIf="getScrollLeftMax(container)"
            class="btn empty"
            [disabled]="container.scrollLeft === 0"
            (click)="moveLeft(container)">
        <i class="fa-solid fa-arrow-left icon-size-m"></i>
    </button>
    <div class="wrapper" #container>
        <ng-content />
    </div>
    <button *ngIf="getScrollLeftMax(container)"
            class="btn empty"
            [disabled]="container.scrollLeft >= getScrollLeftMax(container)"
            (click)="moveRight(container)">
        <i class="fa-solid fa-arrow-right icon-size-m"></i>
    </button>

</div>