import { UserAuthenticationMethod } from "app/shared/enums/user-authentication-method.enum";

export class UserProfileUpdate {
    id: string;
    firstName?: string;
    surname?: string;
    detailsChanged: boolean;
    updatePassword?: boolean;
    currentPassword?: string;
    newPassword?: string;
    oldPin?: string;
    newPin?: string;
    mobileNumber?: string;
    diallingCode?: string;
    preferredAuthenticationMethod?: UserAuthenticationMethod;
}