<div id="user-alert-details">
    <div>
        <div class="user-alert-date">{{userAlert.date | date: 'dd MMMM, yyyy | HH:mm'}}</div>
        <h3 class="user-alert-title">
            {{ (failed$ | async) ? 'Oops, something went wrong!' : userAlert.processTaskTitle }}
        </h3>
        <div *ngIf="!(failed$ | async)" [innerHtml]="userAlert.processTaskDescription | safe:'html'"></div>
        <p *ngIf="failed$ | async">Try refreshing your page or please try again later.</p>
    </div>
    <div *ngIf="isTaskPending">
        <a target="_blank"
           class="btn large primary dark full-width"
           [href]="magicLink$ | async"
           [attr.disabled]="(loading$ | async) || (failed$ | async) ? true : null">
            Let's get started
        </a>
    </div>
</div>