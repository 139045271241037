import { EnumValueTitle } from 'app/shared/models/enum-value-title.model';

export enum EntityApprovalStatus {
    Pending = 1,
    Approved = 2,
    Rejected = 3
}

export const entityApprovalStatuses = [
    new EnumValueTitle(EntityApprovalStatus.Pending, 'Pending'),
    new EnumValueTitle(EntityApprovalStatus.Approved, 'Approved'),
    new EnumValueTitle(EntityApprovalStatus.Rejected, 'Rejected')
];

export function getEntityApprovalStatusTitle(status: EntityApprovalStatus): string {
    return entityApprovalStatuses.find(x => x.value === status)?.title;
}

export function getEntityApprovalStatusIcon(
    status: EntityApprovalStatus
): string {
    switch (status) {
        case EntityApprovalStatus.Approved:
            return 'rag-green-text fa-circle-check';
        case EntityApprovalStatus.Rejected:
            return 'rag-red-text fa-triangle-exclamation';
        case EntityApprovalStatus.Pending:
        default:
            return 'rag-amber-text fa-circle-exclamation';
    }
}