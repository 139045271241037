import { LozengeVariant } from 'app/connect/enums/lozenge-variant.enum';

export enum EntityImportStatus {
    Queued = 1,
    Success = 2,
    Failed = 3,
    PartialSuccess = 4,
}

export function getUserAlertImportStatusLozengeVariant(
    status: EntityImportStatus
): LozengeVariant {
    switch (status) {
        case EntityImportStatus.Queued:
            return LozengeVariant.LightBlue;
        case EntityImportStatus.Success:
            return LozengeVariant.Green;
        case EntityImportStatus.Failed:
            return LozengeVariant.Red;
        case EntityImportStatus.PartialSuccess:
            return LozengeVariant.Amber;
    }
}

export function getUserAlertImportStatusTitle(status: EntityImportStatus) {
    switch (status) {
        case EntityImportStatus.Queued:
            return 'Queued';
        case EntityImportStatus.Success:
            return 'Success';
        case EntityImportStatus.Failed:
            return 'Failed';
        case EntityImportStatus.PartialSuccess:
            return 'Partial Success';
    }
}
