<div fxFlex fxLayout="column" fxLayoutGap="4px" class="select-color">
    <div *ngIf="!helpText">{{label}}</div>
    <app-info-title [title]="label" [info]="helpText">
        <div>{{label}}</div>
    </app-info-title>

    <input
        class="example"
        cpAlphaChannel="disabled"
        [style.background]="color"
        [colorPicker]="color"
        [cpCancelButton]="true"
        [cpOKButton]="true"
        (colorPickerSelect)="onSelectColor($event)"/>

    <button class="btn medium primary light" (click)="onColorResetClick()">Reset</button>
</div>