import { Component, Input, OnInit } from '@angular/core';

// ngrx | rxjs
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

// store
import * as fromProcess from 'app/portal/modules/process-list/store';

// models
import { UserAlert } from 'app/connect/models/user-alert.model';

// rxjs
import { UserAlertCategory } from 'app/connect/enums/user-alert-category.enum';
import { UserAlertTaskStatus } from 'app/connect/enums/user-alert-task-status.enum';

@Component({
    selector: 'app-user-alert-details',
    templateUrl: 'user-alert-details.component.html',
    styleUrls: ['user-alert-details.component.scss'],
})
export class UserAlertDetailsComponent implements OnInit {
    @Input()
    userAlert: UserAlert;

    magicLink$: Observable<string>;
    loading$: Observable<boolean>;
    failed$: Observable<boolean>;

    constructor(private processStore: Store<fromProcess.ProcessListState>) {}

    get isTaskPending() {
        return (
            this.userAlert?.category === UserAlertCategory.Task &&
            [
                UserAlertTaskStatus.NotStarted,
                UserAlertTaskStatus.InProgress,
            ].includes(this.userAlert?.taskStatus)
        );
    }

    ngOnInit() {
        this.magicLink$ = this.processStore.select(
            fromProcess.getMagicLinkClientUser
        );

        this.loading$ = this.processStore.select(
            fromProcess.getMagicLinkClientUserLoading
        );

        this.failed$ = this.processStore.select(
            fromProcess.getMagicLinkClientUserFailed
        );

        if (this.isTaskPending) {
            this.processStore.dispatch(
                fromProcess.GetMagicLinkClientUser({
                    processId: this.userAlert.processId,
                })
            );
        }
    }
}
