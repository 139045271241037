<app-authentication-layout id="enter-authenticator-app-code" fxFlex pageTitle="Enter Authenticator Code">
    <app-loading-card *ngIf="(working$ | async)"></app-loading-card>
    <div>
        <p>
            Enter the code created by your Authenticator app
        </p>
        <div [formGroup]="form">
            <mat-form-field appearance="outline">
                <mat-label>Verification code</mat-label>
                <input matInput
                       placeholder="Verification code"
                       formControlName="code"
                       mask="000-000"
                       (keydown.enter)="onConfirm()" />
            </mat-form-field>
            <p class="mat-error margin-bottom-16" *ngIf="authenticationMessage$ | async as message">
                {{message}}
            </p>

            <button class="btn large primary dark margin-top-16 full-width"
                    [disabled]="(working$ | async) || form.invalid"
                    (click)="onConfirm()">
                Get me in
            </button>
        </div>
    </div>
</app-authentication-layout>