<div id="authentication" fxLayout="column" fxLayoutAlign="space-between">
    <div *ngIf="backgroundUrl$ | async" class="background"
         [style.background-image]="'url(' + (backgroundUrl$ | async) + ')'">

        <main class="container" fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between">
            <div fxHide.xs class="headline-container">
                <h1 class="font-headline text-white">
                    Identity <br />
                    verification <br />
                    made simple.
                </h1>
            </div>
            <div class="content-container">
                <div class="card">
                    <div class="card-content">
                        <div>
                            <img *ngIf="!(authenticationLogoUrl$ | async) && (logoUrl$ | async)"
                                 [src]="logoUrl$ | async" />
                            <img *ngIf="authenticationLogoUrl$ | async" [src]="authenticationLogoUrl$ | async" />
                        </div>
                        <h1 class="title">
                            {{pageTitle || (title$ | async)}}
                        </h1>
                        <ng-content></ng-content>
                        <app-client-selector *ngIf="(fullyLoggedIn$ | async)" [logoutOnBackClick]="true" backLabel="Back to login" [float]="false" [showClose]="false"></app-client-selector>
                        <app-user-group-selector *ngIf="(fullyLoggedIn$ | async)" [logoutOnBackClick]="true" backLabel="Back to login" [float]="false" [showClose]="false"></app-user-group-selector>
                    </div>
                    <div class="terms">
                        <p>© {{ year }} Credas. All rights reserved | <span (click)="onViewTermsClicked()">Terms &
                                Conditions</span></p>
                    </div>
                </div>
            </div>
        </main>
        <footer id="footer">
            <div class="footer-container"
                 ngClass.gt-xs="container">
                <div fxLayout="column"
                fxLayoutGap="32px"
                     fxLayout.gt-xs="row"
                     fxLayoutAlign.gt-xs="space-between center">
                    <div fxFlex="noshrink" ngClass.xs="margin-bottom-32">
                        <app-powered-by-credas color="#F1A948" [showBorderLeft]="false" [showText]="true"></app-powered-by-credas>
                    </div>
                    <p class="tagline">
                        Secure identity verification and document authentication technologies, shaped by legislation.
                    </p>
                </div>
            </div>
        </footer>
    </div>
</div>