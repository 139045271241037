// ngrx
import { createAction, props } from '@ngrx/store';

// models
import { ExportProcessData } from 'app/portal/modules/process-list/models/export-process-data.model';
import { ProcessPdfExportData } from 'app/portal/modules/process-details/models/process-pdf-export-data.model';

export const ExportProcess = createAction(
    '[Process Export] Export a process',
    props<{processId: string; data: ExportProcessData}>()
);
export const ExportProcessSuccess = createAction(
    '[Process Export] Export a process success',
    props<{data: ProcessPdfExportData}>()
);
export const ExportProcessFail = createAction(
    '[Process Export] Export a process fail',
);

export const GetExportProcessData = createAction(
    '[Process Export] Get export a process data',
    props<{processId: string}>()
);
export const GetExportProcessDataSuccess = createAction(
    '[Process Export] Get export a process data success',
    props<{data: ExportProcessData}>()
);
export const GetExportProcessDataFail = createAction(
    '[Process Export] Get export a process data fail',
);

export const ProcessExportDownloaded = createAction(
    '[Process Export] Process export downloaded',
    props<{processId: string; pdfId: number}>()
);
export const ProcessExportDownloadedSuccess = createAction(
    '[Process Export] Process export downloaded success'
);
export const ProcessExportDownloadedFail = createAction(
    '[Process Export] Process export downloaded fail',
);

export const ExportProcessPdf = createAction(
    '[Process Export] Export process PDF',
    props<{ processId: string; download: boolean }>()
);
export const ExportProcessPdfSuccess = createAction(
    '[Process Export] Export process PDF success',
    props<{ processId: string; pdf: Blob; download: boolean }>()
);
export const ExportProcessPdfFail = createAction(
    '[Process Export] Export process PDF fail'
);

export const ViewProcessPdfPack = createAction(
    '[Process Export] View process PDF pack',
    props<{ processId: string; processPdfPackId: number}>()
);

export const ViewProcessPdfPackSuccess = createAction(
    '[Process Export] View process PDF pack success',
    props<{ pdf: Blob}>()
);

export const ViewProcessPdfPackFail = createAction(
    '[Process Export] View process PDF pack fail'
);
