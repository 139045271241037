import { Injectable } from '@angular/core';

@Injectable()
export class SessionStateService {
    private displayChargeableModeWarningName = 'display-chargeable-warning';
    private storage: Storage = sessionStorage;

    clear(): void {
        this.storage.removeItem(this.displayChargeableModeWarningName);
    }

    setDisplayChargeableModeWarningTimestamp(): void {
        this.storage.setItem(this.displayChargeableModeWarningName, new Date().getTime().toString());
    }

    getDisplayChargeableModeWarning(): boolean {
        const ChargeableModeWarningDebounceSeconds = 5;
        try {
            const timestamp = this.storage.getItem(this.displayChargeableModeWarningName);
            if (timestamp) {
                const date = new Date(Number(timestamp));
                const now = new Date();
                if ((now.getTime() - date.getTime()) / 1000 <= ChargeableModeWarningDebounceSeconds) {
                    return true;
                }
            }
        } catch (ex) { 
            // ignore
        }

        return false;
    }
}