import { EventEmitter, Output } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

// rxjs
import { takeUntil } from 'rxjs';

// components
import { BaseComponent } from 'app/shared/base/base-component';

// utilities
import { isNullOrWhitespace } from 'app/shared/utilities/string-utilities';

// models
import { RegExRules } from 'app/shared/models';

interface CodeField {
    index: number;
}

export interface ValueChangedEvent {
    code: string;
    isValid: boolean;
}

const CONTAINER_CLASS = 'code-entry-container';

@Component({
    selector: 'app-authentication-code-entry',
    templateUrl: './code-entry.component.html',
    styleUrls: ['./code-entry.component.scss']
})
export class CodeEntryComponent extends BaseComponent implements OnInit {
    constructor(private fb: FormBuilder) {
        super();
    }

    RegExRules = new RegExRules();

    private _valueChanged = new EventEmitter<ValueChangedEvent>();

    @Input()
    length = 0;

    @Input()
    value: string = '';

    @Input()
    spacerInterval = 0;

    @Input()
    numericOnly = false;

    @Input()
    asPassword = false;

    form: FormGroup;
    fields: CodeField[] = [];

    @Output()
    valueChanged = this._valueChanged.asObservable();

    ngOnInit(): void {
        this.initForm();
    }

    onTextInput(event: Event, field: CodeField): void {
        const text = ((event.target as HTMLInputElement).value || '').trim();
        if (text.length > 1) {
            this.textPasted(text);
        } else {
            const key = text.slice(-1);
            this.textEntered(key, field, event.target as HTMLElement);
        }
    }

    onKeyPressed(event: KeyboardEvent, field: CodeField): void {
        const key = event.key;
        this.textEntered(key, field, event.target as HTMLElement);
    }

    private textEntered(text: string, field: CodeField, source: HTMLElement): void {
        const isAlphaNumeric = new RegExp(this.RegExRules.alpha_numeric).test(text);

        if (isAlphaNumeric && field.index < this.length - 1) {
            const controlName = `code${field.index + 1}`;
            const control = this.form.controls[controlName];
            if (control) {
                const inputElement = this.getContainer(source).querySelector(`[data-codeindex="${field.index + 1}"] input`) as HTMLInputElement;
                if (inputElement) {
                    setTimeout(() => inputElement.focus(), 50);
                }
            }
        }
    }

    onFocus(event: Event, field: CodeField): void {
        const controlName = `code${field.index}`;
        const control = this.form.controls[controlName];
        if (!isNullOrWhitespace(control?.value)) {
            const inputElement = this.getContainer(event.target as HTMLElement).querySelector(`[data-codeindex="${field.index}"] input`) as HTMLInputElement;
            if (inputElement) {
                setTimeout(() => inputElement.select());
            }
        }
    }

    showSpacer(index: number): boolean {
        return this.spacerInterval > 0 &&
            index > 0 &&
            (index % this.spacerInterval) === 0;
    }

    get showError(): boolean {
        let touchedInvalid = false;
        for (let i = 0; i < this.length; i++) {
            const control = this.form.get(`code${i}`);
            if (control && control.touched && control.invalid) {
                touchedInvalid = true;
                break;
            }
        }

        return touchedInvalid;
    }

    onDrop(event: DragEvent): void {
        if (event.dataTransfer?.items?.length > 0) {
            event.dataTransfer.items[0].getAsString(t => {
                this.textPasted(t);
            });
        }
    }

    onPaste(event: ClipboardEvent): void {
        const clipboardData = event.clipboardData || (window as any).clipboardData;
        const pastedText = clipboardData?.getData('text');

        if (pastedText) {
            this.textPasted(pastedText);
        }
    }

    private textPasted(text: string): void {
        this.resetForm();

        text = text.replace('-', '');

        const valueChars = [...(text.substr(0, Math.min(text.length, this.length)))];

        for (let i = 0; i < valueChars.length; i++) {
            const control = this.form.get(`code${i}`);
            if (control) {
                control.patchValue(valueChars[i].toUpperCase());
                control.markAsTouched();
            }
        }

        this.form.updateValueAndValidity();

        this.emitValue();
    }

    resetForm(): void {
        for (let i = 0; i < this.length; i++) {
            const control = this.form.get(`code${i}`);
            if (control) {
                control.patchValue('');
                control.markAsUntouched();
            }
        }
        this.form.reset();
        this.emitValue();
    }

    private initForm(): void {
        const form = this.fb.group({});
        this.fields = [];

        const valueChars = [...(this.value || '')];

        const pattern = new RegExp(this.numericOnly ? this.RegExRules.numeric : this.RegExRules.alpha_numeric);

        for (let i = 0; i < this.length; i++) {
            this.fields.push({ index: i });
            const char = (i < valueChars.length ? valueChars[i].toUpperCase() : null) || '';
            form.addControl(`code${i}`, this.fb.control(char, [Validators.required, Validators.minLength(1), Validators.maxLength(1), Validators.pattern(pattern)]));
        }

        form.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
                this.emitValue();
            });

        this.form = form;
        this.emitValue();
    }

    private emitValue() {
        const isValid = this.form.valid;
        let code = '';

        for (let i = 0; i < this.length; i++) {
            const control = this.form.get(`code${i}`);
            code += (control.value || ' ');
        }

        this._valueChanged.next({
            isValid,
            code: code.toUpperCase()
        });
    }

    private getContainer(source: HTMLElement): HTMLElement {
        let current = source;

        while (current) {
            if (current.classList.contains(CONTAINER_CLASS)) {
                return current;
            }

            current = current.parentElement;
        }

        return null;
    }
}
