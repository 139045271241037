// environment
import { environment } from 'environments/environment';

// ngrx
import { createReducer, on, Action } from '@ngrx/store';

// store
import * as actions from 'app/connect/store/actions/branding.actions';

// models
import { Branding } from 'app/models/branding.model';
import { EntityBranding } from 'app/branding/modules/entity-branding/models/entity-branding.model';

// enums
import { MagicLinkType } from 'app/branding/modules/entity-branding/enumerations/magic-link-type.enum';
import { SupportType } from 'app/branding/modules/entity-branding/enumerations/support-type.enum';
import { PortalRegistrationType } from 'app/branding/modules/entity-branding/enumerations/portal-registration-type.enum';

const defaultConnectBranding: Branding = {
    id: undefined,
    logoUrl: getProperty('logoUrl', 'assets/connect/connect-logo.svg'),
    credasLogoColor: getProperty('credasLogoColor', '#FFFFFF'),
    backgroundUrl: getProperty('backgroundUrl', 'assets/connect/connect-background.svg'),
    textColor: getProperty('textColor', '#FFFFFF'),
    primaryColor: getProperty('primaryColor', '#323343'),
    primaryReverseColor: getProperty('primaryReverseColor', '#FFFFFF'),
    secondaryColor: getProperty('secondaryColor', '#F1A948'),
    secondaryColorDark: getProperty('secondaryColorDark', '#F1A948'),
    accentColor: getProperty('accentColor', '#F1A948'),
    title: getProperty('title', 'Identity Verification Made Simple'),
    tagLine: getProperty('tagLine', 'Credas are the only identity verification provider to be accredited by HMRC for Regulatory Excellence'),
    authenticationLogoUrl: getProperty('authenticationLogoUrl', 'assets/connect/connect-logo-authentication.svg'),
    favIcon: getProperty('favIcon', 'assets/connect/favicon.ico'),
    headerColor: '#323343',
    tableAndTabColor: getProperty('tableAndTabColor', '#323343'),
    tableAndTabTextColor: getProperty('tableAndTabTextColor', '#FFFFFF'),
    pdfHeaderColor: getProperty('pdfHeaderColor', '#323343'),
    pdfHeaderTextColor: getProperty('pdfHeaderTextColor', '#FFFFFF'),
    poweredByCredasEnabled: getProperty('poweredByCredasEnabled', 'true') === 'true',
    supportUrl: null
};

const defaultEntityBranding: EntityBranding = {
    clientName: null,
    accentColor: getProperty('entityAccentColor', '#F1A948'),
    authenticationBackgroundUrl: getProperty('entityAuthenticationBackgroundUrl', 'assets/connect/connect-background.svg'),
    authenticationHeader: getProperty('entityAuthenticationHeader', 'Identity Verification Made Simple'),
    authenticationLogoUrl: getProperty('entityAuthenticationLogoUrl', 'assets/connect/connect-powered-by-credas-logo.svg'),
    authenticationTagline: getProperty('entityAuthenticationTagline', 'Credas are the only identity verification provider to be accredited by HMRC for Regulatory Excellence.'),
    bannerButtonColor: getProperty('entityBannerButtonColor', '#323343'),
    bannerButtonLink: getProperty('entityBannerButtonLink', 'https://www.credas.co.uk/contact'),
    bannerButtonText: getProperty('entityBannerButtonText', 'Find out more'),
    bannerButtonTextColor: getProperty('entityBannerButtonTextColor', '#FFFFFF'),
    bannerEnabled: getProperty('entityBannerEnabled', 'true') === 'true',
    bannerHeader: getProperty('entityBannerHeader', 'Identity Verification Made Simple'),
    bannerHeaderColor: getProperty('entityBannerHeaderColor', '#323343'),
    bannerImageUrl: getProperty('entityBannerImageUrl', 'assets/connect/connect-background.svg'),
    bannerText: getProperty('entityBannerText', 'Credas offers digital verification services, enabling businesses to transact with customers in a convenient and secure way.'),
    bannerTextColor: getProperty('entityBannerTextColor', '#323343'),
    credasLogoColor: getProperty('entityCredasLogoColor', '#FFFFFF'),
    helpText: getProperty('entityHelpText', ''),
    logoUrl: getProperty('entityLogoUrl', 'assets/connect/connect-logo-black.svg'),
    primaryButtonColor: getProperty('entityPrimaryButtonColor', '#F1A948'),
    primaryButtonTextColor: getProperty('entityPrimaryButtonTextColor', '#323343'),
    primaryColor: getProperty('entityPrimaryColor', '#323343'),
    primaryTextColor: getProperty('entityPrimaryTextColor', '#FFFFFF'),
    secondaryButtonColor: getProperty('entitySecondaryButtonColor', '#323343'),
    secondaryButtonTextColor: getProperty('entitySecondaryButtonTextColor', '#FFFFFF'),
    tableAndTabColor: getProperty('entityTableAndTabColor', '#323343'),
    tableAndTabTextColor: getProperty('entityTableAndTabTextColor', '#FFFFFF'),
    textColor: getProperty('entityTextColor', '#323343'),
    magicLinkType: MagicLinkType.CredasDashboard,
    portalPoweredByCredasEnabled: getProperty('portalPoweredByCredasEnabled', 'true') === 'true',
    supportType: SupportType.None,
    supportEmail: null,
    supportUrl: null,
    portalRegistrationType: PortalRegistrationType.GuestRegistration
};

export interface BrandingState {
    branding: Branding;
    connectBranding: Branding;
    entityBranding: EntityBranding;
}

const initialState: BrandingState = {
    branding: null,
    connectBranding: defaultConnectBranding,
    entityBranding: defaultEntityBranding
};

const brandingReducer = createReducer(
    initialState,
    on(actions.SetBranding, (state, action) => ({
        ...state,
        branding: action.branding
    })),
    on(actions.ClearBranding, (state) => ({
        ...state,
        branding: null
    })),
);

export function reducer(state: BrandingState | undefined, action: Action) {
    return brandingReducer(state, action);
}

function getProperty(name: string, defaultValue: string): string {
    if (environment[name] && (<string>environment[name]).length > 0 && !(<string>environment[name]).endsWith('#')) {
        return <string>environment[name];
    }

    return defaultValue;
}