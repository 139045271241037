<app-dialog id="clone-client-owned-item-dialog" [dialogTitle]="message" defaultWidth="500px" defaultHeight="650px">
    <div body fxLayout="column" fxLayoutGap="10px" [formGroup]="form">
        <div class="font-question-label">New name for the cloned {{itemTypeTitle}}</div>
        <mat-form-field appearance="outline">
            <input matInput type="text" formControlName="title"/>
            <mat-error *ngIf="form.get('title').hasError('required')">The title is required</mat-error>
            <mat-error *ngIf="form.get('title').hasError('notEqualValue')">The title must be different to the source title.</mat-error>
        </mat-form-field>
        <h1>{{title}}</h1>
        <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px">
            <button fxFlex="50%" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="16px"
                class="select" [ngClass]="{ 'selected': importAsPublic, 'disabled': !publicAllowed }" (click)="setImportAsPublic(true)" [disabled]="!publicAllowed" >
                    <div fxLayout="row" fxLayoutAlign="center start">
                        <i class="fa fa-solid fa-earth-americas icon-size-xxl" [ngClass]="{ 'rag-green-text': importAsPublic }"></i>
                        <i class="icon-size-s" [ngClass]="{ 'fa fa-solid fa-circle-check rag-green-text': importAsPublic, 'fa fa-regular fa-circle text-gray-6': !importAsPublic }"></i>
                    </div>
                    <h3>Public</h3>
            </button>
            <button fxFlex="50%" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="16px"
                class="select" [ngClass]="{ 'selected': !importAsPublic, 'disabled': !privateAllowed }" (click)="setImportAsPublic(false)" [disabled]="!privateAllowed">
                    <div fxLayout="row" fxLayoutAlign="center start">
                        <i class="fa fa-solid fa-lock icon-size-xxl" [ngClass]="{ 'rag-green-text': !importAsPublic }"></i>
                        <i class="icon-size-s" [ngClass]="{ 'fa fa-solid fa-circle-check rag-green-text': !importAsPublic, 'fa fa-regular fa-circle text-gray-6': importAsPublic }"></i>
                    </div>
                    <h3>Private</h3>
            </button>
        </div>

        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
            <i class="fa fa-solid fa-info-circle rag-blue-text icon-size-m"></i>    
            <h4 *ngIf="!importAsPublic">The {{itemTypeTitle}} will be cloned and attached to the {{(client$ | async).name}} portal.</h4>
            <h4 *ngIf="importAsPublic">The {{itemTypeTitle}} will be cloned as public and will be available to all portals.</h4>
        </div>

    </div>
    <div footer-actions fxLayout="row" fxLayoutAlign="end start" fxLayoutGap="8px">
        <button class="btn large primary light" mat-dialog-close>Cancel</button>
        <button [disabled]="form.invalid" class="btn large primary dark" (click)="onCloneClick()">Clone</button>
    </div>
</app-dialog>
