<div class="loading-container" *ngIf="!useSpinner" fxLayout="column" fxLayoutAlign="center center">
    <div class="inner white-bg" fxLayout="column" fxLayoutGap="50px" fxLayoutAlign="center center">
        <div *ngIf="title" class="title align-center">{{ title }}</div>
        <div class="spinner"></div>
        <div class="font-size-l align-center" *ngIf="message">{{ message }}</div>
    </div>
</div>

<div class="loading-spinner" *ngIf="useSpinner">
    <mat-progress-spinner mode="indeterminate" [diameter]="spinnerSize"></mat-progress-spinner>
</div>